/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import pdfMake from "pdfmake/build/pdfmake";
import Table from '../global/Table';

import PageSpinner from "../global/pageSpinner";
import Loader from 'react-loader-spinner';

import '../global/pdf/customfonts';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { gql, useLazyQuery } from '@apollo/client';
import { upperCase, lowerCase, orderBy } from "lodash";
import Sanitiser from "../global/sanitiser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { vmLogo } from '../global/pdf/vmLogo';
import { logoImage } from '../global/pdf/logoImage';
import ErrorBoundary from '../global/ErrorBoundary';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { filterFindingsByIssue, createDataForFindingsByIssueBarChart, FindingIssues } from "../statistics/statistics";

import { 
  parseJSONSafely, 
  formatDateToLocaleDateString,
  sortDatesDescendingDates, 
  adjustTextNode
} from "../../utils";

import {
  STATUS_SYSTEM_VARIABLE_TYPE_ID,
  STATEMENT_SYSTEM_VARIABLE_TYPE_ID,
  VS_GRAPH_DISPLAY_MONTHS,
  ACTIVITY_STATUS_SYS_VAR_COMPLETED_ID,
  ACTIVITY_STATUS_SYS_VAR_COMMENCED_ID,
  ACTIVITY_STATUS_SYS_VAR_NOT_COMMENCED_ID,
  ACTIVITY_STATUS_SYS_VAR_SCHEDULED,
  NOTIFICATION_STATUS_PENDING,
  NOTIFICATION_STATUS_APPROVED,
  NOTIFICATION_STATUS_REVIEW
} from '../../constants/';

import {
  createCommencedActivityTableRows,
  createCompletedActivityTableRows,
  createFindingTableRows,
  createNotCommencedActivityTableRows,
  createRevisionTableRows,
  createSeceStatusTableRows,
  createIssueFindingsHeaderRows
} from './viewPeriodicVerification';

import { CenteredLayout } from '../global/pdf/centeredLayout';

var htmlToPdfmake = require("html-to-pdfmake");

const {REACT_APP_FILE_MANAGER_API} = process.env;

const VS_REPORT_BY_ID_QUERY = gql`
query verificationScheduleReport($vsRptId: ID!){
    verificationScheduleReport(_id: $vsRptId) {
        _id
        dh_id{
          _id
          dh_name
        }
        installation_ids{
          _id
          installation_title
        }
        vs_rpt_ref
        vs_rpt_is_periodic
        vs_rpt_title
        vs_rpt_description
        vs_rpt_summary
        vs_rpt_scope
        vs_rpt_statement
        vs_rpt_improvements
        vs_rpt_concerns
        vs_rpt_positives
        vs_rpt_payment_details
        vs_ids{
           _id 
           vs_title
           vs_periodicity { _id}
           vs_status
           vs_end_date_time
           sece_ids{
             _id
           }
           verifier_ids{
            _id
            user_fname
            user_lname
          }
        }
        vs_rpt_rev_num
        vs_rpt_issuances
        vs_rpt_start_date
        vs_rpt_end_date
        vs_rpt_issue_date
        vs_rpt_number
        vs_rpt_revision_description
        vs_rpt_scheme_ref
        vs_rpt_added_date
        vs_rpt_added_id{
          _id
          user_fname
          user_lname
        }
        vs_rpt_modified_date
        vs_rpt_modified_by{
          _id
          user_fname
          user_lname
        }

        vs_rpt_approved_date
        vs_rpt_approved_by{
          _id
          user_fname
          user_lname
        }

        vs_rpt_archived
        vs_rpt_status
  }
}`;

const USER_BY_IDS_QUERY = gql`
      query UserByIds($idList: [ID!]!){
          user_by_ids(idList: $idList) {
              _id
              user_location
              user_ms_oid
              user_email
              user_fname
              user_job_title
              user_lname
              user_mobile
              user_notes
              user_office_tel
              user_profile_image
              user_type {
                  _id
                  user_type_display_name
              }
              user_role {
                _id
                user_type
                user_type_id
                user_role_display
                user_role_code
              }
              user_duty_holder {
                _id
                dh_name
                dh_code
              }
              user_duty_holder_location{
                location_name
                location_address_line_1
                location_address_line_2
                location_address_city
                location_address_country
                location_address_post_zip_code
              }
              user_status
          }
}`;

const REPORT_REVISIONS = gql`
query ReportRevisionsByParentId($parentId: String!){
  reportRevisionsByParentId(parentId: $parentId) {
    revision
    revisedDate
    approvedDate
    description
    revisedBy
    approvedBy
  }
}`;

const HISTORIC_DETAILED_SECE_BREACKDOWN = gql`
query DetailedSeceBreakdownFromBactchRecord($param: VsReportStatsInput!){
  detailedSeceBreakdownFromBactchRecord(param: $param) {
      sece_order
      sece_ref
      sece_title
      offshore
      onshore
      remote
      level1
      level2
      level3
  }
}`;

const HISTORIC_ACTIVITIES_STATUS_BY_VS_ID_LIST_ALT = gql`
query ActivitiesStatusByVsIdListFromHistoryAlt($param: VsReportStatsInput!){
  activitiesStatusByVsIdListFromHistoryAlt(param: $param) {
    completedVas {
      sece_ref
      sece_name
      va_ref
      va_short_desc
      as_found_status
    }
    commencedVas{
      sece_ref
      sece_name
      va_ref
      va_short_desc
      as_found_status          
    }
    notCommencedVas{
      sece_ref
      sece_name
      va_ref
      va_short_desc
      as_found_status            
    }
    findings {
      id
      finding_ref
      finding_level_summary
      finding_infomatrix_level
      sece_ref
      synopsis
      status
   }
  }
}`;

const HISTORIC_OPEN_FINDINGS_QUERY = gql`
    query FindingStatsFromHistory($param: VsReportStatsInput!){
      findingStatsFromHistory(param: $param) {
            date {
                labels,
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels,
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
}`;

const HISTORIC_NATURE_FINDINGS_QUERY = gql`
    query FindingStatsGroupByNatureFromHistory($param: VsReportStatsInput!) {
      findingStatsGroupByNatureFromHistory(param: $param) {
            date {
                labels
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels
                datasets {
                    label           
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
}`;

const HISTORIC_ISSUES_FINDINGS_QUERY = gql`
    query findingStatsGroupByIssuesFromHistory($param: VsReportStatsInput!){
      findingStatsGroupByIssuesFromHistory(param: $param)  {
            label
            count
            backgroundColor
            linkHref
        }
}`;

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const HISTORIC_REPORT_BY_VS_ID_LIST_QUERY = gql`
query ReportsByVsIdListFromHistory($param: VsReportStatsInput!){
  reportsByVsIdListFromHistory(param: $param) {
    sece_ref
    sece_no
    barrier_title
    sece_title
    va_ref
    va_client_ref
    va_short_desc
    va_long_desc
    nature
    frequency
    required_sample
    completed_sample
    ps_criteria
    previous_status
    previous_status_id
    current_status
    current_status_id
    detail_va_completed
    conclusive_statement
    verifier
    date
    findings_raised{
      finding_ref
      synopsis
    }
    open_findings{
      finding_ref
      synopsis
    }
  }
}`;

const HISTORIC_FINDINGS_BY_VS_ID_LIST_QUERY = gql`
query FindingsByVsIdListFromHistory($param: VsReportStatsInput!){
  findingsByVsIdListFromHistory(param: $param) {
    finding_level_summary
    finding_infomatrix_level
    finding_ref
    finding_completion_date
    finding_synopsis
    finding_summary
    finding_ps_reference
    finding_ps_reference_attachments
    fnd_ps_ref_attmnt_dest_path
    finding_responses
    finding_action
    
    finding_response_date
    finding_added_date
    barrier_id {
      _id
      bar_ref
      bar_title
      bar_status
    }
    sece_id {
      _id
      sece_ref
      sece_title
      sece_status
    }
    sece_ids {
      _id
      sece_ref
      sece_title
      sece_status
    }
    va_id {
      _id
      va_client_ref
      va_short_desc
      va_long_desc
    }
    va_ids {
      _id
      va_client_ref
      va_short_desc
    }
    finding_current_status{
      _id
      sysvar_title
    }
    finding_integrity{
      _id
      sysvar_title
    }
    finding_legislative{
      _id
      sysvar_title
    }
    finding_rogi{
      _id
      sysvar_title
    }
    finding_pw_process{
      _id
      sysvar_title
    }
    finding_level {
      _id
      sysvar_title
    }
    finding_action_party{
      _id
      user_fname
      user_lname
    }
    finding_dh_responsible{
      _id
      user_fname
      user_lname
    }
    finding_added_id{
      _id
      user_fname
      user_lname
    }
  }
}`;

const HISTORIC_VS_STATS_BY_VS_IDLIST_QUERY = gql`
query verficationScheduleStatsByIdListAltFromHistory($param: VsReportStatsInput!){
  verficationScheduleStatsByIdListAltFromHistory(param: $param) {
    labels
    datasets {
      label
      borderColor
      backgroundColor
      fill
      data
    }
  }
}`;

const VS_REPORT_EDITORS_QUERY = gql`
query VerificationScheduleReportEditors($vsRptId : ID!){
  verificationScheduleReportEditors(_id: $vsRptId) {
    author{
      _id
      full_name
      location
      signature
      date

    }
    reviewer{
      _id
      full_name
      location
      signature
      date
    }
  }
}`;

const FINDING_SEARCH_FROM_HISTORY_ALT_QUERY = gql`
query FindingSearchFromHistoryAlt($param: VsReportStatsInput!){
  findingSearchFromHistoryAlt(param: $param) {
      id
      finding_ref
      finding_level_summary
      finding_infomatrix_level
      sece_ref
      synopsis
      status
  }
}`;

let defaultResultObject = {
  activitiesStatusByVsIdList : false,
  findingRegisterList: false,

  verificationScheduleReport : false,
  verificationScheduleReportEditors : false,
  user_by_ids : false,

  reportsByVsIdList : false,
  findingsByVsIdList : false,
  systemVariablesBySysVarTypeIds : false,
  reportRevisionsByParentId : false,
  verficationScheduleStatsByIdList : false,

  findingStats : false,
  findingStatsGroupByNature : false,
  findingStatsGroupByIssues : false,
  detailedSeceBreakdown : false,
}

const emptyImage ='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

const ViewAssetVerification = (props) => {

  const divRef = useRef(null);
  const defaultState = { date: {}, level: {} }

  //charts & graphs
  const [isLoaded, setIsLoaded] = useState(false);
  //const [emptyImage] = useState("data:image/png;base64,iVBORw0KGg==");

  const [vsPdfReportTitle, setVsPdfReportTitle] = useState("");
  const [vsPdfReportSubTitle, setVsPdfReportSubTitle] = useState("");

  const [vsReport, setVsReport] = useState({});
  const [vsReportHistories, setVsReportHistories] = useState([]);

  const [seceBreakdowns, setSeceBreakdowns] = useState([]);
  const [vsStatsData, setVsStatsData] = useState({});

  const [vaFindingList, setVaFindingList] = useState([]);
  const [vaReportList, setVaReportList] = useState([]);

  const [statementList, setStatementList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const [vsDutyHolder, setVsDutyHolder] = useState("");
  const [vsInstallations, setVsInstallations] = useState([]);
  const [vsVerifiers, setVsVerifiers] = useState([]);

  const [completedVaList, setCompletedVaList] = useState([]);
  const [commencedVaList, setCommencedVaList] = useState([]);
  const [notCommencedVaList, setNotCommencedVaList] = useState([]);
  const [raisedFindings, setRaisedFindings] = useState([]);

  const [openFindingsStats, setOpenFindingsStats] = useState(defaultState);
  const [natureFindingsStats, setNatureFindingsStats] = useState(defaultState);
  const [issuesFindingsStats, setIssuesFindingsStats] = useState([]);
  const [issuesFindingsStatsBarChart, setIssuesFindingsStatsBarChart] = useState({});
  const [issuesFindingsStatsSvg, setIssuesFindingsStatsSvg] = useState("<svg></svg>");

  const [reportNumber, setReportNumber] = useState("");
  const [verSchemeRef, setVerSchemeRef] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [currentRevision, setCurrentRevision] = useState("");

  const [pdfAuthorDetail, setPdfAuthorDetail] = useState({});
  const [pdfReviewerDetail, setPdfReviewerDetail] = useState({});

  const [resultObject, setResultObject] = useState({});
  const [resultStatus, setResultStatus] = useState(false);

  const [chartImageStatus, setChartImageStatus] = useState(false);

  const { vsRptId = "" } = props ? props : {};

  const [activitiesStatusByVsIdListFromHistoryAlt, { loading: svaLoading, error: svaError, data: scheduledVaStatusData }] = useLazyQuery(HISTORIC_ACTIVITIES_STATUS_BY_VS_ID_LIST_ALT);
  const [findingSearchFromHistoryAlt, { loading: fndRegLoading, error: fndRegError, data: fndRegData }] = useLazyQuery(FINDING_SEARCH_FROM_HISTORY_ALT_QUERY);

  const [verificationScheduleReport, { data: vsReportData }] = useLazyQuery(VS_REPORT_BY_ID_QUERY);
  const [verificationScheduleReportEditors, { data: vsReportEditorsData }] = useLazyQuery(VS_REPORT_EDITORS_QUERY);
  const [user_by_ids, { data: vsVerifierData }] = useLazyQuery(USER_BY_IDS_QUERY);

  const [reportsByVsIdListFromHistory, { data: reportsByVsIdListData }] = useLazyQuery(HISTORIC_REPORT_BY_VS_ID_LIST_QUERY);
  const [findingsByVsIdListFromHistory, { data: findingsByVsIdListData }] = useLazyQuery(HISTORIC_FINDINGS_BY_VS_ID_LIST_QUERY);

  const [systemVariablesBySysVarTypeIds, { data: sysVarsData }] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);
  const [reportRevisionsByParentId, { data: ReportRevisionData }] = useLazyQuery(REPORT_REVISIONS);

  const [verficationScheduleStatsByIdListAltFromHistory, { loading: vsStatsLoading, error: vsStatsError, data: vsListStatsResult }] = useLazyQuery(HISTORIC_VS_STATS_BY_VS_IDLIST_QUERY);

  const [findingStatsFromHistory, { loading: openFindingsLoading, error: openFindingsError, data: openFindingsData }] = useLazyQuery(HISTORIC_OPEN_FINDINGS_QUERY);
  const [findingStatsGroupByNatureFromHistory, { loading: natureFindingsLoading, error: natureFindingsError, data: natureFindingsData }] = useLazyQuery(HISTORIC_NATURE_FINDINGS_QUERY);
  const [findingStatsGroupByIssuesFromHistory, { loading: issuesFindingsLoading, error: issuesFindingsError, data: issuesFindingsData }] = useLazyQuery(HISTORIC_ISSUES_FINDINGS_QUERY);
  
  const [detailedSeceBreakdownFromBactchRecord, { loading: seceBreakdownLoading, error: seceBreakdownError, data: seceBreakdownData }] = useLazyQuery(HISTORIC_DETAILED_SECE_BREACKDOWN);

  const sysVarTypeIds = [STATEMENT_SYSTEM_VARIABLE_TYPE_ID, STATUS_SYSTEM_VARIABLE_TYPE_ID];

  let svgElement = document.getElementsByClassName("svg-content-responsive").item(0);

  useEffect(() => {

    setTimeout(() => { setIsLoaded(true); }, 9000);
    setResultObject(defaultResultObject);

    if (vsRptId !== "") {
      verificationScheduleReport({ variables: { vsRptId: vsRptId }, errorPolicy: 'all' });
      reportRevisionsByParentId({ variables: { parentId: vsRptId }, errorPolicy: 'all' });
      verificationScheduleReportEditors({ variables: { vsRptId: vsRptId }, errorPolicy: 'all' });
    }
    systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });

  }, []);

  useEffect(() => {
    if (resultObject && resultObject.hasOwnProperty("findingStats")) {
      let areAllPropertiesTrue = Object.values(resultObject).every(value => value === true);
      setResultStatus(areAllPropertiesTrue);
    }
  }, [resultObject]);

  useEffect(() => {
    if (svgElement) {
      if (svgElement) {
        const svg = svgElement.outerHTML;
        setIssuesFindingsStatsSvg(svg);
      }
    }
  }, [svgElement]);

  useEffect(() => {
    if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
      formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
        setStatementList,
        setStatusList,
        STATEMENT_SYSTEM_VARIABLE_TYPE_ID,
        STATUS_SYSTEM_VARIABLE_TYPE_ID);
    }
    setResultObject(prevState => ({...prevState, systemVariablesBySysVarTypeIds: true}));
  }, [sysVarsData]);

  useEffect(() => {
    if (vsReportData && vsReportData.verificationScheduleReport) {
      let vsReport = vsReportData.verificationScheduleReport;
      
      let vsList = vsReport.vs_ids;

      let vsEndDateList = vsList.map(v => new Date(parseInt(v.vs_end_date_time)));
      let endDatesOfSchedules = sortDatesDescendingDates(vsEndDateList);

      let scheduleLastDate = endDatesOfSchedules[0] ?? "";

      let verifierArrayList = vsReport.vs_ids && vsReport.vs_ids.length > 0 ? vsReport.vs_ids.map(v => v.verifier_ids) : [];

      let verifierIdList = [];
      for (let index = 0; index < verifierArrayList.length; index++) {
        const verifierArray = verifierArrayList[index];
        for (let index = 0; index < verifierArray.length; index++) {
          const verifier = verifierArray[index];
          if (verifier) {
            verifierIdList.push(verifier._id);
          }
        }
      }

      let vsRrptNumber = vsReport.vs_rpt_number && vsReport.vs_rpt_number ? vsReport.vs_rpt_number : "";
      
      let reportIssueDate = vsReport.vs_rpt_issue_date ? new Date(parseInt(vsReport.vs_rpt_issue_date)): new Date();
      let tempVsRptIssueDate = vsReport.vs_rpt_issue_date ? new Date(parseInt(vsReport.vs_rpt_issue_date)): "N/A"
      let vsRptIssueDate = tempVsRptIssueDate === "N/A"? tempVsRptIssueDate : formatDateToLocaleDateString(tempVsRptIssueDate);
      
      let vsRptSchemeRef = vsReport.vs_rpt_scheme_ref && vsReport.vs_rpt_scheme_ref ? vsReport.vs_rpt_scheme_ref : "";

      let dhId = vsReport.dh_id && vsReport.dh_id._id ? vsReport.dh_id._id : "";
      let dhName = vsReport.dh_id && vsReport.dh_id.dh_name ? vsReport.dh_id.dh_name : "N/A";
      let instlList = vsReport.installation_ids ? vsReport.installation_ids : [];
      let instlId = instlList.length ? instlList[0]._id : "";
      let installationId = instlList.map(inst => inst._id);

      let approvalDate = vsReport.vs_rpt_approved_date ? new Date(parseInt(vsReport.vs_rpt_approved_date)) : new Date();

      let rptDescriptionAlt = htmlToPdfmake(vsReport.vs_rpt_description, {
        defaultStyles:{ // change the default styles
          p: {
            margin: [0,0,0,0]
          },
          br: {
            margin: [0,0,0,0]
          }
        }
      });

      const adjustedRptDescriptionAlt = adjustTextNode(rptDescriptionAlt);

      let rptSummaryAlt = htmlToPdfmake(vsReport.vs_rpt_summary);
      const adjustedRptSummaryAlt = adjustTextNode(rptSummaryAlt);

      let rptScopeAlt = htmlToPdfmake(vsReport.vs_rpt_scope)
      const adjustedRptScopeAlt = adjustTextNode(rptScopeAlt);

      let rptStatementAlt = htmlToPdfmake(vsReport.vs_rpt_statement)
      const adjustedRptStatementAlt = adjustTextNode(rptStatementAlt);
      //const formatedRptStatementAlt = formatPdfDescription(adjustedRptStatementAlt);

      let rptImprovementsAlt = htmlToPdfmake(vsReport.vs_rpt_improvements)
      const adjustedRptImprovementsAlt = adjustTextNode(rptImprovementsAlt);

      let rptConcernsAlt = htmlToPdfmake(vsReport.vs_rpt_concerns)
      const adjustedRptConcernsAlt = adjustTextNode(rptConcernsAlt);

      let rptPositivesAlt = htmlToPdfmake(vsReport.vs_rpt_positives)
      const adjustedRptPositivesAlt = adjustTextNode(rptPositivesAlt);

      let report = {
        rptIsPeriodic: vsReport.vs_rpt_is_periodic,
        rptTitle: vsReport.vs_rpt_title,
        rptDescription: vsReport.vs_rpt_description,
        rptDescriptionAlt: adjustedRptDescriptionAlt,
        rptSummary: vsReport.vs_rpt_summary,
        rptSummaryAlt: adjustedRptSummaryAlt,
        rptScope: vsReport.vs_rpt_scope,
        rptScopeAlt: adjustedRptScopeAlt,
        rptStatement: vsReport.vs_rpt_statement,
        rptStatementAlt: adjustedRptStatementAlt,
        rptImprovements: vsReport.vs_rpt_improvements,
        rptImprovementsAlt: adjustedRptImprovementsAlt,
        rptConcerns: vsReport.vs_rpt_concerns,
        rptConcernsAlt: adjustedRptConcernsAlt,
        rptPositives: vsReport.vs_rpt_positives,
        rptPositivesAlt: adjustedRptPositivesAlt,
        rptPaymentDetails: vsReport.vs_rpt_payment_details,
        instlId: instlId,
        vsId: vsReport.vs_id ? vsReport.vs_id._id : "",
        vsIdList: vsReport.vs_ids ? vsReport.vs_ids.map(vs => vs._id) : [],
        rptStartDate: vsReport.vs_rpt_start_date ? new Date(parseInt(vsReport.vs_rpt_start_date)) : "",
        rptEndDate: vsReport.vs_rpt_end_date ? new Date(parseInt(vsReport.vs_rpt_end_date)) : "",
        rptStatus: vsReport.vs_rpt_status,
        rptApprovalDate: formatDateToLocaleDateString(approvalDate),
        scheduleLastDate: formatDateToLocaleDateString(scheduleLastDate)
      }

      let reportTitle = createReportTitleFrom(dhName, instlList);

      setVsPdfReportTitle(reportTitle);
      setVsPdfReportSubTitle(report.rptTitle);

      setVsReport(report);
      setVsDutyHolder(dhName);
      setVsInstallations(instlList);

      setReportNumber(vsRrptNumber);
      setVerSchemeRef(vsRptSchemeRef);
      setIssueDate(vsRptIssueDate);
      setResultObject(prevState => ({...prevState, verificationScheduleReport: true}));

      if (verifierIdList.length) {
        user_by_ids({ variables: { idList: verifierIdList }, errorPolicy: 'all' });
      }

      let endDateAlt = new Date(reportIssueDate);

      endDateAlt.setMonth(endDateAlt.getMonth() + 1);
   
      if (dhId && dhId !== "") {      
        let payload = {
          dh_id: dhId,
          inst_ids: installationId,
          vs_id_list: [],
          end_date: endDateAlt
        }
        detailedSeceBreakdownFromBactchRecord({ variables: { param: payload }, errorPolicy: 'all' });
      }

      if (vsList.length) { 

        let endDate = reportIssueDate;     
        let vsIdList = vsList.map(vs => vs._id);
       
        let payload = {
          vs_id_list: vsIdList,
          end_date: endDate
        }

        let payloadAlt1 = {
          dh_id: dhId,
          inst_ids: installationId,
          vs_id_list: vsIdList,
          end_date: endDate
        }
       
        activitiesStatusByVsIdListFromHistoryAlt({ variables: {  param: payload }, errorPolicy: 'all' });  
        findingSearchFromHistoryAlt({ variables: {  param: payload }, errorPolicy: 'all' });

        findingStatsFromHistory({ variables: {  param: payloadAlt1 }, errorPolicy: 'all' });
        findingStatsGroupByNatureFromHistory({ variables: {  param: payloadAlt1 }, errorPolicy: 'all' });
        findingStatsGroupByIssuesFromHistory({ variables: {  param: payloadAlt1 }, errorPolicy: 'all' });

        reportsByVsIdListFromHistory({ variables: {  param: payload }, errorPolicy: 'all' });
        findingsByVsIdListFromHistory({ variables: {  param: payload }, errorPolicy: 'all' });     

        let startDate = new Date(endDate);      
        startDate.setMonth(startDate.getMonth() - VS_GRAPH_DISPLAY_MONTHS);

        let modStartDate = new Date(startDate);
        modStartDate.setMonth(modStartDate.getMonth() - 2);

        let modEndDate = new Date(endDate);
        modEndDate.setMonth(modEndDate.getMonth() + 2);

        let payloadAlt = {
          vs_id_list: vsIdList,
          start_date: modStartDate,
          end_date: modEndDate
        }
        verficationScheduleStatsByIdListAltFromHistory({ variables: { param: payloadAlt }, errorPolicy: 'all' });
      }
    }
  }, [vsReportData]);

  useEffect(() => {
    if (vsReportEditorsData && vsReportEditorsData.verificationScheduleReportEditors) {
      let editors = vsReportEditorsData.verificationScheduleReportEditors;
      const {author, reviewer} = editors ? editors : {}
      setPdfAuthorDetail(author);
      setPdfReviewerDetail(reviewer)
      setResultObject(prevState => ({...prevState, verificationScheduleReportEditors: true}));
    }
  }, [vsReportEditorsData]);

  useEffect(() => {
    if (scheduledVaStatusData && scheduledVaStatusData.activitiesStatusByVsIdListFromHistoryAlt) {
      let scheduledVaList = scheduledVaStatusData.activitiesStatusByVsIdListFromHistoryAlt;
      const { completedVas, commencedVas, notCommencedVas } = scheduledVaList ? scheduledVaList : {};
      setCompletedVaList(completedVas);
      setCommencedVaList(commencedVas);
      setNotCommencedVaList(notCommencedVas);
      setResultObject(prevState => ({...prevState, activitiesStatusByVsIdList: true}));
    }
  }, [scheduledVaStatusData]);

  useEffect(() => {
    if (fndRegData && fndRegData.findingSearchFromHistoryAlt) {
      setRaisedFindings(fndRegData.findingSearchFromHistoryAlt);
      setResultObject(prevState => ({...prevState, findingRegisterList: true}));
    }
  }, [fndRegData]);

  useEffect(() => {
    if (openFindingsData && openFindingsData.findingStatsFromHistory) {
      setOpenFindingsStats({
        date: openFindingsData.findingStatsFromHistory.date,
        level: openFindingsData.findingStatsFromHistory.level,
      });
      setResultObject(prevState => ({...prevState, findingStats: true}));
    }
  }, [openFindingsData]);

  useEffect(() => {
    if (natureFindingsData && natureFindingsData.findingStatsGroupByNatureFromHistory) {
      setNatureFindingsStats({
        date: natureFindingsData.findingStatsGroupByNatureFromHistory.date,
        level: natureFindingsData.findingStatsGroupByNatureFromHistory.level,
      });
      setResultObject(prevState => ({...prevState, findingStatsGroupByNature: true}));
    }
  }, [natureFindingsData]);

  useEffect(() => {
    if (issuesFindingsData && issuesFindingsData.findingStatsGroupByIssuesFromHistory) {
      const findingsByIssueData = issuesFindingsData.findingStatsGroupByIssuesFromHistory;
      let filteredList = filterFindingsByIssue(findingsByIssueData);

      setIssuesFindingsStats(filteredList);
      let findingsByIssueBarChart = createDataForFindingsByIssueBarChart(findingsByIssueData);
      setIssuesFindingsStatsBarChart(findingsByIssueBarChart);

      setResultObject(prevState => ({...prevState, findingStatsGroupByIssues: true}));
    }
  }, [issuesFindingsData]);

  useEffect(() => {
    if (vsVerifierData && vsVerifierData.user_by_ids) {
      let users = vsVerifierData.user_by_ids;
      let formatedVerifiers = users.map(user => {
        const { user_fname = "", user_lname = "" } = user ? user : {};
        return `${user_fname} ${user_lname}`;
      });
      setVsVerifiers(formatedVerifiers);
      setResultObject(prevState => ({...prevState, user_by_ids: true}));
    }
  }, [vsVerifierData]); 

  useEffect(() => {
    if (seceBreakdownData && seceBreakdownData.detailedSeceBreakdownFromBactchRecord) {
      let breakDown = seceBreakdownData.detailedSeceBreakdownFromBactchRecord;
      setSeceBreakdowns(breakDown);
      setResultObject(prevState => ({...prevState, detailedSeceBreakdown: true}));
    }
  }, [seceBreakdownData]);


  useEffect(() => {
    if (reportsByVsIdListData && reportsByVsIdListData.reportsByVsIdListFromHistory) {
      let reports = reportsByVsIdListData.reportsByVsIdListFromHistory;
      let formatedVaReports = formatVaReportData(reports, statementList, statusList);
      setVaReportList(formatedVaReports);
      setResultObject(prevState => ({...prevState, reportsByVsIdList: true}));
    }
  }, [reportsByVsIdListData]);

  useEffect(() => {
    if (findingsByVsIdListData && findingsByVsIdListData.findingsByVsIdListFromHistory) {
      let formatedFindings = formatFindingData(findingsByVsIdListData.findingsByVsIdListFromHistory);
      let sortedFormatedFindings = orderBy(formatedFindings, ['findingRef'], ['asc']);
      setVaFindingList(sortedFormatedFindings);
      setResultObject(prevState => ({...prevState, findingsByVsIdList: true}));
    }
  }, [findingsByVsIdListData]);

  useEffect(() => {
    if (ReportRevisionData && ReportRevisionData.reportRevisionsByParentId) {
      let reportRevisions = ReportRevisionData.reportRevisionsByParentId;
      let latestRevision = getLatestRevisionFrom(reportRevisions);

      setCurrentRevision(latestRevision)
      setVsReportHistories(ReportRevisionData.reportRevisionsByParentId);
      setResultObject(prevState => ({...prevState, reportRevisionsByParentId: true}));
    }
  }, [ReportRevisionData]);

  useEffect(() => {
    if (vsListStatsResult && vsListStatsResult.verficationScheduleStatsByIdListAltFromHistory) {
      setVsStatsData(vsListStatsResult.verficationScheduleStatsByIdListAltFromHistory);
      setResultObject(prevState => ({...prevState, verficationScheduleStatsByIdList: true}));
    }
  }, [vsListStatsResult]);

  //Sece Detialed Breakdown Column Start
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'SECEref',
        columns: [{
          Header: 'SECE Ref.',
          accessor: 'sece_ref',
        }]
      },
      {
        Header: '',
        id: 'SECEtitle',
        columns: [{
          Header: 'SECE Title',
          accessor: 'sece_title',
        }]
      },
      {

        Header: `End of Date Range - ${issueDate}`,

        id: 'EndOfDateRange',
        columns: [
          {
            Header: 'Completion',
            id: 'completion',
            columns: [
              {
                Header: 'Offshore',

                accessor: 'offshore',

                Cell: off => (
                  <span className={`flex py-1 px-2 justify-center ${[off.value === 0 ? "bg-red-900 text-white" : off.value <= 50 ? "bg-blue-300 text-white" : off.value <= 75 ? "bg-yellow-900 text-black" : off.value <= 100 ? "bg-green-900 text-black" : null]}`}>
                    {off.value}%
                  </span>
                ),
              }, {
                Header: 'Onshore',

                accessor: 'onshore',
                Cell: on => (

                  <span className={`flex py-1 px-2 justify-center ${[on.value === 0 ? "bg-red-900 text-white" : on.value <= 50 ? "bg-blue-300 text-white" : on.value <= 75 ? "bg-yellow-900 text-black" : on.value <= 100 ? "bg-green-900 text-black" : null]}`}>

                    {on.value}%
                  </span>
                ),
              }, {
                Header: 'Remote',

                accessor: 'remote',
                Cell: rem => (

                  <span className={`flex py-1 px-2 justify-center ${[rem.value === 0 ? "bg-red-900 text-white" : rem.value <= 50 ? "bg-blue-300 text-white" : rem.value <= 75 ? "bg-yellow-900 text-black" : rem.value <= 100 ? "bg-green-900 text-black" : null]}`}>
                    {rem.value}%
                  </span>
                ),
              }
            ]
          },
          {
            Header: 'Findings',
            id: 'findings',
            columns: [
              {
                Header: 'Level 1',
                accessor: 'level1',
                Cell: lev1 => (
                  <span className={"flex py-1 px-2 justify-center bg-blue-300 text-black"}>
                    {lev1.value}
                  </span>
                ),
              },
              {
                Header: 'Level 2',
                accessor: 'level2',
                Cell: lev2 => (
                  <span className={"flex py-1 px-2 justify-center bg-yellow-900"}>
                    {lev2.value}
                  </span>
                ),
              },
              {
                Header: 'Level 3',
                accessor: 'level3',
                Cell: lev3 => (
                  <span className={"flex py-1 px-2 justify-center bg-red-900 text-white"}>
                    {lev3.value}
                  </span>
                ),
              }
            ]
          }
        ]
      }
    ],
    [vsReport]
  )
  //Sece Detailed Breakdown Column End

  //Open findings status pie base64
  const openFindingRef = useRef(null);
  const [base64openFinding, setBase64openFinding] = useState('');
  function doneOpenFindings() {
    setBase64openFinding(openFindingRef.current.chartInstance.toBase64Image());
  }

  //Open findings level pie base64
  const openFindingLevelRef = useRef(null);
  const [base64openFindingLevel, setBase64openFindingLevel] = useState('');
  function doneOpenFindingsLevel() {
    setBase64openFindingLevel(openFindingLevelRef.current.chartInstance.toBase64Image());
  }

  const FindingsByNatureStatus = useRef(null);
  const [base64FindingsByNatureStatus, setBase64FindingsByNatureStatus] = useState('');
  function doneFindingsByNatureStatus() {
    setBase64FindingsByNatureStatus(FindingsByNatureStatus.current.chartInstance.toBase64Image());
  }

  const FindingsByNatureLevel = useRef(null);
  const [base64FindingsByNatureLevel, setBase64FindingsByNatureLevel] = useState('');
  function doneFindingsByNatureLevel() {
    setBase64FindingsByNatureLevel(FindingsByNatureLevel.current.chartInstance.toBase64Image());
  }

  const FindingsIssuesBarChart = useRef(null);
  const [base64FindingsIssuesBarChart, setBase64FindingsIssuesBarChart] = useState('');
  function doneFindingsIssuesBar() {
    setBase64FindingsIssuesBarChart(FindingsIssuesBarChart.current.chartInstance.toBase64Image());
  }

  const VerificationScheduleRef = useRef(null);
  const [base64VerificationSchedule, setBase64VerificationSchedule] = useState('');
  function doneVerificationSchedule() {
    setBase64VerificationSchedule(VerificationScheduleRef.current.chartInstance.toBase64Image());
  }

  useEffect(() => {
    let areAllChartBaseImagesUpdated = base64VerificationSchedule !== "" &&
      base64openFinding !== "" &&
      base64openFindingLevel !== "" &&
      base64FindingsByNatureStatus !== "" &&
      base64FindingsByNatureLevel !== "" &&
      base64FindingsIssuesBarChart !== "";
    setChartImageStatus(areAllChartBaseImagesUpdated);
  }, [base64VerificationSchedule,
    base64openFinding,
    base64openFindingLevel,
    base64FindingsByNatureStatus,
    base64FindingsByNatureLevel,
    base64FindingsIssuesBarChart]);

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 35],
    header: function (currentPage, pageCount) {
      if (currentPage !== 1 && currentPage !== pageCount) {
        var headerObj;
        if (currentPage % 2) {
          headerObj = {
            table: {
              headerRows: 0,
              widths: ['75%', '25%'],
              body: [
                [
                  {
                    border: [false, false, true, false],
                    fillColor: '#002a4e',
                    stack: [
                      { text: vsDutyHolder, font: 'Montserrat', fontSize: 9, color: '#fff', margin: 0 },
                      { text: `Report No. ${reportNumber} ${currentRevision}`, font: 'Montserrat', fontSize: 9, color: '#fff' },
                      [{
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 30,
                            h: 0.5,
                            r: 0,
                            lineWidth: 1,
                            lineColor: 'red',
                          }
                        ]
                      }],
                      { text: 'ASSET VERIFICATION REPORT', font: 'Montserrat', fontSize: 12, color: '#fff', bold: true, marginTop: 3 }
                    ]
                  },
                  {
                    border: [true, false, false, false],
                    fillColor: '#b40c1e',
                    ...(logoImage && logoImage !=="" && {image: logoImage}),
                    width: 100
                  },
                ]
              ],
            },
            layout: {
              vLineColor: function (i, node) { return 'white'; },
              vLineWidth: function (i) { return 4; },
              paddingLeft: function (i, node) { return 30; },
              paddingRight: function (i, node) { return 10; },
              paddingTop: function (i, node) { return 20; },
              paddingBottom: function (i, node) { return 10; },
            }
          };
        } else {

          headerObj = {
            table: {
              headerRows: 0,
              widths: ['25%', '75%'],
              body: [
                [
                  {
                    border: [false, false, true, false],
                    fillColor: '#b40c1e',

                    ...(logoImage && logoImage !=="" && {image: logoImage}),

                    width: 100
                  },
                  {
                    border: [true, false, false, false],
                    fillColor: '#002a4e',
                    stack: [

                      { text: vsDutyHolder, font: 'Montserrat', fontSize: 9, color: '#fff', margin: 0 },
                      { text: `Report No. ${reportNumber} ${currentRevision}`, font: 'Montserrat', fontSize: 9, color: '#fff' },

                      [{
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 30,
                            h: 0.5,
                            r: 0,
                            lineWidth: 1,
                            lineColor: 'red',
                          }
                        ]
                      }],
                      { text: 'ASSET VERIFICATION REPORT', font: 'Montserrat', fontSize: 12, color: '#fff', bold: true, marginTop: 3 }
                    ]
                  },
                ]
              ],
            },
            layout: {
              vLineColor: function (i, node) { return 'white'; },
              vLineWidth: function (i) { return 4; },
              paddingLeft: function (i, node) { return 30; },
              paddingRight: function (i, node) { return 10; },
              paddingTop: function (i, node) { return 20; },
              paddingBottom: function (i, node) { return 10; },
            }
          };

        }
      }
      return headerObj;
    },
    footer: function (currentPage, pageCount) {
      if (currentPage !== 1 && currentPage !== pageCount) {
        if (currentPage !== 1 && currentPage !== pageCount) {
          var footerObj;

          footerObj = [
            { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, font: 'Montserrat', fontSize: 8, alignment: 'center' },
            { text: `Copyright © by ABS. All rights reserved. CONFIDENTIAL`, font: 'Montserrat', fontSize: 8, alignment: 'center' }
          ]

          //return currentPage.toString() -1; 
          return footerObj;
        }
      }

    },
    //metadata
    info: {
      title: 'Client - Asset Verification Report',
      author: pdfAuthorDetail.full_name ? pdfAuthorDetail.full_name : "N/A",
    },
    content: [

      //COVER PAGE
      {
        table: {
          widths: ['100%'],
          heights: [200],
          body: [
            [{
              fillColor: '#b40c1e', border: [false, false, false, true],
              stack: [
                ...(vmLogo && vmLogo !== "" && [{ image: vmLogo, width: 300, marginBottom: 25 }]),
                { text: 'ASSET', font: 'Montserrat', fontSize: 30, color: '#fff', bold: true, lineHeight: 1 },
                { text: 'VERIFICATION', font: 'Montserrat', fontSize: 30, color: '#fff', bold: true, lineHeight: 1 },
                { text: 'REPORT', font: 'Montserrat', fontSize: 30, color: '#fff', bold: true, lineHeight: 1, marginBottom: 20 },
                [{
                  canvas: [
                    {
                      type: 'rect',
                      x: 0,
                      y: 0,
                      w: 105,
                      h: 0.5,
                      r: 0,
                      lineWidth: 1,
                      lineColor: 'white',
                    }
                  ]
                }],
              ]
            }],
          ],
        },
        layout: {
          vLineColor: function (i, node) { return '#fff'; },
          vLineWidth: function (i) { return 0; },
          hLineColor: function (i, node) { return 'white'; },
          hLineWidth: function (i) { return 6; },
          paddingLeft: function (i, node) { return 40; },
          paddingRight: function (i, node) { return 40; },
          paddingTop: function (i, node) { return 40; },
          paddingBottom: function (i, node) { return 10; },
        },
      },
      {
        table: {
          widths: ['100%'],
          heights: [100],
          body: [
            [{
              stack: [
                { text: vsPdfReportTitle, font: 'Montserrat', fontSize: 14, lineHeight: 1 },
                { text: vsPdfReportSubTitle, font: 'Montserrat', fontSize: 14, lineHeight: 1, marginBottom: 20 },
                { text: `Report No. ${reportNumber} ${currentRevision}`, font: 'Montserrat', fontSize: 14, lineHeight: 1 },
                { text: `Date of Issue: ${issueDate}`, font: 'Montserrat', fontSize: 14, lineHeight: 1, marginBottom: 20 },
                { text: `Verification Scheme Ref(s): ${verSchemeRef}`, font: 'Montserrat', fontSize: 14, lineHeight: 1 },
              ]
            }]
          ],
        },
        layout: {
          vLineColor: function (i, node) { return '#fff'; },
          vLineWidth: function (i) { return 0; },
          hLineColor: function (i, node) { return 'white'; },
          hLineWidth: function (i) { return 6; },
          paddingLeft: function (i, node) { return 0; },
          paddingRight: function (i, node) { return 0; },
          paddingTop: function (i, node) { return 30; },
          paddingBottom: function (i, node) { return 30; },
        },
      },
      {
        table: {
          widths: ['100%'],
          heights: [120],
          body: [
            [{
              fillColor: '#002a4e', border: [false, true, false, false],
              stack: [
                ...(logoImage && logoImage !== "" && [{ image: logoImage, width: 225, alignment: 'center' }]),
              ]
            }]
          ],
        },
        layout: {
          vLineColor: function (i, node) { return '#fff'; },
          vLineWidth: function (i) { return 0; },
          hLineColor: function (i, node) { return 'white'; },
          hLineWidth: function (i) { return 6; },
          paddingLeft: function (i, node) { return 40; },
          paddingRight: function (i, node) { return 40; },
          paddingTop: function (i, node) { return 40; },
          paddingBottom: function (i, node) { return 10; },
        },
        pageBreak: 'after'
      },
      //COVER PAGE END


      //DOCUMENT CONTROL
      [{
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 30,
            h: 0.5,
            r: 0,
            lineWidth: 1,
            lineColor: '#b40c1e',
          }
        ]
      }], //red line
      { text: 'DOCUMENT CONTROL', style: 'title' },
      {
        columns: [
          //empty col for left spacing
          {
            width: 40,
            text: ''
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              //REVISIONS TABLE
              {
                table: {
                  widths: ['auto', 'auto', '*', 'auto', 'auto'], //col widths
                  headerRows: 2,
                  body: [
                    [{ text: 'REVISION CONTROL', style: 'StandardTableHeader', colSpan: 5, alignment: 'center', borderColor: ['#000', '#000', '#000', '#fff'] }, {}, {}, {}, {}],//first header
                    [{ text: 'Rev.', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#000', '#fff', '#fff', '#fff'] }, { text: 'Date', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, { text: 'Description', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, { text: 'Revised By', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, { text: 'Approved By', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#fff', '#fff', '#000', '#fff'] }],//second header
                    ...createRevisionTableRows(vsReportHistories)
                  ]
                },
                style: 'standardTable',
              },

              //DOCUMENTS TABLE

              /*
              {
                table: {
                  widths: ['33.3%', '33.3%', '33.3%'], //col widths
                  headerRows: 1,
                  body: [
                    [{ text: 'Issue Number', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#000', '#000', '#fff', '#fff'] }, { text: 'Document Holder', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#000', '#000', '#fff', '#fff'] }, { text: 'Location', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#fff', '#000', '#000', '#fff'] }],//header
                    ...createDocumentTableRows(reportIssuanceList)
                  ]
                },
                style: 'standardTable',
              },
              */
              // { text: ['This is to certify that the undersigned Surveyor to this Bureau, did at the request of the Duty Holder’s Representative, attend the Maersk Drilling office in Lyngby, to complete onshore Verification activities for Maersk Resolve, ', { text: 'ABSID 09162307', color: '#b40c1e' }, { text: ', of ' }, { text: 'Republic of Singapore.', color: '#b40c1e' }] },
              { stack: vsReport.rptDescriptionAlt },
              // { text: 'In here will go a standard statement that will be created in the “General Template”.', marginTop: 10 },
              {
                table: {
                  widths: ['27%', '1%', '27%', '1%', '27%', '1%', '16%'], //col widths
                  headerRows: 2,
                  body: [
                    [
                      { text: pdfAuthorDetail.full_name, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: pdfAuthorDetail.location && pdfAuthorDetail.location !== "" ? pdfAuthorDetail.location : "N/A", alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      //{ text: 'J. Bloggs', alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      ...(pdfAuthorDetail.signature && pdfAuthorDetail.signature !== "" ? [{ image: pdfAuthorDetail.signature, width: 90, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }] : [{ text: pdfAuthorDetail.full_name, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }]),
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: pdfAuthorDetail.date, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }
                    ],
                    [
                      { text: 'Author Name', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Location', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Signature', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Date', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] }
                    ],
                  ]
                },
                layout: CenteredLayout,
                style: 'signatureTable',
              },
              {

                table: {
                  widths: ['27%', '1%', '27%', '1%', '27%', '1%', '16%'], //col widths
                  headerRows: 2,
                  body: [
                    [
                      { text: pdfReviewerDetail.full_name, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: pdfReviewerDetail.location && pdfReviewerDetail.location !== "" ? pdfReviewerDetail.location : "N/A", alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      //{ text: 'J. Doe', alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      ...(pdfReviewerDetail.signature && pdfReviewerDetail.signature !== "" ? [{ image: pdfReviewerDetail.signature, width: 90, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }] : [{ text: pdfReviewerDetail.full_name, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }]),
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: pdfReviewerDetail.date, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }
                    ],
                    [
                      { text: 'Reviewer Name', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Location', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Signature', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Date', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] }
                    ]
                  ]
                },
                layout: CenteredLayout,
                style: 'signatureTable',
                pageBreak: 'after'

              },
            ],
          },
        ],
      },
      //DOCUMENT CONTROL END

      //TABLE OF CONTENTS
      [{
        canvas: [
          {

            type: 'rect',
            x: 0,
            y: 0,
            w: 30,
            h: 0.5,
            r: 0,
            lineWidth: 1,
            lineColor: '#b40c1e',
          }
        ]
      }], //red line
      { text: 'TABLE OF CONTENTS', style: 'title' },
      {
        columns: [
          //empty col for left spacing
          {
            width: 40,
            text: ''

          },
          //content goes in this col
          {
            width: '*',
            table: {
              widths: ['auto', '*', 'auto'], //col widths
              headerRows: 0,
              body: [
                ['1.', { textReference: 'executive' }, { pageReference: 'executive' }],
                ['2.', { textReference: 'extent-of-ver' }, { pageReference: 'extent-of-ver' }],
                [' ', { text: ['2.1 ', { textReference: 'additional-info' }] }, { pageReference: 'additional-info' }],
                [' ', { text: ['2.2 ', { textReference: 'completedActivities' }] }, { pageReference: 'completedActivities' }],
                [' ', { text: ['2.3 ', { textReference: 'commencedActivities' }] }, { pageReference: 'commencedActivities' }],
                [' ', { text: ['2.4 ', { textReference: 'notCommencedActivities' }] }, { pageReference: 'notCommencedActivities' }],
                ['3.', { textReference: 'ver-activities' }, { pageReference: 'ver-activities' }],
                ['4.', { textReference: 'findings' }, { pageReference: 'findings' }],
                [' ', { text: ['4.1 ', { textReference: 'findingRegister' }] }, { pageReference: 'findingRegister' }],
                [' ', { text: ['4.2 ', { textReference: 'finding-details' }] }, { pageReference: 'finding-details' }],
                [' ', { text: ['4.3 ', { textReference: 'findingStats' }] }, { pageReference: 'findingStats' }],
                [' ', { text: ['4.3.1 ', { textReference: 'findingsOverview' }] }, { pageReference: 'findingsOverview' }],
                [' ', { text: ['4.3.2 ', { textReference: 'findingsNature' }] }, { pageReference: 'findingsNature' }],
                [' ', { text: ['4.3.3 ', { textReference: 'findingsIssues' }] }, { pageReference: 'findingsIssues' }],
                ['5.', { textReference: 'ver-status' }, { pageReference: 'ver-status' }],
                [' ', { text: ['5.1 ', { textReference: 'seceStatus' }] }, { pageReference: 'seceStatus' }],
                [' ', { text: ['5.2 ', { textReference: 'verSchedule' }] }, { pageReference: 'verSchedule' }],
                ['6.', { textReference: 'improvement' }, { pageReference: 'improvement' }],
                ['7.', { textReference: 'concern' }, { pageReference: 'concern' }],
                ['8.', { textReference: 'attendance' }, { pageReference: 'attendance' }],
              ],
            },
            layout: 'noBorders',
            pageBreak: 'after'
          }
        ],
      },
      //TABLE OF CONTENTS END

      //OUR MISSION
      [{
        canvas: [
          {

            type: 'rect',
            x: 0,
            y: 0,
            w: 30,
            h: 0.5,
            r: 0,
            lineWidth: 1,
            lineColor: '#b40c1e',
          }
        ]
      }], //red line
      { text: 'OUR MISSION', style: 'title' },
      {
        columns: [
          //empty col for left spacing
          {
            width: 40,
            text: ''
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'The mission of ABS is to serve the public interest as well as the needs of our members and clients by promoting the security of life and property and preserving the natural environment.', style: 'leadText' },
              { text: 'ABS HEALTH, SAFETY, QUALITY & ENVIRONMENTAL POLICY', style: 'leadText', bold: true },
              { text: [{ text: 'We will respond', bold: true }, ' to the needs of our members, clients and the public by delivering quality products and services in support of our Mission that provides for the safety of life and property and the protection of the natural environment.',], marginBottom: 10, font: 'Montserrat', color: '#002a4e' },
              { text: [{ text: 'With the input and the participation of our workers,', bold: true }, ' we are committed to continually improving the effectiveness of our HSQE performance and management system by identifying risks and opportunities that help to eliminate hazards and reduce risks and by providing safe and healthy working conditions for the prevention of work related injury, ill health and pollution.'], marginBottom: 10, font: 'Montserrat', color: '#002a4e' },
              { text: [{ text: 'We will comply', bold: true }, ' with all applicable legal requirements as well as any additional requirements ABS subscribes to which relate to ABS and our HSQE aspects, objectives and targets.'], font: 'Montserrat', color: '#002a4e', pageBreak: 'after' },

            ]
          },
        ]
      },
      //OUR MISSION END


      //EXECUTIVE SUMMARY
      {
        columns: [
          {
            width: 40,
            text: '1',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'EXECUTIVE SUMMARY', style: 'title', id: 'executive' },            
              { stack: vsReport.rptSummaryAlt },
            ]
          }
        ]
      },
      //EXECUTIVE SUMMARY END

      //EXTENT OF VERIFICATION
      {
        columns: [
          {
            width: 40,
            text: '2',
            style: 'title',
          },        
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'EXTENT OF VERIFICATION', style: 'title', id: 'extent-of-ver' },
              { stack: vsReport.rptScopeAlt },
            ]
          }
        ]
      },
      //EXTENT OF VERIFICATION END

      //ADDITIONAL INFORMATION
      {
        columns: [
          {
            width: 40,
            text: '2.1',
            style: 'title',
          },

          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Additional Information', style: 'title', id: 'additional-info' },
              { stack: vsReport.rptStatementAlt, marginBottom: 20 },
            ],
            pageBreak: 'after'
          }
        ]
      },
      //ADDITIONAL INFORMATION END

      //COMPLETED ACTIVITIES
      {
        columns: [
          {
            width: 40,
            text: '2.2',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Completed Activities', style: 'title', id: 'completedActivities' },
              { text: 'The following Verification Activities were completed during the attendance(s) detailed within this report.', marginBottom: 5 },
              { text: 'KEY:' },
              {
                table: {
                  widths: [10, '*'],
                  headerRows: 0,
                  body: [
                    //rows
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#4CA342',
                            color: '#4CA342',
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were satisfied with regard to this Activity.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#5E8AB4',
                            color: '#5E8AB4'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were satisfied with regard to this Activity, however Low Level Finding(s) were raised during completion of this Activity, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#FFAD0A',
                            color: '#FFAD0A'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were not met with regard to this Activity, resulting in Mid Level Finding(s) being raised during completion, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#b40c1e',
                            color: '#b40c1e'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were not met with regard to this Activity, resulting in High Level Finding(s) being raised during completion, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],

                  ]
                },
              },
              {
                style: 'tableSM',
                table: {
                  widths: ['auto', 'auto', 'auto', '*', 40],
                  headerRows: 1,
                  body: [
                    //header
                    [
                      { text: 'SECE Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
                      { text: 'SECE Name', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Ref.', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Short Description', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'As Found Status', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] },
                    ],

                    ...createCompletedActivityTableRows(completedVaList)

                  ]
                }
              },
            ],
            pageBreak: 'after'
          }
        ]
      },
      //COMPLETED ACTIVITIES END

      //COMMENCED ACTIVITIES
      {
        columns: [
          {
            width: 40,
            text: '2.3',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Commenced Activities', style: 'title', id: 'commencedActivities' },
              { text: 'The following Verification Activities were Commenced during the attendance(s) detailed within this report.', marginBottom: 5 },
              { text: 'KEY:' },

              {
                table: {
                  widths: [10, '*'],
                  headerRows: 0,
                  body: [
                    //rows
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#4CA342',
                            color: '#4CA342',
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were satisfied with regard to this Activity.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#5E8AB4',
                            color: '#5E8AB4'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were satisfied with regard to this Activity, however Low Level Finding(s) were raised during completion of this Activity, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#FFAD0A',
                            color: '#FFAD0A'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were not met with regard to this Activity, resulting in Mid Level Finding(s) being raised during completion, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#b40c1e',
                            color: '#b40c1e'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were not met with regard to this Activity, resulting in High Level Finding(s) being raised during completion, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ]
                  ]
                },
              },
              {
                style: 'tableSM',
                table: {
                  widths: ['auto', 'auto', 'auto', '*', 40],
                  headerRows: 1,
                  body: [
                    //header
                    [
                      { text: 'SECE Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
                      { text: 'SECE Name', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Ref.', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Short Description', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'As Found Status', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] },
                    ],
                    ...createCommencedActivityTableRows(commencedVaList)
                  ]
                }
              },
            ],
            pageBreak: 'after'
          }
        ]
      },
      //COMMENCED ACTIVITIES END

      //NOT COMMENCED ACTIVITIES
      {
        columns: [
          {
            width: 40,
            text: '2.4',
            style: 'title',
          },
          //content goes in this col
          {

            width: '*',
            stack: [
              { text: 'Not Commenced Activities', style: 'title', id: 'notCommencedActivities' },
              { text: 'The following Verification Activities were Not Commenced during the attendance(s) detailed within this report.', marginBottom: 5 },
              { text: 'KEY:' },

              {
                table: {
                  widths: [10, '*'],
                  headerRows: 0,
                  body: [
                    //rows
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#5E8AB4',
                            color: '#5E8AB4'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Activity Not Commenced, however Low Level Finding(s) were raised, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#FFAD0A',
                            color: '#FFAD0A'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Activity Not Commenced, however Mid Level Finding(s) were raised, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#b40c1e',
                            color: '#b40c1e'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Activity Not Commenced, however High Level Finding(s) were raised, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: 'black',
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'No Verification has been conducted with regard to this Activity.', border: [false, false, false, false] }
                    ]
                  ]
                },
              },
              {
                style: 'tableSM',
                table: {
                  widths: ['auto', 'auto', 'auto', '*', 40],
                  headerRows: 1,
                  body: [
                    //header
                    [
                      { text: 'SECE Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
                      { text: 'SECE Name', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Ref.', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Short Description', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'As Found Status', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] },
                    ],
                    ...createNotCommencedActivityTableRows(notCommencedVaList)
                  ]
                }
              },
            ],
            pageBreak: 'after'
          }
        ]
      },
      //NOT COMMENCED ACTIVITIES END

      //VERIFICATION ACTIVITIES
      {
        columns: [
          {
            width: 40,
            text: '3',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'VERIFICATION ACTIVITIES (Detailed)', style: 'title', id: 'ver-activities' },
              ...createVADetailTableRows(vaReportList)
            ],
          }
        ]
      },
      //VERIFICATION ACTIVITIES END

      //FINDINGS
      {
        columns: [
          {
            width: 40,
            text: '4',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'FINDINGS', style: 'title', id: 'findings', marginBottom: 5 },
            ]
          }
        ]
      },
      //FINDINGS END

      //FINDING REGISTER
      {
        columns: [
          {
            width: 40,
            text: '4.1',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Finding Register', style: 'title', id: 'findingRegister' },
              { text: 'The following Findings were raised (and closed where applicable) during the attendance(s) detailed within this report.', marginBottom: 5 },
              {
                style: 'tableSM',
                table: {
                  widths: ['auto', 'auto', '*', 40, 40],
                  headerRows: 1,
                  body: [
                    //header
                    [
                      { text: 'Finding Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'], alignment: 'center' },
                      { text: 'SECE Ref.', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'], alignment: 'center' },
                      { text: 'Synopsis', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Finding Level', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'], alignment: 'center' },
                      { text: 'Status', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'], alignment: 'center' },
                    ],

                    ...createFindingTableRows(raisedFindings)

                  ]
                }
              },
            ]
          }
        ],
        pageBreak: 'after'
      },
      //FINDING REGISTER END

      //FINDING DETAILS
      {
        columns: [
          {
            width: 40,
            text: '4.2',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Finding Details', style: 'title', id: 'finding-details' },
              ...createFindingDetailTableRows(vaFindingList)
            ],        
          }
        ]     
      },
      //FINDING DETAILS END

      //FINDING STATISTICS
      {
        columns: [
          {
            width: 40,
            text: '4.3',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Finding Statistics', style: 'title', id: 'findingStats' },
              { text: 'The following section provides statistical information relating to all Findings Open upon the issue date of this report.  For full details, refer to ABS Verification Manager™.'},
            ]
          }
        ]
      },
      //FINDING STATISTICS END

      //FINDINGS OVERVIEW
      {
        columns: [
          {
            width: 40,
            text: '4.3.1',
            style: 'title',
          },
          //content goes in this col
          [
            { text: 'Findings by Overview', style: 'title', id: 'findingsOverview' },
            { text: 'The following charts provide a summary of the status and level of Findings Open upon the issue date of this report.', marginBottom: 5 },
            {
              columns: [
                [
                  { text: 'ALL OPEN FINDINGS', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  ...(base64openFinding && base64openFinding !== "" ?  [{ image: base64openFinding, width: 225 }] :  [{ image: emptyImage, width: 225 }])
                ],
                [
                  { text: 'ALL OPEN FINDINGS', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  ...(base64openFindingLevel && base64openFindingLevel !== "" ?  [{ image: base64openFindingLevel, width: 225 }] :  [{ image: emptyImage, width: 225 }])
                ],
              ]
            }
          ]
        ]
      },
      //FINDING OVERVIEW END

      //FINDINGS NATURE
      {
        columns: [
          {
            width: 40,
            text: '4.3.2',
            style: 'title',
          },
          //content goes in this col
          [
            { text: 'Findings by Nature', style: 'title', id: 'findingsNature' },
            { text: 'The following charts detail the Nature by which Findings Open upon the issue date of this report were raised.', marginBottom: 5 },
            {
              columns: [
                [
                  { text: 'FINDINGS BY NATURE', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  ...(base64FindingsByNatureStatus && base64FindingsByNatureStatus !== "" ? [{ image: base64FindingsByNatureStatus, width: 225, marginRight: 10 }] : [{ image: emptyImage, width: 225, marginRight: 10 }])
                ],
                [
                  { text: 'FINDINGS BY NATURE', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  ...(base64FindingsByNatureLevel && base64FindingsByNatureLevel !== "" ? [{ image: base64FindingsByNatureLevel, width: 225 }]: [{ image: emptyImage, width: 225 }])
                ],
              ]
            }
          ]
        ]
      },
      //FINDING NATURE END

      //FINDINGS ISSUE
      {
        columns: [
          {
            width: 40,
            text: '4.3.3',
            style: 'title',
          },
          //content goes in this col
          [
            { text: 'Findings by Issue', style: 'title', id: 'findingsIssues' },
            { text: 'The following charts detail the issues associated with Findings Open upon the issue date of this report.', marginBottom: 5 },
            {
              columns: [
                [
                  { text: 'FINDINGS BY ISSUE', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  {
                    table: {
                      widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                      headerRows: 0,
                      body: [
                        //header
                        [
                          ...createIssueFindingsHeaderRows(issuesFindingsStats),                          
                        ],
                      ]
                    }
                  },
                  //remove width 100% and height auto from svg

                  { svg: issuesFindingsStatsSvg, width: 225, marginRight: 10 }
                ],
                [
                  { text: 'FINDINGS BY ISSUE', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  ...(base64FindingsIssuesBarChart && base64FindingsIssuesBarChart !== "" ?[{ image: base64FindingsIssuesBarChart, width: 225 }] : [{ image: emptyImage, width: 225 }])

                ],
              ]
            }
          ]
        ],
        pageBreak: 'after'
      },
      //FINDINGS ISSUE END

      //VERIFICTION STATUS
      {
        columns: [
          {
            width: 40,
            text: '5',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [

              { text: 'VERIFICATION STATUS', style: 'title', id: 'ver-status' },
              { text: 'The following section details the status of SECEs and associated Verification Activities detailed within this report. For full details, refer to ABS Verification Manager™.', marginBottom: 20 },

            ]
          }
        ]
      },
      //VERIFICTION STATUS END

      //SECE STATUS
      {
        columns: [
          {
            width: 40,
            text: '5.1',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'SECE Status', style: 'title', id: 'seceStatus' },
              { text: 'The following chart details the status of SECEs in terms of Completion of Verification and Findings assigned to them post attendance(s) detailed within this report.' },
              {
                style: 'tableSM',
                table: {
                  widths: ['*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                  headerRows: 3,
                  body: [
                    //header
                    [

                      { text: ' ', colSpan: 2, style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#fff'] },
                      {},
                      { text: `End of Date Range - ${issueDate}`, colSpan: 6, style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#fff'] },
                      {},
                      {},
                      {},
                      {},
                      {},
                    ],
                    [
                      { text: ' ', colSpan: 2, style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#fff'] },
                      {},
                      { text: 'Completion', colSpan: 3, style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      {},
                      {},
                      { text: 'Findings', colSpan: 3, style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#fff'] },
                      {},
                      {},

                    ],
                    [
                      { text: 'SECE Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
                      { text: 'SECE Name', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Offshore', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Onshore', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Remote', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Level 1', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Level 2', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Level 3', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] }
                    ],

                    ...createSeceStatusTableRows(seceBreakdowns)                 
                  ]
                }, marginBottom: 20

              },
            ]
          }
        ]
      },
      //SECE STATUS END

      //VERIFICATION SCHEDULE
      {
        columns: [
          {
            width: 40,
            text: '5.2',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Verification Schedule', style: 'title', id: 'verSchedule' },

              { text: 'The following chart details the overall Verification Schedule post attendance(s) detailed within this report.' },
              ...(base64VerificationSchedule && base64VerificationSchedule !== "" ? [{ image: base64VerificationSchedule, width: 450 }] : [{ image: emptyImage, width: 450 }]),

            ],
            pageBreak: 'after'
          }
        ]
      },
      //VERIFICATION SCHEDULE END

      //OPPORTUNITIES FOR IMPROVEMENT
      {
        columns: [
          {
            width: 40,
            text: '6',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'OPPORTUNITIES FOR IMPROVEMENT', style: 'title', id: 'improvement' },
              { stack: vsReport.rptImprovementsAlt},
            ]
          }
        ]
      },
      //OPPORTUNITIES FOR IMPROVEMENT END

      //AREAS OF CONCERN
      {
        columns: [
          {
            width: 40,
            text: '7',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'AREAS OF CONCERN', style: 'title', id: 'concern' },
              { stack: vsReport.rptConcernsAlt },
            ]
          }
        ]
      },
      //AREAS OF CONCERN END

      //POSITIVES FROM THE ATTENDANCE
      {
        columns: [
          {
            width: 40,
            text: '8',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'POSITIVES FROM THE ATTENDANCE', style: 'title', id: 'attendance' },
              { stack: vsReport.rptPositivesAlt },
            ]
          }
        ],
        pageBreak: 'after'
      },
      //POSITIVES FROM THE ATTENDANCE END

      //BACK COVER
      {
        table: {
          widths: ['100%'],
          heights: [275, 275],
          body: [
            [{
              fillColor: '#b40c1e', border: [false, false, false, true],
              stack: [
                ...(vmLogo && vmLogo !== "" ? [{ image: vmLogo, width: 350, alignment: 'center' }]: [{ image: emptyImage, width: 350, alignment: 'center' }]),
              ]
            }],
            [{
              fillColor: '#002a4e', border: [false, true, false, false],
              stack: [
                ...(logoImage && logoImage !== "" ? [{ image: logoImage, width: 250, marginTop: 70, alignment: 'center' }] : [{ image: emptyImage, width: 250, marginTop: 70, alignment: 'center' }]),
              ]
            }]
          ],

        },
        layout: {
          vLineColor: function (i, node) { return '#fff'; },
          vLineWidth: function (i) { return 0; },
          hLineColor: function (i, node) { return 'white'; },
          hLineWidth: function (i) { return 6; },
          paddingLeft: function (i, node) { return 40; },
          paddingRight: function (i, node) { return 40; },
          paddingTop: function (i, node) { return 40; },
          paddingBottom: function (i, node) { return 10; },
        }
      },
      //BACK COVER END

      //CONTENT END
    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      title: {
        marginTop: 10,
        font: 'Montserrat',
        fontSize: 14,
        bold: true,
        color: '#002a4e'
      },
      leadText: {
        marginBottom: 20,
        font: 'Montserrat',
        fontSize: 11,
        color: '#002a4e',
        //bold: true
      },
      redTitle: {
        marginBottom: 10,
        font: 'Montserrat',
        color: '#b40c1e'
      },
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      detailsTable: {
        marginBottom: 35,
        lineHeight: 1
      },
      detailsTable2: {
        lineHeight: 1
      },
      tableSM: {
        fontSize: 8
      },
      signatureTable: {
        margin: [0, 20, 0, 0],
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }
      
  //open in new tab
  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{vsDutyHolder && vsDutyHolder !=="" && `${vsDutyHolder} - `}View Asset Verification</h1>
      </div>

      <div className="my-4">
        {/* <Link to="/reporting/report-search" className="button-red mr-2"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link> */}
        
        {isLoaded === true && resultStatus === true && chartImageStatus === true ?
          <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF Report</span> :
          <span className="button-red cursor-not-allowed">
            <div className="flex items-center">
              <span>
                Loading PDF
              </span>
              <span className="ml-2 inline-block">
                <Loader
                  type="Oval"

                  color="#ffffff"
                  height={17}
                  width={17}
                  timeout={120000} //120 secs

                />
              </span>
            </div>
          </span>
        }

        {
          (vsReport.rptStatus && vsReport.rptStatus !== NOTIFICATION_STATUS_REVIEW &&
            vsReport.rptStatus !== NOTIFICATION_STATUS_PENDING &&
            vsReport.rptStatus !== NOTIFICATION_STATUS_APPROVED) ?
            <Link to={{
              pathname: `/reporting/vs-report/report-index/edit/${vsRptId}`,
              state: { formMode: 'edit' }
            }} className="button-red ml-2">Edit <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-white" /></Link>
            : <></>
        }


      </div>

      <div className="w-full lg:w-3/3">


        {/* DOCUMENT CONTROL */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">

          <h2 className="mt-12 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{""}</h2>
          <table className="w-full my-5 table-auto">
            <tbody>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border">Duty Holder</th>
                <td className="p-3 border">{vsDutyHolder}</td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border w-1/2">Report Title</th>
                <td className="p-3 border">{`${vsReport.rptTitle ?? ""}`}</td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border w-1/2">Report No.</th>

                <td className="p-3 border">{`${reportNumber} ${currentRevision}`}</td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border w-1/2">Date of Issue</th>
                <td className="p-3 border">{issueDate}</td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border w-1/2">Verification Scheme Ref(s)</th>
                <td className="p-3 border">{`${verSchemeRef}`}</td>

              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border" colSpan="2">Installations</th>
              </tr>
              <tr>
                <td className="p-3 border" colSpan="2">
                  {vsInstallations.length > 0 ? vsInstallations.map((inst, index) =>
                    <p key={index}>{inst.installation_title}</p>
                  ) :
                    <p>{"No Installation"}</p>
                  }
                </td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border" colSpan="2">Verifiers</th>
              </tr>
              <tr>
                <td className="p-3 border" colSpan="2">
                  {vsVerifiers.length > 0 ? vsVerifiers.map((verifier, index) =>
                    <p key={index}>{verifier}</p>
                  ) :
                    <p>{"No Verifier"}</p>
                  }
                </td>
              </tr>
            </tbody>
          </table>

          <h2 className="mt-12 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Document Control</h2>
          <table className="w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th colSpan="5">Revision Control</th>
              </tr>
              <tr>
                <th>Revision</th>
                <th>Date</th>
                <th>Description</th>
                <th>Revised By</th>
                <th>Approved By</th>
              </tr>
            </thead>
            <tbody>
              {vsReportHistories.length ? vsReportHistories.map((hist, index) =>
                <tr key={index}>
                  <td>{hist.revision}</td>
                  <td>{hist.revisedDate}</td>
                  <td>{<Sanitiser html={hist.description} />}</td>
                  <td>{hist.revisedBy}</td>
                  <td>{hist.approvedBy}</td>
                </tr>
              ) :
                <tr key={"1"}>
                  <td colSpan="5">{"No Data to Display"}</td>
                </tr>
              }
            </tbody>
          </table>

          {/*
          <table className="mt-6 w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th>Issue Number</th>
                <th>Document Holder</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {reportIssuanceList.map((issuance, index) =>
                <tr key={index}>
                  <td>{issuance.issue_number}</td>
                  <td>{issuance.document_holder}</td>
                  <td>{issuance.location}</td>
                </tr>
              )}
            </tbody>
          </table> */}

          {/* <p>This is to certify that the undersigned Surveyor to this Bureau, did at the request of the Duty Holder’s Representative, attend the Maersk Drilling office in Lyngby, to complete onshore Verification activities for Maersk Resolve, <span className="text-red-900">ABSID 09162307</span>, of <span className="text-red-900">Republic of Singapore</span>.</p> */}

          <div>{<Sanitiser html={vsReport.rptDescription}/>}</div>

        </div>
        {/* DOCUMENT CONTROL END */}

        {/* TABLE OF CONTENTS */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">

          <h2 className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Table of Contents</h2>

          <p><a href="#executive-summary" className="hover:underline">1. EXECUTIVE SUMMARY</a></p>

          <p><a href="#extent-of-verification" className="hover:underline">2. EXTENT OF VERIFICATION</a></p>

          <p className='ml-5'><a href="#additional-information" className="hover:underline">2.1 Additional Information</a></p>
          <p className='ml-5'><a href="#completed-activities" className="hover:underline">2.2 Completed Activities</a></p>
          <p className='ml-5'><a href="#commenced-activities" className="hover:underline">2.3 Commenced Activities</a></p>
          <p className='ml-5'><a href="#not-commenced-activities" className="hover:underline">2.4 Not Commenced Activities</a></p>


          <p><a href="#verification-activities" className="hover:underline">3. VERIFICATION ACTIVITIES (Detailed)</a></p>

          <p><a href="#findings" className="hover:underline">4. FINDINGS</a></p>

          <p className='ml-5'><a href="#finding-register" className="hover:underline">4.1 Finding Register</a></p>
          <p className='ml-5'><a href="#finding-details" className="hover:underline">4.2 Finding Details</a></p>
          <p className='ml-5'><a href="#findings-statistics" className="hover:underline">4.3 Findings Statistics</a></p>
          <p className='ml-5'><a href="#findings-by-overview" className="hover:underline">4.3.1 Findings by Overview</a></p>
          <p className='ml-5'><a href="#findings-by-nature" className="hover:underline">4.3.2 Findings by Nature</a></p>
          <p className='ml-5'><a href="#findings-by-issue" className="hover:underline">4.3.3 Findings by Issue</a></p>

          <p><a href="#verification-status" className="hover:underline">5. VERIFICATION STATUS</a></p>
          <p className='ml-5'><a href="#sece-status" className="hover:underline">5.1 SECE Status</a></p>
          <p className='ml-5'><a href="#verification-schedule" className="hover:underline">5.2 Verification Schedule</a></p>


          <p><a href="#opportunities-for-improvement" className="hover:underline">6. OPPORTUNITIES FOR IMPROVEMENT</a></p>
          <p><a href="#areas-of-concern" className="hover:underline">7. AREAS OF CONCERN</a></p>
          <p><a href="#positives-from-the-attendance" className="hover:underline">8. POSITIVES FROM THE ATTENDANCE</a></p>


        {/* EXECUTIVE SUMMARY */}
        <div className="my-6 pt-4 pb-8 border-t-2 border-b-2 border-gray-200">
          <h2 id="executive-summary" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">1. Executive Summary</h2>
          <Sanitiser html={vsReport.rptSummary} />
        </div>
        {/* EXECUTIVE SUMMARY END */}


          <h2 id="extent-of-verification" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">2. Extent of Verification</h2>
          <Sanitiser html={vsReport.rptScope} />

          {/* EXTENT OF VERIFICATION END */}

          {/* ADDITIONAL INFORMATION */}
          <div className="mb-6">
            <h3 id="additional-information" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">2.1 Additional Information</h3>
            <Sanitiser html={vsReport.rptStatement} />
          </div>
          {/* ADDITIONAL INFORMATION END */}

          {/* COMPLETED ACTIVITIES */}

          <h3 id="completed-activities" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">2.2 Completed Activities</h3>
          <p className="mb-3">The following Verification Activities were completed during the attendance(s) detailed within this report.</p>

          <p>KEY:</p>
          <p><span className="text-green-900">◼</span> Performance Standards were satisfied with regard to this Activity.</p>
          <p><span className="text-blue-300">◼</span> Performance Standards were satisfied with regard to this Activity, however Low Level Finding(s) were raised during completion of this Activity, or remain Open from previous Verification.</p>
          <p><span className="text-yellow-900">◼</span> Performance Standards were not met with regard to this Activity, resulting in Mid Level Finding(s) being raised during completion, or remain Open from previous Verification.</p>
          <p><span className="text-red-900">◼</span> Performance Standards were not met with regard to this Activity, resulting in High Level Finding(s) being raised during completion, or remain Open from previous Verification.</p>

          <table className="mt-6 w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th className="text-left">SECE Ref.</th>
                <th className="text-left">SECE Name</th>
                <th className="text-left">VA Ref.</th>
                <th className="text-left">VA Short Description</th>
                <th className="text-left">As Found Status</th>
              </tr>
            </thead>
            <tbody>
              {svaLoading ? <tr><td colSpan={5}>Loading...</td></tr> : <></>}
              {svaError ? <tr><td colSpan={5}>Something went wrong retrieving the table data</td></tr> : <></>}
              {completedVaList.length ? completedVaList.map((sva, index) =>
                <React.Fragment key={index}>
                  <tr>
                    <td>{sva.sece_ref}</td>
                    <td>{sva.sece_name}</td>
                    <td>{sva.va_ref}</td>
                    <td>{sva.va_short_desc}</td>
                    <td className={sva.as_found_status}>&nbsp;</td>
                  </tr>
                </React.Fragment>
              ): (!svaLoading &&
                  <tr><td colSpan="6">{"No Data to Display"}</td></tr>
                )
              }
            </tbody>
          </table>

          <h3 id="commenced-activities" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">2.3 Commenced Activities</h3>
          <p className="mb-3">The following Verification Activities were Commenced during the attendance(s) detailed within this report.</p>

          <p>KEY:</p>
          <p><span className="text-green-900">◼</span> Performance Standards were satisfied with regard to this Activity.</p>
          <p><span className="text-blue-300">◼</span> Performance Standards were satisfied with regard to this Activity, however Low Level Finding(s) were raised during completion of this Activity, or remain Open from previous Verification.</p>
          <p><span className="text-yellow-900">◼</span> Performance Standards were not met with regard to this Activity, resulting in Mid Level Finding(s) being raised during completion, or remain Open from previous Verification.</p>
          <p><span className="text-red-900">◼</span> Performance Standards were not met with regard to this Activity, resulting in High Level Finding(s) being raised during completion, or remain Open from previous Verification.</p>

          <table className="mt-6 w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th className="text-left">SECE Ref.</th>
                <th className="text-left">SECE Name</th>
                <th className="text-left">VA Ref.</th>
                <th className="text-left">VA Short Description</th>
                <th className="text-left">As Found Status</th>
              </tr>
            </thead>
            <tbody>
              {svaLoading ? <tr><td colSpan={5}>Loading...</td></tr> : <></>}
              {svaError ? <tr><td colSpan={5}>Something went wrong retrieving the table data</td></tr> : <></>}
              {commencedVaList.length ? commencedVaList.map((sva, index) =>
                <React.Fragment key={index}>
                  <tr>
                    <td>{sva.sece_ref}</td>
                    <td>{sva.sece_name}</td>
                    <td>{sva.va_ref}</td>
                    <td>{sva.va_short_desc}</td>
                    <td className={sva.as_found_status}>&nbsp;</td>
                  </tr>
                </React.Fragment>
              ) :
                (!svaLoading &&
                  <tr><td colSpan="6">{"No Data to Display"}</td></tr>
                )
              }
            </tbody>
          </table>

          <h3 id="not-commenced-activities" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">2.4 Not Commenced Activities</h3>
          <p className="mb-3">The following Verification Activities were Not Commenced during the attendance(s) detailed within this report.</p>

          <p>KEY:</p>
          <p><span className="text-blue-300">◼</span> Activity Not Commenced, however Low Level Finding(s) were raised, or remain Open from previous Verification.</p>
          <p><span className="text-yellow-900">◼</span> Activity Not Commenced, however Mid Level Finding(s) were raised, or remain Open from previous Verification.</p>
          <p><span className="text-red-900">◼</span> Activity Not Commenced, however High Level Finding(s) were raised, or remain Open from previous Verification.</p>
          <p>◻ No Verification has been conducted with regard to this Activity.</p>

          <table className="mt-6 w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th className="text-left">SECE Ref.</th>
                <th className="text-left">SECE Name</th>
                <th className="text-left">VA Ref.</th>
                <th className="text-left">VA Short Description</th>
                <th className="text-left">As Found Status</th>
              </tr>
            </thead>
            <tbody>
              {svaLoading ? <tr><td colSpan={5}>Loading...</td></tr> : <></>}
              {svaError ? <tr><td colSpan={5}>Something went wrong retrieving the table data</td></tr> : <></>}
              {notCommencedVaList.length ? notCommencedVaList.map((sva, index) =>
                <React.Fragment key={index}>
                  <tr>
                    <td>{sva.sece_ref}</td>
                    <td>{sva.sece_name}</td>
                    <td>{sva.va_ref}</td>
                    <td>{sva.va_short_desc}</td>
                    <td className={sva.as_found_status}>&nbsp;</td>
                  </tr>
                </React.Fragment>
              ) :
                (!svaLoading &&
                  <tr><td colSpan="6">{"No Data to Display"}</td></tr>
                )
              }
            </tbody>
          </table>


        </div>

        {/* EXTENT OF VERIFICATION */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">

          <h2 id="verification-activities" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold">3. VERIFICATION ACTIVITIES (Detailed)</h2>

          {/* <p className="mb-3">Details taken directly from "Verification Activity Report".</p> */}

          {vaReportList.length ? vaReportList.map((rpt, index) =>
            <table className="mt-6 mb-12 w-full my-3 table-auto verification-table" key={index}>
              <tbody>
                <tr>
                  <th width="20%">SECE Ref.</th>
                  <td width="30%">{rpt.seceRef}</td>
                  <th width="20%">Barrier</th>
                  <td width="30%">{rpt.barrierTitle}</td>
                </tr>
                <tr>
                  <th>SECE Name</th>
                  <td>{rpt.seceTitle}</td>
                  <th>Verification Activity Ref</th>
                  <td>{rpt.vaClientRef}</td>
                </tr>
                <tr>
                  <th>Verification Activity Description</th>
                  <td colSpan="3">{rpt.vaLongDesc}</td>
                </tr>
                <tr>
                  <th>Nature</th>
                  <td>{rpt.nature}</td>
                  <th>Frequency</th>
                  <td>{rpt.frequency}</td>
                </tr>
                <tr>
                  <th>Required Sample</th>
                  <td>{rpt.requiredSample}</td>
                  <th>Completed Sample</th>
                  <td>{rpt.completedSample}</td>
                </tr>
                <tr>
                  <th>Previous Status</th>
                  <td className= {`${rpt.previousStatusColorCode} ${rpt.previousStatusColorCode !== "bg-white-900" && "text-white"}`}>{rpt.previousStatus}</td>
                  <th>Current Status</th>
                  <td className= {`${rpt.currentStatusColorCode} text-white`}>{rpt.currentStatus}</td>
                </tr>
                <tr>
                  <th colSpan="1">Performance Standard Criteria</th>
                  <td colSpan="3">{rpt.performanceStandardCriteria}</td>
                </tr>
                <tr>
                  <th colSpan="1">Details of Verification Conducted</th>
                  <td colSpan="3">
                    {rpt.detailVaCompleted}
                  </td>
                </tr>
                <tr>
                  <th colSpan="1">Conclusive Statement</th>
                  <td colSpan="3">{rpt.conclusiveStatement}</td>
                </tr>
                <tr>
                  <th>Verifier</th>
                  <td>{rpt.verifier}</td>
                  <th>Date</th>
                  <td>{rpt.date}</td>
                </tr>
                <tr>
                  <th colSpan="4">Findings Raised</th>
                </tr>
                {rpt.findingsRaised.length ? rpt.findingsRaised.map((fnd, index) =>
                  <tr key={index}>
                    <th>Finding Ref.</th>
                    <td>{fnd.finding_ref}</td>
                    <th>Synopsis</th>
                    <td>{fnd.synopsis}</td>

                  </tr>
                ) :
                  <tr>
                    <td colSpan="4">{"No Data to Display"}</td>
                  </tr>
                }
                <tr>
                  <th colSpan="4">Existing Open Findings</th>
                </tr>

                {rpt.openFindings.length ? rpt.openFindings.map((fnd, index) =>
                  <tr key={index}>
                    <th>Finding Ref.</th>
                    <td>{fnd.finding_ref}</td>
                    <th>Synopsis</th>
                    <td>{fnd.synopsis}</td>
                  </tr>
                ) :
                  <tr>
                    <td colSpan="4">{"No Data to Display"}</td>
                  </tr>
                }
              </tbody>
            </table>
          ) : "No Data to Display"}
        </div>
        {/* EXTENT OF VERIFICATION END */}

        {/* FINDINGS */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">
          <h2 id="findings" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">4. Findings</h2>

          <h3 id="finding-register" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.1 Finding Register</h3>
          <p className="mb-3">The following Findings were raised (and closed where applicable) during the attendance(s) detailed within this report.</p>

          <table className="mt-6 w-full my-3 table-auto report-table">
            <thead>

              <tr>
                <th className="text-left">Finding Ref.</th>
                <th className="text-left">SECE Ref.</th>
                <th className="text-left">Synopsis</th>
                <th className="text-left">Finding Level</th>
                <th className="text-left">Status</th>
              </tr>

            </thead>
            <tbody>
              {fndRegLoading ? <tr><td colSpan={4}>Loading...</td></tr> : <></>}
              {fndRegError ? <tr><td colSpan={4}>Something went wrong retrieving the table data</td></tr> : <></>}
              {raisedFindings.length ? raisedFindings.map((fnd, index) =>
                <tr key={index}>
                  <td className="text-center">{fnd.finding_ref}</td>
                  <td className="text-center">{fnd.sece_ref}</td>
                  <td>{fnd.synopsis}</td>
                  <td className= {`${classColorCodeMapper(fnd.finding_level_summary)} text-center`}>{fnd.finding_infomatrix_level}</td>
                  <td className="text-center">{fnd.status}</td>
                </tr>
              ) :
                (!fndRegLoading &&
                  <tr><td colSpan="6">{"No Data to Display"}</td></tr>
                )
              }
            </tbody> 
          </table>

          <h3 id="finding-details" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.2 Finding Details</h3>
          {vaFindingList.length ? vaFindingList.map((finding, index) =>
            <React.Fragment key={index}>
              <table className="mt-6 w-full table-auto verification-table">
                <tbody>
                  <tr>
                    <th width="20%">Finding Reference</th>
                    <td width="30%">{finding.findingRef}</td>
                    <th width="20%">Finding Level</th>
                    <td className={`${finding.findingLevelColorCode} text-white`} width="30%">{finding.findingInfomatrixLevel}</td>
                  </tr>
                  <tr>
                    <th>SECE Ref.</th>
                    <td>{finding.seceRef}</td>
                    <th>Finding Current Status</th>
                    <td>{finding.currentStatus}</td>
                  </tr>
                  <tr>
                    <th>SECE Name</th>
                    <td>{finding.seceName}</td>
                    <th>Verification Activity Ref.</th>
                    <td>{finding.vaRef}</td>
                  </tr>
                  <tr>
                    <th colSpan="1">Verification Activity Description</th>
                    <td colSpan="3">{finding.vaDescription}</td>
                  </tr>
                  <tr>
                    <th>Integrity</th>
                    <td>{finding.integrity}</td>
                    <th>Legislative</th>
                    <td>{finding.legislative}</td>
                  </tr>
                  <tr>
                    <th>Paperwork / Process</th>
                    <td>{finding.paperwork}</td>
                    <th>ROGI</th>
                    <td>{finding.rogi}</td>
                  </tr>
                  <tr>
                    <th colSpan="1">Finding Synopsis</th>
                    <td colSpan="3">{finding.synopsis}</td>
                  </tr>
                  <tr>
                    <th colSpan="1">Reference</th>
                    <td colSpan="3">{finding.reference}</td>
                  </tr>
                  <tr>
                    <th colSpan="1">Reference Attachments</th>
                    <td colSpan="3">
                      {finding.referenceImages && finding.referenceImages.length ? finding.referenceImages.map((image, index) =>
                        <img key={index} src={image.link} alt="Reference" width="100px" className="inline mb-2 mr-2" />
                      ) : ""}

                      {finding.referenceImageLinks.length ? finding.referenceImageLinks.map((link, index) =>
                        <p key={index}><a href={link.path} className="hover:underline" rel="noopener noreferrer" target={"_blank"} download={true}>{link.name}</a></p>
                      ) : "No Data to Display"}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1">Finding</th>
                    <td colSpan="3">{finding.findingDetail}</td>
                  </tr>
                  <tr>
                    <th colSpan="1">Action</th>
                    <td colSpan="3">{finding.action}</td>
                  </tr>
                  <tr>
                    <th>Response Date</th>
                    <td>{finding.responseDate}</td>
                    <th>Completion Date</th>
                    <td>{finding.completionDate}</td>
                  </tr>
                  <tr>
                    <th>Action Party</th>
                    <td>{finding.actionParty}</td>
                    <th>Duty Holder Responsible</th>
                    <td>{finding.dutyHolderResponsible}</td>
                  </tr>

                  <tr>
                    <th>Verifier</th>
                    <td>{finding.verifier}</td>
                    <th>Date Raised</th>
                    <td>{finding.dateRaised}</td>
                  </tr>
                </tbody>
              </table>
              <table className="mb-12 w-full table-auto verification-table">
                <tbody>
                  <tr>

                    <th colSpan="6">Finding Responses</th>
                  </tr>
                  {finding.reponses && finding.reponses.length ? finding.reponses.map((reponse, index) =>
                    <React.Fragment key={index}>

                      <tr>
                        <th width="20%">Response by ABS / Duty Holder</th>
                        <td width="20%">{reponse.responder}</td>
                        <th width="10%">Date</th>
                        <td width="20%">{reponse.responseDate}</td>
                        <th width="10%">Current Status</th>
                        <td width="20%">{reponse.responseCurrentStatus}</td>
                      </tr>

                      <tr>
                        <th colSpan="1">Response</th>
                        <td colSpan="5">{<Sanitiser html={reponse.detail} />}</td>
                      </tr>
                    </React.Fragment>
                  ) :
                    <tr>
                      <td colSpan="6">No Data to Display</td>
                    </tr>
                  }
                </tbody>
              </table>
            </ React.Fragment>
          ) : "No Data to Display"}


          <h3 id="findings-statistics" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.3 Finding Statistics</h3>
          <p className="mb-3">The following section provides statistical information relating to all Findings Open upon the issue date of this report.  For full details, refer to ABS Verification Manager&trade;.</p>

          <h3 id="findings-by-overview" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.3.1 Findings by Overview</h3>
          <p className="mb-3">The following charts provide a summary of the status and level of Findings Open upon the issue date of this report.</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">All Open Findings</h3>

                  {openFindingsLoading && (
                    <PageSpinner />
                  )}
                  {openFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}
                  {!openFindingsLoading && !openFindingsError && openFindingsStats.date.datasets &&
                    (<ErrorBoundary>

                      <Pie
                        id="openFindings"
                        data={openFindingsStats.date}
                        options={{

                          title: { display: false, text: 'All Open Findings (Status)', fontColor: '#002a4e' },
                          legend: { align: 'start' },
                          animation: {
                            onComplete: doneOpenFindings
                          },
                        }}
                        ref={openFindingRef}
                      />
                    </ErrorBoundary>
                    )}

                </div>
              </div>
            </div>
            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">All Open Findings</h3>

                  {openFindingsLoading && (
                    <PageSpinner />
                  )}
                  {openFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}
                  {!openFindingsLoading && !openFindingsError && openFindingsStats.level.datasets &&
                    <ErrorBoundary>
                      <Pie
                        id="openFindings"
                        data={openFindingsStats.level}
                        options={{
                          title: { display: false, text: 'All Open Findings (Level)', fontColor: '#002a4e' },
                          legend: { align: 'start' },
                          animation: {
                            onComplete: doneOpenFindingsLevel
                          },
                        }}
                        ref={openFindingLevelRef}
                      />
                    </ErrorBoundary>
                  }

                </div>
              </div>
            </div>
          </div>

          <h3 id="findings-by-nature" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.3.2 Findings by Nature</h3>
          <p className="mb-3">The following charts detail the Nature by which Findings Open upon
            the issue date of this report were raised.</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">Findings by Nature</h3>

                  {natureFindingsLoading && (
                    <PageSpinner />
                  )}
                  {natureFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}
                  {!natureFindingsLoading && !natureFindingsError && natureFindingsStats.date.datasets &&
                    (<ErrorBoundary>
                      <Bar
                        data={natureFindingsStats.date}
                        options={{
                          scales: {
                            xAxes: [{
                              stacked: true
                            }],
                            yAxes: [{
                              stacked: true
                            }]
                          },
                          legend: { align: 'start' },
                          animation: {
                            onComplete: doneFindingsByNatureStatus
                          },
                        }
                        }
                        ref={FindingsByNatureStatus}
                      />
                    </ErrorBoundary>
                    )}

                </div>
              </div>
            </div>
            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">Findings by Nature</h3>

                  {natureFindingsLoading && (
                    <PageSpinner />
                  )}
                  {natureFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}
                  {!natureFindingsLoading && !natureFindingsError && natureFindingsStats.level.datasets &&
                    (
                    <ErrorBoundary>
                      <Bar
                        data={natureFindingsStats.level}
                        options={{
                          scales: {
                            xAxes: [{
                              stacked: true
                            }],
                            yAxes: [{
                              stacked: true
                            }]
                          },
                          legend: { align: 'start' },
                          animation: {
                            onComplete: doneFindingsByNatureLevel
                          },
                        }}
                        ref={FindingsByNatureLevel}
                      />
                    </ErrorBoundary>
                    )
                  }

                </div>
              </div>
            </div>
          </div>

          <h3 id="findings-by-issue" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.3.3 Findings by Issue</h3>
          <p className="mb-3">The following charts detail the issues associated with Findings Open
            upon the issue date of this report.</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">Findings by Issue</h3>

                  {issuesFindingsLoading && (
                    <PageSpinner />
                  )}
                  {issuesFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}
                  {!issuesFindingsLoading && !issuesFindingsError && issuesFindingsStats.length && (
                    <>
                      <div className="flex">
                        <div className="mt-2 w-full flex flex-row justify-between">
                          {issuesFindingsStats.map((issue, index) =>
                            <div className="p-2 text-center" style={{ backgroundColor: `${issue.backgroundColor}` }} key={index}>
                              <span className="block text-sm text-white">{issue.count}</span>
                              <span style={{ fontSize: '0.6rem' }} className="text-white">{issue.label}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <ErrorBoundary>
                        <div className="w-full xl:w-4/5 mx-auto" ref={divRef}>
                          <FindingIssues data={issuesFindingsStats} />
                        </div>
                      </ErrorBoundary>
                    </>
                  )}

                </div>
              </div>
            </div>
            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">Findings by Issue</h3>

                  {issuesFindingsLoading && (
                    <PageSpinner />
                  )}
                  {issuesFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}
                  {!issuesFindingsLoading && !issuesFindingsError && issuesFindingsStatsBarChart.datasets &&
                    (
                      <ErrorBoundary>
                        <Bar
                          data={issuesFindingsStatsBarChart}
                          options={{
                            scales: {
                              xAxes: [{
                                stacked: true,
                                ticks: {
                                  fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                },
                              }],
                              yAxes: [{
                                stacked: true,
                                ticks: {
                                  fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                },
                              }]
                            },
                            legend: {
                              display: false,
                              align: 'start',
                              labels: {
                                fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                              }
                            },
                            animation: {
                              onComplete: doneFindingsIssuesBar
                            },
                          }}
                          ref={FindingsIssuesBarChart}
                        />
                      </ErrorBoundary>
                    )}

                </div>
              </div>
            </div>
          </div>

        </div>
        {/* FINDINGS END */}

        {/* VERIFICATION STATUS */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">

          <h2 id="verification-status" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">5. Verification Status</h2>
          <p>The following section details the status of SECEs and associated Verification Activities detailed within this report. For full details, refer to ABS Verification Manager&trade;.</p>

          <h3 id="sece-status" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">5.1 SECE Status</h3>
          <p>The following chart details the status of SECEs in terms of Completion of Verification and Findings assigned to them post attendance(s) detailed within this report.</p>


          {seceBreakdownError ? <span>Something went wrong retrieving the table data</span> : ""}
          {seceBreakdownLoading ? <PageSpinner /> : ""}
          {seceBreakdowns.length ? <Table columns={columns} data={seceBreakdowns} /> : (!seceBreakdownLoading && "No Data To Display")}

          <h3 id="verification-schedule" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">5.2 Verification Schedule</h3>
          <p>The following chart details the overall Verification Schedule post attendance(s) detailed within this report.</p>

          {vsStatsError ? <span>Something went wrong retrieving the table data</span> : ""}
          {vsStatsLoading ? <PageSpinner /> : ""}
          <ErrorBoundary>
            <Line
              data={vsStatsData}
              options={{
                title: { display: true, text: 'VERIFICATION SCHEDULE', fontColor: '#002a4e', fontSize: 20 },
                legend: { align: 'middle' },
                animation: {
                  onComplete: doneVerificationSchedule
                },
              }}
              ref={VerificationScheduleRef}
            />
          </ErrorBoundary>  

        </div>
        {/* VERIFICATION STATUS END */}
      </div>


      {/* OPPORTUNITIES FOR IMPROVEMENT */}
      <div className="mb-6 pb-8 border-b-2 border-gray-200">
        <h2 id="opportunities-for-improvement" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">6. Opportunities for Improvement</h2>
        <Sanitiser html={vsReport.rptImprovements} />
      </div>
      {/* OPPORTUNITIES FOR IMPROVEMENT END */}

      {/* AREAS OF CONCERN */}
      <div className="mb-6 pb-8 border-b-2 border-gray-200">
        <h2 id="areas-of-concern" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">7. Areas of Concern</h2>
        <Sanitiser html={vsReport.rptConcerns} />
      </div>
      {/* AREAS OF CONCERN END */}

      {/* POSITIVES FROM THE ATTENDANCE */}
      <div className="mb-6 pb-8 border-b-2 border-gray-200">
        <h2 id="positives-from-the-attendance" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">8. Positives from the attendance</h2>
        <Sanitiser html={vsReport.rptPositives} />
      </div>
      {/* POSITIVES FROM THE ATTENDANCE END */}

      <div className="my-4">
        {/* <Link to="/reporting/report-search" className="button-red mr-2"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link> */}

        {isLoaded === true && resultStatus === true && chartImageStatus === true ?
          <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF Report</span> :
          <span className="button-red cursor-not-allowed">
            <div className="flex items-center">
              <span>
                Loading PDF
              </span>
              <span className="ml-2 inline-block">
                <Loader
                  type="Oval"
                  color="#ffffff"
                  height={17}
                  width={17}
                  timeout={120000} //120 secs
                />
              </span>

            </div>
          </span>
        }

        {
          (vsReport.rptStatus && vsReport.rptStatus !== NOTIFICATION_STATUS_REVIEW &&
            vsReport.rptStatus !== NOTIFICATION_STATUS_PENDING &&
            vsReport.rptStatus !== NOTIFICATION_STATUS_APPROVED) ?
            <Link to={{
              pathname: `/reporting/vs-report/report-index/edit/${vsRptId}`,
              state: { formMode: 'edit' }
            }} className="button-red ml-2">Edit <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-white" /></Link>
            : <></>
        }
      </div>

    </div>
  )
}

/*
const formatVaStatusFrom = (vaList) => {
  let newVaStatusList = [];
  if (vaList) {
    for (let index = 0; index < vaList.length; index++) {
      const vaStatus = vaList[index];

      const { sece_id: sece, bar_id: barrier, va_id: va, scheduled_date } = vaStatus;

      let scheduledDate = parseInt(scheduled_date);
      let scheduledDateString = new Date(scheduledDate);

      let formatedDate = formatDateToLocaleDateString(scheduledDateString);

      let newVaStatus = {
        barrierTitle: barrier && barrier.bar_title ? barrier.bar_title : "N/A",
        seceNo: sece && sece.sece_order_no ? sece.sece_order_no : "N/A",
        seceTitle: sece && sece.sece_title ? sece.sece_title : "N/A",
        vaRef: va && va.va_client_ref ? va.va_client_ref : "N/A",
        date: formatedDate && formatedDate !== "" ? formatedDate : "N/A"
      }
      newVaStatusList.push(newVaStatus);
    }
  }
  return newVaStatusList;

} 
*/

export const formatSysVarDataAndUpdateStates = (list, setStatementList, setStatusList, statementTypeId, statusTypeId) => {
  let statementList = [];
  let statusList = [];

  statementList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === statementTypeId);
  statusList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === statusTypeId && sysVar.sysvar_title !== "Open");

  setStatementList(statementList);
  setStatusList(statusList);
}

export const formatVaReportData =(vaReportList) => {
  let formatedVaReports = [];
  if (vaReportList && vaReportList.length) {
    vaReportList.forEach(vaRpt => {
      const {
        sece_ref,
        sece_no,
        barrier_title,
        sece_title,
        va_ref,
        va_client_ref,
        va_short_desc,
        va_long_desc,
        nature,
        frequency,
        required_sample,
        completed_sample,
        ps_criteria,
        previous_status,
        previous_status_id,
        current_status,
        current_status_id,
        detail_va_completed,
        conclusive_statement,
        verifier,
        date,
        findings_raised,
        open_findings
      } = vaRpt ? vaRpt : {};

      let performanceStandardCriteriaAlt = ps_criteria ? htmlToPdfmake(ps_criteria) : [{text: "N/A"}];
      const adjustedPerformanceStandardCriteriaAlt = adjustTextNode(performanceStandardCriteriaAlt);

      let detailVaCompletedAlt = detail_va_completed ? htmlToPdfmake(detail_va_completed) : [{text: "N/A"}];
      const adjustedDetailVaCompletedAlt = adjustTextNode(detailVaCompletedAlt);

      let vaLongDescAlt = va_long_desc ? htmlToPdfmake(va_long_desc) : [{text: "N/A"}];
      const adjustedVaLongDescAlt = adjustTextNode(vaLongDescAlt);

      let vaReport = {
        seceRef: sece_ref,
        seceNo: sece_no,
        barrierTitle: barrier_title,
        seceTitle: sece_title,
        vaRef: va_ref,
        vaClientRef: va_client_ref,
        vaShortDesc: va_short_desc,
        vaLongDesc: va_long_desc ? <Sanitiser html={va_long_desc} /> : "N/A",
        vaLongDescAlt: adjustedVaLongDescAlt,
        nature: nature,
        frequency: frequency,
        requiredSample: required_sample,
        completedSample: completed_sample,
        performanceStandardCriteria: ps_criteria ? <Sanitiser html={ps_criteria} /> : "N/A",
        performanceStandardCriteriaAlt: adjustedPerformanceStandardCriteriaAlt,
        previousStatus: previous_status,
        previousStatusColorCode: statusClassColorCodeMapper(previous_status_id),
        previousStatusColorCodeAlt: statusClassColorCodeMapperAlt(previous_status_id),
        currentStatus: current_status,
        currentStatusColorCode: statusClassColorCodeMapper(current_status_id),
        currentStatusColorCodeAlt: statusClassColorCodeMapperAlt(current_status_id),
        detailVaCompleted: detail_va_completed && detail_va_completed !== "" ? <Sanitiser html={detail_va_completed} /> : "N/A",
        detailVaCompletedAlt: adjustedDetailVaCompletedAlt,
        conclusiveStatement: conclusive_statement,
        verifier: verifier,
        date: date ? formatDateToLocaleDateString(date) : "N/A",
        findingsRaised: findings_raised,
        openFindings: open_findings
      }

      formatedVaReports.push(vaReport);
    });
  }
  return formatedVaReports;
}

export const formatFindingData = (findingList) => {
  let formatedFindings = [];
  if (findingList && findingList.length) {
    findingList.forEach(fnd => {
      const { user_fname: actionFName = "", user_lname: actionLName = "" } = fnd.finding_action_party ? fnd.finding_action_party : {};
      const actionPartyFullName = (actionFName !== "" && actionLName !== "") ? `${actionFName} ${actionLName}` : "N/A";

      const { user_fname: dhRepFName = "", user_lname: dhRepLName = "" } = fnd.finding_dh_responsible ? fnd.finding_dh_responsible : {};
      const dhResponsibleFullName = (dhRepFName !== "" && dhRepLName !== "") ? `${dhRepFName} ${dhRepLName}` : "N/A";

      const { user_fname: authorFName = "", user_lname: authorLName = "" } = fnd.finding_added_id ? fnd.finding_added_id : {};
      const authorFullName = (authorFName !== "" && authorLName !== "") ? `${authorFName} ${authorLName}` : "N/A";

      const findingLevel = fnd.finding_level_summary ? upperCase(fnd.finding_level_summary) : "";

      const infomatrixLevel = fnd.finding_infomatrix_level ?? "";
      const infomatrixLevelArray = infomatrixLevel.split(":");
      const findingInfomatrixLevel = infomatrixLevelArray[1] ?? "N/A";

      let tempFindingResponses = fnd.finding_responses ? fnd.finding_responses.replace(/\s/g, " ") : "[]";
      const findingResponses = parseJSONSafely(tempFindingResponses) ?? [];

      const findingReferenceAttachmentPath = fnd.fnd_ps_ref_attmnt_dest_path ? fnd.fnd_ps_ref_attmnt_dest_path : "";
      const findingReferenceAttachments =  parseJSONSafely(fnd.finding_ps_reference_attachments) ?? [];

      const { sysvar_title: findingIntegrity = "N/A" } = fnd.finding_integrity ? fnd.finding_integrity : {};
      const { sysvar_title: findingLegislative = "N/A" } = fnd.finding_legislative ? fnd.finding_legislative : {};
      const { sysvar_title: findingRogi = "N/A" } = fnd.finding_rogi ? fnd.finding_rogi : {};
      const { sysvar_title: findingPwProcess = "N/A" } = fnd.finding_pw_process ? fnd.finding_pw_process : {};

      const { sece_ref: seceRef = "N/A", sece_title: seceTitle = "N/A" } = fnd.sece_ids && fnd.sece_ids[0] ? fnd.sece_ids[0] : {};
      const { sysvar_title: currentStatus = "N/A" } = fnd.finding_current_status ? fnd.finding_current_status : {};
      const { 
        va_client_ref: vaClientRef = "N/A", 
        va_long_desc: vaLongDesc = "N/A", 
      } = fnd.va_id ? fnd.va_id : {};

      let findingItems = [];
      let findingItem = {
        tagNo: "N/A",
        description: "N/A",
        pressure: "N/A",
        result: "N/A",
        comment: "N/A",
      }
      findingItems.push(findingItem);

      let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${findingReferenceAttachmentPath}/`;

      let referenceImages = [];
      findingReferenceAttachments.forEach(attach => {
        const { name } = attach ? attach : {};

        let referenceImage = {
          link: createImageLink(fullPath, name),
          linkAlt: 'iVBORw0KGgoAAAANSUhEUgAAAV4AAAFeBAMAAAA/BWopAAAAG1BMVEXMzMyWlpacnJyqqqrFxcWxsbGjo6O3t7e+vr6He3KoAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEnElEQVR4nO3dwW/iRhTH8WdjA0fGJGSPdpI2OZLtrrRH00S7OQJq0x5BikSOkLbpHmGzf3jnjW0CXoJaVdit9P1I8QD+SfM0HsbjXBABAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADVuE6id7YJjYrtq+Bt9GFvVEYajfZGD8bVORRprOtd2Xa+LypXRb2vRg9noX0fizSLetvanuyLyjSv9/Xo4STR10+JScXv/WktRXzzcDuN0j1RSWY2+nlf9GBaZqL9DsUrxmkR6WAP90SlmAOvRg+nYZZ6XScyeJN/MrUvAjeRX4sG+mJv9HB8I1mfq6P8E9PRSo72RNs6J/ZGD8dzy5IdtEUn+6Bt+vY4ymbHQK98qPNgM9oyO6IVaX3OOx7F2Qehm5yrbv4m1qKX29Ew2hGtUtN2fDW5i2aSz1IZ9NyJILHL11W3FG30wun381K0QrdT2+X01K6l51qQFuHlQ7gwaWCOStFmlNjlNy1FK2PvVvd2TXX3AC0i3SjCN/3NBSuL+u7Wcl6KVlmv3RUY89PtlZ2cWRH++it1tIrKUc8c3zybbilaZb12XPWqt+wh635dxLQ7PSlHPa3TzpRStDJ2XGP5eanVHZcHbWC27gcuGv4q7r5W0/jqVc/HcNUrT0q7bVvujNobXU3z1xrlS5btu/SlD0xvd1RMXNP6IG5cHXttS4tqy5T2X0VUkk4d6+/4Qo8vy1f5puWb9XK2HbX11nF/W+j+245R6xfXRm4DtrEpGEXr20URlT+0TLvxKUUrkY3Oqpt9d/Td1qYrMG+m3VI0O6kbiTr2Z67O6Ul2bXWt3drUNky/2O6so9mI6kaihv1vQ6dnaI7aetlDPWw9NAxM2si3k+uofcpM9Rk5reP5om26Xz9NbZ9J9Hv7Svt2D2XFjtxOhuBlxc2jvrlIn/XBcytaEfds3ssezd1au/nQ60Z9FJWiLqGjXsfzsXuO7+tCm7XZY3v+QOnrJ4PiihdRl+iWopV5SrL/0Vzn7eY/bRZ6scP1ilZEg7fmbFmKAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAWnn53//Ff7Nek/325g6lejv2nWeMkUjE70j2O3jXyUzko7kXLxZJDltpXkbwwytndtTrjr2lPOX1Bt+lz/ZwM069B2lND1+tFvCjvD0TP23ZF+FpX7xxR5vG6UjrHZ9Jc27PbNR7MpFZXm9jmB3CvvckzUVF9Yb3H4eN+UB+k7ub9+Ldu+buRofLs6fasbzfrLdz2Y7zev00OwSx5y+/VDLZ7Xzw0yBu98fzD3JuR9JLXXMuA63XnpKZ/dNfkrNz19X71Jzn9XrFoeO1JrNK6rXfNzds8eUk1pq0e206xfztyJeG/pjLy/j6j/Lt+MpFXM34aoc6iJOHiR1UV5Q2G+Prj2Wr3vBYvp2/MhpWVa/OXxnPH4fylF5rUdq8zF9pnWzX696448b6IBUt1tqvXR9klQ5SaSWX2qs2L+uDtOId9WZz5toU629V9f4Nzap/i+hfeqy7gH/Gu6y7AgCox1+3obpuSn31LQAAAABJRU5ErkJggg=='
        }
        referenceImages.push(referenceImage);
      })

      let referenceImageLinks = [];
      findingReferenceAttachments.forEach(attach => {
        const { name, path } = attach ? attach : {};
        let referenceImageLink = {
          name: name,
          path: (fullPath + name),
          pathAlt: path
        }
        referenceImageLinks.push(referenceImageLink);
      })

      let reponses = [];

      

      findingResponses.forEach(fndRes => {
        const { response_note = "N/A", response_status = "N/A", response_update_by = "N/A", response_update_date = "" } = fndRes ? fndRes : {};
        let detailAlt = response_note === "N/A" ? [{ text: "N/A" }] : htmlToPdfmake(response_note);
        const adjustedDetailAlt = adjustTextNode(detailAlt);

        let reponse = {
          responder: response_update_by,
          responseDate: response_update_date && response_update_date !== "" ? formatDateToLocaleDateString(response_update_date) : "N/A",
          responseCurrentStatus: response_status,
          detail: response_note,
          detailAlt: adjustedDetailAlt,
        }
        reponses.push(reponse);
      })


        let vaDescriptionAlt = vaLongDesc === "N/A" ? [{ text: "N/A" }] : htmlToPdfmake(vaLongDesc);
        const adjustedVaDescriptionAlt = adjustTextNode(vaDescriptionAlt);

        let synopsisAlt = fnd.finding_synopsis ? htmlToPdfmake(fnd.finding_synopsis) : [{text: "N/A"}];
        const adjustedSynopsisAlt = adjustTextNode(synopsisAlt);

        let referenceAlt = fnd.finding_ps_reference ? htmlToPdfmake(fnd.finding_ps_reference) : [{text: "N/A"}];
        const adjustedReferenceAlt = adjustTextNode(referenceAlt);

        let findingDetailAlt = fnd.finding_summary ? htmlToPdfmake(fnd.finding_summary) : [{text: "N/A"}];
        const adjustedFindingDetailAlt = adjustTextNode(findingDetailAlt);

        let actionAlt = fnd.finding_action ? htmlToPdfmake(fnd.finding_action) : [{text: "N/A"}];
        const adjustedActionAlt = adjustTextNode(actionAlt);

      const findingData = {
        findingRef: fnd.finding_ref ? fnd.finding_ref : "N/A",
        findingLevel: findingLevel,
        findingInfomatrixLevel: findingInfomatrixLevel.trim(),
        findingLevelColorCode: classColorCodeMapper(findingLevel),
        findingLevelColorCodeAlt: classColorCodeMapperAlt(findingLevel),
        seceRef: seceRef,
        currentStatus: currentStatus,

        seceName: seceTitle,
        vaRef: vaClientRef,
        vaDescription: vaLongDesc === "N/A" ? vaLongDesc : <Sanitiser html={vaLongDesc} />,
        vaDescriptionAlt: adjustedVaDescriptionAlt,

        integrity: findingIntegrity,
        legislative: findingLegislative,
        paperwork: findingPwProcess,
        rogi: findingRogi,

        synopsis: fnd.finding_synopsis ? <Sanitiser html={fnd.finding_synopsis} /> : "N/A",
        synopsisAlt: adjustedSynopsisAlt,

        reference: fnd.finding_ps_reference ? <Sanitiser html={fnd.finding_ps_reference} /> : "N/A",
        referenceAlt: adjustedReferenceAlt,

        referenceImages: referenceImages,
        referenceImageLinks: referenceImageLinks,

        findingDetail: fnd.finding_summary ? <Sanitiser html={fnd.finding_summary} /> : "N/A",
        findingDetailAlt: adjustedFindingDetailAlt,

        action: fnd.finding_action ? <Sanitiser html={fnd.finding_action} /> : "N/A",
        actionAlt: adjustedActionAlt,

        responseDate: fnd.finding_response_date ? formatDateToLocaleDateString(fnd.finding_response_date) : "N/A",
        completionDate: fnd.finding_completion_date ? formatDateToLocaleDateString(fnd.finding_completion_date) : "N/A",

        actionParty: actionPartyFullName,
        dutyHolderResponsible: dhResponsibleFullName,

        verifier: authorFullName,
        dateRaised: fnd.finding_added_date ? formatDateToLocaleDateString(fnd.finding_added_date) : "N/A",

        reponses: reponses
      }

      formatedFindings.push(findingData);
    });
  }
  return formatedFindings;
} 

export const classColorCodeMapper = (findingLevel) => {
  let colorCode = "bg-red-900";
  switch (findingLevel) {
    case "HIGH":
    case "High":
      colorCode = "bg-red-900";
      break;
    case "MEDIUM":
    case "Medium":
      colorCode = "bg-yellow-900";
      break;
    case "LOW":
    case "Low":
      colorCode = "bg-green-900";
      break;
    default:
      colorCode = "bg-red-900";
      break;
  }
  return colorCode;
}

function classColorCodeMapperAlt(findingLevel) {
  let colorCode = "#da1f33";
  switch (findingLevel) {
    case "HIGH":
    case "High":
      colorCode = "#da1f33";
      break;
    case "MEDIUM":
    case "Medium":
      colorCode = "#ffa700";
      break;
    case "LOW":
    case "Low":
      colorCode = "#008744";
      break;
    default:
      colorCode = "#da1f33";
      break;
  }
  return colorCode;
}

function statusClassColorCodeMapper(currentStatusId) {
  let colorCode = "bg-red-900";
  switch (currentStatusId) {
    case ACTIVITY_STATUS_SYS_VAR_COMPLETED_ID:
      colorCode = "bg-green-900";
      break;
    case ACTIVITY_STATUS_SYS_VAR_COMMENCED_ID:
      colorCode = "bg-blue-100";
      break;
    case ACTIVITY_STATUS_SYS_VAR_NOT_COMMENCED_ID:
      colorCode = "bg-yellow-900";
      break;
    case ACTIVITY_STATUS_SYS_VAR_SCHEDULED:
      colorCode = "bg-gray-500";
      break;
    default:
      colorCode = "bg-white-900";
      break;
  }
  return colorCode;
}

function statusClassColorCodeMapperAlt(currentStatusId) {
  let colorCode = "#da1f33";
  switch (currentStatusId) {
    case ACTIVITY_STATUS_SYS_VAR_COMPLETED_ID:
      colorCode = "#4ca342";
      break;
    case ACTIVITY_STATUS_SYS_VAR_COMMENCED_ID:
      colorCode = "#5E8AB4";
      break;
    case ACTIVITY_STATUS_SYS_VAR_NOT_COMMENCED_ID:
      colorCode = "#ffad0a";
      break;
    case ACTIVITY_STATUS_SYS_VAR_SCHEDULED:
      colorCode = "#898b8d";
      break;
    default:
      colorCode = "#ffffff";
      break;
  }
  return colorCode;
}

export const createVADetailTableRows = (reportList) => {
  let rowList = [];

  if (!reportList || reportList.length === 0) {
    rowList.push(emptyTablePlaceholder);
    return rowList;
  }

  reportList.forEach(report => {
    const {
      seceRef,
      barrierTitle,
      seceTitle,
      vaClientRef,
      vaLongDescAlt,
      nature,
      frequency,
      requiredSample,
      completedSample,
      performanceStandardCriteriaAlt,
      previousStatus,
      previousStatusColorCodeAlt,
      currentStatus,
      currentStatusColorCodeAlt,
      detailVaCompletedAlt = [],
      conclusiveStatement,
      verifier,
      date,
      findingsRaised,
      openFindings
    } = report ? report : {};

    let tempDetailVaCompletedAlt = Array.isArray(detailVaCompletedAlt) ? detailVaCompletedAlt : [detailVaCompletedAlt];

    let tableRow = {
      style: 'detailsTable',
      table: {
        widths: ['25%', '25%', '25%', '25%'],
        headerRows: 0,
        body: [
          //rows
          [{ text: 'SECE Ref.', fillColor: '#dcddde' }, seceRef, { text: 'Barrier', fillColor: '#dcddde' }, barrierTitle],
          [{ text: 'SECE Name', fillColor: '#dcddde' }, seceTitle, { text: 'Verification Activity Ref', fillColor: '#dcddde' }, vaClientRef],
          [{ text: 'Verification Activity Description', fillColor: '#dcddde' }, { stack: vaLongDescAlt, colSpan: 3 }],

          [{ text: 'Nature', fillColor: '#dcddde' }, nature, { text: 'Frequency', fillColor: '#dcddde' }, frequency],
          [{ text: 'Required Sample', fillColor: '#dcddde' }, requiredSample, { text: 'Completed Sample', fillColor: '#dcddde' }, completedSample],
          [{ text: 'Previous Status', fillColor: '#dcddde' }, { text: previousStatus, fillColor: previousStatusColorCodeAlt, color: previousStatusColorCodeAlt === "#ffffff" ? "#000000" : "#ffffff" }, { text: 'Current Status', fillColor: '#dcddde' }, { text: currentStatus, fillColor: currentStatusColorCodeAlt, color: "#ffffff" }],
          [{ text: 'Performance Standard Criteria', fillColor: '#dcddde' }, { stack: performanceStandardCriteriaAlt, colSpan: 3 }],
          [
            { text: 'Details of Verification Conducted', fillColor: '#dcddde' },
            {
              stack: [              
                ...tempDetailVaCompletedAlt
              ],
              colSpan: 3
            }
          ],

          [{ text: 'Conclusive Statement', fillColor: '#dcddde' }, { text: conclusiveStatement, colSpan: 3 }],
          [{ text: 'Verifier', fillColor: '#dcddde' }, verifier, { text: 'Date', fillColor: '#dcddde' }, date],
          [{ text: 'Findings Raised', fillColor: '#dcddde', colSpan: 4 }, {}, {}, {}],
          ...createTableFindingList(findingsRaised),

          [{ text: 'Existing Open Findings', fillColor: '#dcddde', colSpan: 4 }, {}, {}, {}],
          ...createTableFindingList(openFindings),
        ]
      },
      pageBreak: 'after'
    };
    rowList.push(tableRow);
  })
  return rowList;
}

function createTableFindingList(findingList) {
  let rowList = [];

  if (!findingList || findingList.length === 0) {
    let emptyRow = [{ text: "No Data to Display", fillColor: '#FFFFFF', colSpan: 4 }, {}, {}, {}]
    rowList.push(emptyRow);
    return rowList;
  }

  findingList.forEach(fnd => {
    const { finding_ref, synopsis } = fnd ? fnd : {};
    let row = [{ text: 'Finding Ref.', fillColor: '#dcddde' }, finding_ref, { text: 'Synopsis', fillColor: '#dcddde' }, synopsis];

    rowList.push(row);
  })

  return rowList;
}

export const createFindingDetailTableRows = (findingList) => {
  let rowList = [];

  if (!findingList || findingList.length === 0) {
    let emptyRow = { ...emptyTablePlaceholder, style: 'detailsTable2' }
    rowList.push(emptyRow);
    return rowList;
  }

  findingList.forEach(fnd => {
    const {
      findingRef,
      findingInfomatrixLevel,
      findingLevelColorCodeAlt,
      seceRef,
      currentStatus,
      seceName,
      vaRef,
      vaDescriptionAlt,
      integrity,
      legislative,
      paperwork,
      rogi,
      synopsisAlt,
      referenceAlt,
      referenceImages,
      referenceImageLinks,
      findingDetailAlt,
      actionAlt,
      responseDate,
      completionDate,
      actionParty,
      dutyHolderResponsible,
      verifier,
      dateRaised,
      reponses
    } = fnd ? fnd : {};

    let stackFindingDetailAlt = findingDetailAlt;

    if (!Array.isArray(stackFindingDetailAlt)) {
      stackFindingDetailAlt = [stackFindingDetailAlt];
    }

    let row1 = {
      style: 'detailsTable2',
      table: {
        widths: ['25%', '25%', '25%', '25%'],
        headerRows: 0,
        body: [
          //rows
          [{ text: 'Finding Reference', fillColor: '#dcddde' }, findingRef, { text: 'Finding Level', fillColor: '#dcddde' }, { text: findingInfomatrixLevel, fillColor: findingLevelColorCodeAlt, color: "#ffffff" }],
          [{ text: 'SECE Ref.', fillColor: '#dcddde' }, seceRef, { text: 'Finding Current Status', fillColor: '#dcddde' }, currentStatus],
          [{ text: 'SECE Name', fillColor: '#dcddde' }, seceName, { text: 'Verification Activity Ref.', fillColor: '#dcddde' }, vaRef],

          [{ text: 'Verification Activity Description', fillColor: '#dcddde' }, { stack: vaDescriptionAlt, colSpan: 3 }],

          [{ text: 'Integrity', fillColor: '#dcddde' }, integrity, { text: 'Legislative', fillColor: '#dcddde' }, legislative],
          [{ text: 'Paperwork / Process', fillColor: '#dcddde' }, paperwork, { text: 'ROGI', fillColor: '#dcddde' }, rogi],

          [{ text: 'Finding Synopsis', fillColor: '#dcddde' }, { stack: synopsisAlt, colSpan: 3 }],

          [{ text: 'Reference', fillColor: '#dcddde' }, { stack: referenceAlt, colSpan: 3 }],

          [
            { text: 'Reference Attachments', fillColor: '#dcddde' },
            {
              stack: [
                {
                  columnGap: 2,
                  columns: [
                    ...createImageRows(referenceImages)
                  ]
                },
                ...createImageLinkRows(referenceImageLinks)
              ],
              colSpan: 3
            }

          ],

          [{ text: 'Finding', fillColor: '#dcddde' }, { stack: stackFindingDetailAlt, colSpan: 3 }],

          [{ text: 'Action', fillColor: '#dcddde' }, { stack: actionAlt, colSpan: 3 }],

          [{ text: 'Response Date', fillColor: '#dcddde' }, responseDate, { text: 'Completion Date', fillColor: '#dcddde' }, completionDate],

          [{ text: 'Action Party', fillColor: '#dcddde' }, actionParty, { text: 'Duty Holder Responsible', fillColor: '#dcddde' }, dutyHolderResponsible],

          [{ text: 'Verifier', fillColor: '#dcddde' }, verifier, { text: 'Date Raised', fillColor: '#dcddde' }, dateRaised],
        ]
      },
    };

    let row2 = {
      style: 'detailsTable',
      table: {
        widths: ['25%', '15%', '10%', '25%', '10%', '15%'],
        headerRows: 0,
        body: [
          [{ text: 'Finding Responses', fillColor: '#dcddde', colSpan: 6 }, {}, {}, {}, {}, {}],
          ...createFindingResponseRows(reponses)
        ]
      },
      pageBreak: 'after'
    };
    rowList.push(row1);
    rowList.push(row2);
  })

  return rowList;
}

function createImageRows(images) {
  let rowList = [];

  if (!images || images.length === 0) {
    return rowList;
  }

  images.forEach(imageBase64 => {
    let row = { image: `data:image/jpeg;base64, ${imageBase64.linkAlt}`, width: 50, marginBottom: 5 };
    rowList.push(row);
  })

  return rowList;
}

function createImageLinkRows(imageLinks) {
  let rowList = [];

  if (!imageLinks || imageLinks.length === 0) {
    let row = { text: 'No Data to Display' };
    rowList.push(row);
    return rowList;
  }

  imageLinks.forEach(link => {
    let row = { text: link.name };
    rowList.push(row);
  })

  return rowList;
}

function createFindingResponseRows(responses) {
  let rowList = [];

  if (!responses || responses.length === 0) {
    let emptyRow = [{ text: 'No Data to Display', fillColor: '#FFFFFF', colSpan: 6 }, {}, {}, {}, {}, {}];
    rowList.push(emptyRow);
    return rowList;
  }

  responses.forEach(response => {
    const { responder, responseDate, responseCurrentStatus, detailAlt } = response ? response : {};

    let row1 = [{ text: 'Response by ABS / Duty Holder', fillColor: '#dcddde' }, responder, { text: 'Date', fillColor: '#dcddde' }, responseDate, { text: 'Current Status', fillColor: '#dcddde' }, responseCurrentStatus];
    let row2 = [{ text: 'Response', fillColor: '#dcddde', colSpan: 1 }, {stack: detailAlt, colSpan: 5 }];
    rowList.push(row1);
    rowList.push(row2);
  })
  return rowList;
}

const emptyTablePlaceholder = {
  style: 'detailsTable',
  table: {
    widths: ['25%', '25%', '25%', '25%'],
    headerRows: 0,
    body: [
      [{ text: 'No Data to Display', fillColor: '#dcddde', colSpan: 4 }, {}, {}, {}],
    ]
  },
  pageBreak: 'after'
}

function createImageLink(fullPath, name) {
  let defaultLink = "https://via.placeholder.com/350x350";
  let knownFileFormats = ["jpg", "jpeg", "png"];

  if (fullPath && fullPath !== "" && name && name !== "") {
    let nameArray = name.split(".");
    let fileFormat = nameArray[nameArray.length - 1];

    let lowerCaseFileFormat = lowerCase(fileFormat);

    let isKnownFormat = knownFileFormats.some(f => f === lowerCaseFileFormat)

    if (isKnownFormat) {
      defaultLink = (fullPath + name);
    }
  }

  return defaultLink;
}

export const createReportTitleFrom = (dhName, instlList) => {
  let title = "";
  if (dhName && instlList && instlList.length) {
    let instNameList = instlList.map(inst => inst.installation_title)
    let instListString = convertArrayToReadableString(instNameList);
    title = `${dhName}: ${instListString}`;
  }
  return title;
}

function convertArrayToReadableString(list) {
  let readableString = ""

  if (list && list.length) {

    let listSize = list.length;

    for (let index = 0; index < listSize; index++) {
      const element = list[index];

      if (index === 0) {
        readableString = element
        continue;
      }

      if (listSize === index + 1) {
        readableString = readableString + " and " + element;
        continue;
      }

      readableString = readableString + ", " + element
    }

  }

  return readableString;
}

export const getLatestRevisionFrom = (revisionList) => {
  let lastRevision = "";
  let listLength = revisionList.length
  if (revisionList && listLength) {
    const { revision } = revisionList[listLength - 1]
    lastRevision = revision;
  }
  return lastRevision
}

export const  formatPdfDescription = (descriptionList) => {
  let formatedDescription = [];

  if (descriptionList && descriptionList.length) {
    const description = descriptionList[0];

    const { text: textArray } = description;

    let newTextArray = [];

    for (let index = 0; index < textArray.length; index++) {
      const textElement = textArray[index];

      const { nodeName, text: level2TextArray } = textElement;

      if (nodeName === "STRONG") {
        let isArray = Array.isArray(level2TextArray);
        let newLevel2TextObj = {};
        if (isArray) {
          let level2Text = level2TextArray[0];

          newLevel2TextObj = level2Text && { text: [{ ...level2Text, color: '#b40c1e' }] };
        }

        let newTextElement = {
          ...textElement,
          color: '#b40c1e',
          ...newLevel2TextObj
        };

        newTextArray.push(newTextElement);
        continue;
      }

      newTextArray.push(textElement);
    }

    let newDescription = {
      ...description,
      text: newTextArray
    }

    formatedDescription.push(newDescription);

  }

  return formatedDescription;
}

export default ViewAssetVerification;