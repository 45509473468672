/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import pdfMake from "pdfmake/build/pdfmake";
import PageSpinner from "../global/pageSpinner";
import Loader from 'react-loader-spinner';
import '../global/pdf/customfonts';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { gql, useLazyQuery } from '@apollo/client';
import Sanitiser from "../global/sanitiser";
import Table from '../global/Table';
import ErrorBoundary from '../global/ErrorBoundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { vmLogo } from '../global/pdf/vmLogo';
import { logoImage } from '../global/pdf/logoImage';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {filterFindingsByIssue, createDataForFindingsByIssueBarChart, FindingIssues} from "../statistics/statistics";
import { formatDateToLocaleDateString, formatToShortDate, adjustTextNode } from "../../utils";
import {
  createReportTitleFrom,
  getLatestRevisionFrom,
  classColorCodeMapper
} from './viewAssetVerification';

import {
  NOTIFICATION_STATUS_PENDING,
  NOTIFICATION_STATUS_APPROVED,
  NOTIFICATION_STATUS_REVIEW,
} from '../../constants/';

import { CenteredLayout } from '../global/pdf/centeredLayout';

var htmlToPdfmake = require("html-to-pdfmake");

const VS_REPORT_BY_ID_QUERY = gql`
query verificationScheduleReport($vsRptId: ID!){
    verificationScheduleReport(_id: $vsRptId) {
        _id
        dh_id{
          _id
          dh_name
        }
        installation_ids{
          _id
          installation_title
        }
        vs_rpt_ref
        vs_rpt_is_periodic
        vs_rpt_title
        vs_rpt_description
        vs_rpt_summary
        vs_rpt_scope
        vs_rpt_statement
        vs_rpt_improvements
        vs_rpt_concerns
        vs_rpt_positives
        vs_rpt_payment_details
        vs_ids{
           _id 
           vs_title
           vs_periodicity{ _id}
           vs_status
           verifier_ids{
             _id
             user_fname
             user_lname
           }
           sece_ids{
             _id
           }
        }
        vs_rpt_rev_num
        vs_rpt_issuances
        vs_rpt_start_date
        vs_rpt_end_date
        vs_rpt_issue_date
        vs_rpt_number
        vs_rpt_revision_description
        vs_rpt_scheme_ref
        vs_rpt_added_date
        vs_rpt_added_id{
          _id
        }
        vs_rpt_archived
        vs_rpt_status
  }
}`;

const HISTORIC_DETAILED_SECE_BREACKDOWN = gql`
query DetailedSeceBreakdownFromBactchRecord($param: VsReportStatsInput!){
  detailedSeceBreakdownFromBactchRecord(param: $param) {
      sece_order
      sece_ref
      sece_title
      offshore
      onshore
      remote
      level1
      level2
      level3
  }
}`;

const HISTORIC_ACTIVITIES_STATUS_BY_VS_ID_LIST_ALT = gql`
query ActivitiesStatusByVsIdListFromHistoryAlt($param: VsReportStatsInput!){
  activitiesStatusByVsIdListFromHistoryAlt(param: $param) {
    completedVas {
      sece_ref
      sece_name
      va_ref
      va_short_desc
      as_found_status
    }
    commencedVas{
      sece_ref
      sece_name
      va_ref
      va_short_desc
      as_found_status          
    }
    notCommencedVas{
      sece_ref
      sece_name
      va_ref
      va_short_desc
      as_found_status            
    }
    findings {
      id
      finding_ref
      finding_level_summary
      finding_infomatrix_level
      sece_ref
      synopsis
      status
   }
  }
}`;

const HISTORIC_OPEN_FINDINGS_QUERY = gql`
    query FindingStatsFromHistory($param: VsReportStatsInput!){
      findingStatsFromHistory(param: $param) {
            date {
                labels,
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels,
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
}`;

const HISTORIC_NATURE_FINDINGS_QUERY = gql`
    query FindingStatsGroupByNatureFromHistory($param: VsReportStatsInput!) {
      findingStatsGroupByNatureFromHistory(param: $param) {
            date {
                labels
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels
                datasets {
                    label           
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
}`;

const HISTORIC_ISSUES_FINDINGS_QUERY = gql`
    query findingStatsGroupByIssuesFromHistory($param: VsReportStatsInput!){
      findingStatsGroupByIssuesFromHistory(param: $param)  {
            label
            count
            backgroundColor
            linkHref
        }
}`;

const USER_BY_IDS_QUERY = gql`
      query UserByIds($idList: [ID!]!){
          user_by_ids(idList: $idList) {
              _id
              user_location
              user_ms_oid
              user_email
              user_fname
              user_job_title
              user_lname
              user_mobile
              user_notes
              user_office_tel
              user_profile_image
              user_type {
                  _id
                  user_type_display_name
              }
              user_role {
                _id
                user_type
                user_type_id
                user_role_display
                user_role_code
              }
              user_duty_holder {
                _id
                dh_name
                dh_code
              }
              user_duty_holder_location{
                location_name
                location_address_line_1
                location_address_line_2
                location_address_city
                location_address_country
                location_address_post_zip_code
              }
              user_status
          }
}`;

const HISTORIC_VS_STATS_BY_VS_IDLIST_QUERY = gql`
query verficationScheduleStatsByIdListAltFromHistory($param: VsReportStatsInput!){
  verficationScheduleStatsByIdListAltFromHistory(param: $param) {
    labels
    datasets {
      label
      borderColor
      backgroundColor
      fill
      data
    }
  }
}`;

const REPORT_REVISIONS = gql`
query ReportRevisionsByParentId($parentId: String!){
  reportRevisionsByParentId(parentId: $parentId) {
    revision
    revisedDate
    approvedDate
    description
    revisedBy
    approvedBy
  }
}`;

const VS_REPORT_EDITORS_QUERY = gql`
query VerificationScheduleReportEditors($vsRptId : ID!){
  verificationScheduleReportEditors(_id: $vsRptId) {
    author{
      _id
      full_name
      location
      signature
      date
    }
    reviewer{
      _id
      full_name
      location
      signature
      date
    }
  }
}`;

const FINDING_SEARCH_FROM_HISTORY_QUERY = gql`
query FindingSearchFromHistory($param: VsReportStatsInput!){
  findingSearchFromHistory(param: $param) {
      id
      finding_ref
      finding_level_summary
      finding_infomatrix_level
      sece_ref
      synopsis
      status
  }
}`;

const defaultResultObject = {
  verificationScheduleReport: false,
  user_by_ids: false,

  verficationScheduleStatsByIdList: false,
  verificationScheduleReportEditors: false,

  reportRevisionsByParentId: false,
  detailedSeceBreakdown: false,

  activitiesStatusByVsIdList: false,
  findingRegisterList: false,

  findingStats: false,
  findingStatsGroupByNature: false,
  findingStatsGroupByIssues: false,
}

const ViewPeriodicVerification = (props) => {
  const divRef = useRef(null); 
  const defaultState = {date: { datasets: [{_meta: {}, label: ""}] }, level: { datasets: [{_meta: {}, label: ""}] }}

  //graphs and charts
  const [isLoaded, setIsLoaded] = useState(false);
  const [vsStatsData, setVsStatsData] = useState({});

  const [vsPdfReportTitle, setVsPdfReportTitle] = useState("");
  const [vsPdfReportSubTitle, setVsPdfReportSubTitle] = useState("");
 
  const [vsReport, setVsReport] = useState({});
  const [dateRange, setDateRange] = useState("");
  const [dateRangeStart, setDateRangeStart] = useState("");
  const [dateRangeEnd, setDateRangeEnd] = useState("");

  const [vsReportHistories, setVsReportHistories] = useState([]);
  const [seceBreakdowns, setSeceBreakdowns] = useState([]);

  const [vsDutyHolder, setVsDutyHolder] = useState("");
  const [vsInstallations, setVsInstallations] = useState([]);
  const [vsVerifiers, setVsVerifiers] = useState([]);

  const [completedVaList, setCompletedVaList] = useState([]);
  const [commencedVaList, setCommencedVaList] = useState([]);
  const [notCommencedVaList, setNotCommencedVaList] = useState([]);
  const [raisedFindings, setRaisedFindings] = useState([]);

  const [openFindingsStats, setOpenFindingsStats] = useState(defaultState);
  const [natureFindingsStats, setNatureFindingsStats] = useState(defaultState);
  const [issuesFindingsStats, setIssuesFindingsStats] = useState([]);
  const [issuesFindingsStatsBarChart, setIssuesFindingsStatsBarChart] = useState({});
  const [issuesFindingsStatsSvg, setIssuesFindingsStatsSvg] = useState("<svg></svg>");

  const [reportNumber, setReportNumber] = useState("");
  const [verSchemeRef, setVerSchemeRef] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [currentRevision, setCurrentRevision] = useState("");

  const [pdfAuthorDetail, setPdfAuthorDetail] = useState({});
  const [pdfReviewerDetail, setPdfReviewerDetail] = useState({});

  const [resultObject, setResultObject] = useState({});
  const [resultStatus, setResultStatus] = useState(false);

  const [chartImageStatus, setChartImageStatus] = useState(false);

  const { vsRptId = "" } = props ? props : {};

  const [verificationScheduleReport, { data: vsReportData }] = useLazyQuery(VS_REPORT_BY_ID_QUERY);
  const [user_by_ids, { data: vsVerifierData }] = useLazyQuery(USER_BY_IDS_QUERY);

  const [verficationScheduleStatsByIdListAltFromHistory, { loading: vsStatsLoading, error: vsStatsError, data: vsListStatsResult }] = useLazyQuery(HISTORIC_VS_STATS_BY_VS_IDLIST_QUERY);
  
  const [verificationScheduleReportEditors, { data: vsReportEditorsData }] = useLazyQuery(VS_REPORT_EDITORS_QUERY);

  const [reportRevisionsByParentId, { data: ReportRevisionData }] = useLazyQuery(REPORT_REVISIONS);

  const [detailedSeceBreakdownFromBactchRecord, { loading: seceBreakdownLoading, error: seceBreakdownError, data: seceBreakdownData }] = useLazyQuery(HISTORIC_DETAILED_SECE_BREACKDOWN);

  const [activitiesStatusByVsIdListFromHistoryAlt, { loading: svaLoading, error: svaError, data: scheduledVaStatusData }] = useLazyQuery(HISTORIC_ACTIVITIES_STATUS_BY_VS_ID_LIST_ALT);

  const [findingSearchFromHistory, { loading: fndRegLoading, error: fndRegError, data: fndRegData }] = useLazyQuery(FINDING_SEARCH_FROM_HISTORY_QUERY);

  const [findingStatsFromHistory, { loading: openFindingsLoading, error: openFindingsError, data: openFindingsData }] = useLazyQuery(HISTORIC_OPEN_FINDINGS_QUERY);

  const [findingStatsGroupByNatureFromHistory, { loading: natureFindingsLoading, error: natureFindingsError, data: natureFindingsData }] = useLazyQuery(HISTORIC_NATURE_FINDINGS_QUERY);

  const [findingStatsGroupByIssuesFromHistory, { loading: issuesFindingsLoading, error: issuesFindingsError, data: issuesFindingsData }] = useLazyQuery(HISTORIC_ISSUES_FINDINGS_QUERY);

  let svgElement = document.getElementsByClassName("svg-content-responsive").item(0);
 
  useEffect(() => {
    setTimeout(() => {setIsLoaded(true);}, 9000);
    setResultObject(defaultResultObject);

    if (vsRptId !== "") {
      verificationScheduleReport({ variables: { vsRptId: vsRptId }, errorPolicy: 'all' });
      reportRevisionsByParentId({ variables: { parentId: vsRptId }, errorPolicy: 'all' });
      verificationScheduleReportEditors({ variables: { vsRptId: vsRptId }, errorPolicy: 'all' });
    }
  }, []);

  useEffect(() => {
    if (resultObject && resultObject.hasOwnProperty("user_by_ids")) {
      let areAllPropertiesTrue = Object.values(resultObject).every(value => value === true);
      setResultStatus(areAllPropertiesTrue);
    }
  }, [resultObject]);

  useEffect(() => {
    if (svgElement) {
      if (svgElement) {
        const svg = svgElement.outerHTML;
        setIssuesFindingsStatsSvg(svg);
      }
    }
  }, [svgElement]);

  useEffect(() => {
    if (vsReportData && vsReportData.verificationScheduleReport) {
      let vsReport = vsReportData.verificationScheduleReport;
      let vsList = vsReport.vs_ids;
      let verifierArrayList = vsList && vsList.length > 0 ? vsList.map(v => v.verifier_ids) : [];

      let verifierIdList = [];
      for (let index = 0; index < verifierArrayList.length; index++) {
        const verifierArray = verifierArrayList[index];
        for (let index = 0; index < verifierArray.length; index++) {
          const verifier = verifierArray[index];
          if (verifier) {
            verifierIdList.push(verifier._id);
          }
        }
      }

      let vsRrptNumber = vsReport.vs_rpt_number && vsReport.vs_rpt_number ? vsReport.vs_rpt_number : "";
   
      let tempVsRptIssueDate = vsReport.vs_rpt_issue_date ? new Date(parseInt(vsReport.vs_rpt_issue_date)): "N/A"
      let vsRptIssueDate = tempVsRptIssueDate === "N/A"? tempVsRptIssueDate : formatDateToLocaleDateString(tempVsRptIssueDate);
      let vsRptSchemeRef = vsReport.vs_rpt_scheme_ref && vsReport.vs_rpt_scheme_ref ? vsReport.vs_rpt_scheme_ref : "";

      let instlList = vsReport.installation_ids ? vsReport.installation_ids : [];
      let instlId = instlList.length ? instlList[0]._id : "";
      let installationId = instlList.map(inst => inst._id);

      let dhId = vsReport.dh_id && vsReport.dh_id._id ? vsReport.dh_id._id : "";
      let dhName = vsReport.dh_id && vsReport.dh_id.dh_name ? vsReport.dh_id.dh_name : "N/A";

      let startDate = new Date(parseInt(vsReport.vs_rpt_start_date));
      let endDate = new Date(parseInt(vsReport.vs_rpt_end_date));

      let formatedStartDate = formatDateToLocaleDateString(startDate);
      let formatedEndDate = formatDateToLocaleDateString(endDate);

      let dateRange = `${formatToShortDate(startDate)} - ${formatToShortDate(endDate)}`;

      let rptDescriptionAlt = htmlToPdfmake(vsReport.vs_rpt_description, {
        defaultStyles:{ // change the default styles
          p: {
            margin: [0,0,0,0]
          },
          br: {
            margin: [0,0,0,0]
          }
        }
      });
      const adjustedRptDescriptionAlt = adjustTextNode(rptDescriptionAlt);

      let rptSummaryAlt = htmlToPdfmake(vsReport.vs_rpt_summary)
      const adjustedRptSummaryAlt = adjustTextNode(rptSummaryAlt);

      let rptScopeAlt = htmlToPdfmake(vsReport.vs_rpt_scope)
      const adjustedRptScopeAlt = adjustTextNode(rptScopeAlt);

      let rptStatementAlt = htmlToPdfmake(vsReport.vs_rpt_statement)
      const adjustedRptStatementAlt = adjustTextNode(rptStatementAlt);

      let rptImprovementsAlt = htmlToPdfmake(vsReport.vs_rpt_improvements)
      const adjustedRptImprovementsAlt = adjustTextNode(rptImprovementsAlt);

      let rptConcernsAlt = htmlToPdfmake(vsReport.vs_rpt_concerns)
      const adjustedRptConcernsAlt = adjustTextNode(rptConcernsAlt);

      let rptPositivesAlt = htmlToPdfmake(vsReport.vs_rpt_positives)
      const adjustedRptPositivesAlt = adjustTextNode(rptPositivesAlt);

      let report = {
        id: vsReport._id,
        dhName: dhName,
        rptIsPeriodic: vsReport.vs_rpt_is_periodic,
        rptTitle: vsReport.vs_rpt_title && vsReport.vs_rpt_title !== "" ? vsReport.vs_rpt_title: "N/A",
        rptDescription: vsReport.vs_rpt_description,
        rptDescriptionAlt: adjustedRptDescriptionAlt,
        rptSummary: vsReport.vs_rpt_summary,
        rptSummaryAlt: adjustedRptSummaryAlt,
        rptScope: vsReport.vs_rpt_scope,
        rptScopeAlt: adjustedRptScopeAlt,
        rptStatement: vsReport.vs_rpt_statement,
        rptStatementAlt: adjustedRptStatementAlt,
        rptImprovements: vsReport.vs_rpt_improvements,
        rptImprovementsAlt: adjustedRptImprovementsAlt,
        rptConcerns: vsReport.vs_rpt_concerns,
        rptConcernsAlt: adjustedRptConcernsAlt,
        rptPositives: vsReport.vs_rpt_positives,
        rptPositivesAlt: adjustedRptPositivesAlt,
        rptPaymentDetails: vsReport.vs_rpt_payment_details,
        instlId: instlId,
        vsId: vsReport.vs_id ? vsReport.vs_id._id : "",
        vsIdList: vsReport.vs_ids ? vsReport.vs_ids.map(vs => vs._id) : [],
        rptStartDate: startDate,
        rptEndDate: endDate,
        rptStatus: vsReport.vs_rpt_status,
        rptApprovedBy: vsReport.vs_rpt_approved_by ? vsReport.vs_rpt_approved_by : ""
      }

      let reportTitle = createReportTitleFrom(dhName, instlList);

      setVsPdfReportTitle(reportTitle);
      setVsPdfReportSubTitle(report.rptTitle);

      setVsReport(report);
      setDateRange(dateRange);
   
      setDateRangeStart(formatedStartDate);
      setDateRangeEnd(formatedEndDate);

      setReportNumber(vsRrptNumber);
      setVerSchemeRef(vsRptSchemeRef);
      setIssueDate(vsRptIssueDate);

      setVsDutyHolder(dhName);
      setVsInstallations(instlList);
      setResultObject(prevState => ({...prevState, verificationScheduleReport: true}));

      if (verifierIdList.length) {
        user_by_ids({ variables: { idList: verifierIdList }, errorPolicy: 'all' });
      }


      if (dhId && dhId !== "") {
        
        let payload = {
          dh_id: dhId,
          inst_ids: installationId,
          vs_id_list: [],
          start_date: startDate,
          end_date: endDate
        }
        detailedSeceBreakdownFromBactchRecord({ variables: { param: payload }, errorPolicy: 'all' });
      }

      if (vsList.length) {
        let vsIdList = vsList.map(vs => vs._id);
      
        let payload = {
          dh_id: dhId,
          inst_ids: installationId,
          end_date: endDate
        }

        let payloadAlt = {
          dh_id: dhId,
          vs_id_list: vsIdList,
          start_date: startDate,
          end_date: endDate
        }

        let payloadAlt2 = {
          dh_id: dhId,
          inst_ids: installationId,
          vs_id_list: [],
          start_date: startDate,
          end_date: endDate
        }

        activitiesStatusByVsIdListFromHistoryAlt({ variables: {  param: payloadAlt }, errorPolicy: 'all' });
        findingSearchFromHistory({ variables: {  param: payloadAlt2 }, errorPolicy: 'all' });
        findingStatsFromHistory({ variables: {  param: payload }, errorPolicy: 'all' });
        findingStatsGroupByNatureFromHistory({ variables: {  param: payload }, errorPolicy: 'all' });
        findingStatsGroupByIssuesFromHistory({ variables: {  param: payload }, errorPolicy: 'all' });     
      }
    }
  }, [vsReportData]);

  useEffect(() => {
    if (vsReportEditorsData && vsReportEditorsData.verificationScheduleReportEditors) {
      let editors = vsReportEditorsData.verificationScheduleReportEditors;
      const {author, reviewer} = editors ? editors : {}
      setPdfAuthorDetail(author);
      setPdfReviewerDetail(reviewer);
      setResultObject(prevState => ({...prevState, verificationScheduleReportEditors: true}));
    }
  }, [vsReportEditorsData]);

  useEffect(() => {
    if (scheduledVaStatusData && scheduledVaStatusData.activitiesStatusByVsIdListFromHistoryAlt) {
      let scheduledVaList = scheduledVaStatusData.activitiesStatusByVsIdListFromHistoryAlt;  
      const { completedVas, commencedVas, notCommencedVas } = scheduledVaList ? scheduledVaList : {};  
      setCompletedVaList(completedVas);
      setCommencedVaList(commencedVas);
      setNotCommencedVaList(notCommencedVas);
      setResultObject(prevState => ({...prevState, activitiesStatusByVsIdList: true}));
    }
  }, [scheduledVaStatusData]);

  useEffect(() => {
    if (fndRegData && fndRegData.findingSearchFromHistory) {
      setRaisedFindings(fndRegData.findingSearchFromHistory);
      setResultObject(prevState => ({...prevState, findingRegisterList: true}));
    }
  }, [fndRegData]);

  useEffect(() => {
    if (openFindingsData && openFindingsData.findingStatsFromHistory) {
      setOpenFindingsStats(prevState => ({...prevState, ...{
        date: openFindingsData.findingStatsFromHistory.date,
        level: openFindingsData.findingStatsFromHistory.level,
      }}));
      setResultObject(prevState => ({...prevState, findingStats: true}));
    }
  }, [openFindingsData]);

  useEffect(() => {
    if (natureFindingsData && natureFindingsData.findingStatsGroupByNatureFromHistory) { 
      setNatureFindingsStats(prevState => ({...prevState, ...{
        date: natureFindingsData.findingStatsGroupByNatureFromHistory.date,
        level: natureFindingsData.findingStatsGroupByNatureFromHistory.level,
      }}));
      setResultObject(prevState => ({...prevState, findingStatsGroupByNature: true}));
    }
  }, [natureFindingsData]);

  useEffect(() => {
    if (issuesFindingsData && issuesFindingsData.findingStatsGroupByIssuesFromHistory) {
      const findingsByIssueData = issuesFindingsData.findingStatsGroupByIssuesFromHistory;
      let filteredList = filterFindingsByIssue(findingsByIssueData);
      setIssuesFindingsStats(filteredList);
      let findingsByIssueBarChart = createDataForFindingsByIssueBarChart(findingsByIssueData);
      setIssuesFindingsStatsBarChart(findingsByIssueBarChart);
      setResultObject(prevState => ({...prevState, findingStatsGroupByIssues: true}));
    }
  }, [issuesFindingsData]);

  useEffect(() => {
    if (vsVerifierData && vsVerifierData.user_by_ids) {
      let users = vsVerifierData.user_by_ids;
      let formatedVerifiers = users.map(user => {
        const { user_fname = "", user_lname = "" } = user ? user : {};
        return `${user_fname} ${user_lname}`;
      });
      setVsVerifiers(formatedVerifiers);
      setResultObject(prevState => ({...prevState, user_by_ids: true}));
    }
  }, [vsVerifierData]);

  useEffect(() => {
    if (seceBreakdownData && seceBreakdownData.detailedSeceBreakdownFromBactchRecord) {
      let breakDown = seceBreakdownData.detailedSeceBreakdownFromBactchRecord;
      setSeceBreakdowns(breakDown);
      setResultObject(prevState => ({...prevState, detailedSeceBreakdown: true}));
    }
  }, [seceBreakdownData]);

  useEffect(() => {
    const {vsIdList = [], rptStartDate = "", rptEndDate = "" } = vsReport;
    if (vsIdList.length && rptStartDate !== "" && rptEndDate !== "") {      

      let modStartDate = new Date(rptStartDate);
      modStartDate.setMonth(modStartDate.getMonth() - 1);

      let modEndDate = new Date(rptEndDate);
      modEndDate.setMonth(modEndDate.getMonth() + 1);

      let payloadAlt = { 
        vs_id_list: vsIdList, 
        start_date: modStartDate, 
        end_date: modEndDate 
      }

      verficationScheduleStatsByIdListAltFromHistory({ variables: { param: payloadAlt }, errorPolicy: 'all' });
    }
  }, [vsReport]);

  useEffect(() => {
    if (vsListStatsResult && vsListStatsResult.verficationScheduleStatsByIdListAltFromHistory) {
      setVsStatsData(vsListStatsResult.verficationScheduleStatsByIdListAltFromHistory);
      setResultObject(prevState => ({...prevState, verficationScheduleStatsByIdList: true}));
    }
  }, [vsListStatsResult]);

  useEffect(() => {
    if (ReportRevisionData && ReportRevisionData.reportRevisionsByParentId) {
      let reportRevisions = ReportRevisionData.reportRevisionsByParentId;
      let latestRevision = getLatestRevisionFrom(reportRevisions);

      setCurrentRevision(latestRevision)
      setVsReportHistories(ReportRevisionData.reportRevisionsByParentId);
      setResultObject(prevState => ({...prevState, reportRevisionsByParentId: true}));
    }
  }, [ReportRevisionData]);

  //Verification Schedule pie base64
  const VerificationScheduleRef = useRef(null);
  const [base64VerificationSchedule, setBase64VerificationSchedule] = useState('');
  function doneVerificationSchedule() {
    setBase64VerificationSchedule(VerificationScheduleRef.current.chartInstance.toBase64Image());  
  }

  //Open findings level pie base64
  const OpenFindingsStatus = useRef(null);
  const [base64OpenFindingsStatus, setBase64FindingsStatus] = useState('');
  function doneOpenFindingsStatus() {
    setBase64FindingsStatus(OpenFindingsStatus.current.chartInstance.toBase64Image());
  }

  const OpenFindingsLevel = useRef(null);
  const [base64OpenFindingsLevel, setBase64FindingsLevel] = useState('');
  function doneOpenFindingsLevel() {
    setBase64FindingsLevel(OpenFindingsLevel.current.chartInstance.toBase64Image());
  }

  const FindingsByNatureStatus = useRef(null);
  const [base64FindingsByNatureStatus, setBase64FindingsByNatureStatus] = useState('');
  function doneFindingsByNatureStatus() {
    const chartImage = FindingsByNatureStatus.current.chartInstance.toBase64Image();
    setBase64FindingsByNatureStatus(chartImage);
  }

  const FindingsByNatureLevel = useRef(null);
  const [base64FindingsByNatureLevel, setBase64FindingsByNatureLevel] = useState('');
  function doneFindingsByNatureLevel() {
    setBase64FindingsByNatureLevel(FindingsByNatureLevel.current.chartInstance.toBase64Image());
  }
 
  const FindingsIssuesBarChart = useRef(null);
  const [base64FindingsIssuesBarChart, setBase64FindingsIssuesBarChart] = useState('');
  function doneFindingsIssuesBar() {
    setBase64FindingsIssuesBarChart(FindingsIssuesBarChart.current.chartInstance.toBase64Image());
  }

  useEffect(() => {
    let areAllChartBaseImagesUpdated = base64VerificationSchedule !== "" &&
      base64OpenFindingsStatus !== "" &&
      base64OpenFindingsLevel !== "" &&
      base64FindingsByNatureStatus !== "" &&
      base64FindingsByNatureLevel !== "" &&
      base64FindingsIssuesBarChart !== "";
    setChartImageStatus(areAllChartBaseImagesUpdated);
  }, [base64VerificationSchedule,
    base64OpenFindingsStatus,
    base64OpenFindingsLevel,
    base64FindingsByNatureStatus,
    base64FindingsByNatureLevel,
    base64FindingsIssuesBarChart]);

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 35],
    header: function (currentPage, pageCount) {
      if (currentPage !== 1 && currentPage !== pageCount) {
        var headerObj;
        if (currentPage % 2) {
          headerObj = {
            table: {
              headerRows: 0,
              widths: ['75%', '25%'],
              body: [
                [
                  {
                    border: [false, false, true, false],
                    fillColor: '#002a4e',
                    stack: [
                      { text: vsDutyHolder, font: 'Montserrat', fontSize: 9, color: '#fff', margin: 0 },
                      { text: `Report No. ${reportNumber} ${currentRevision}`, font: 'Montserrat', fontSize: 9, color: '#fff' },
                      [{
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 30,
                            h: 0.5,
                            r: 0,
                            lineWidth: 1,
                            lineColor: 'red',
                          }
                        ]
                      }],
                      { text: 'PERIODIC VERIFICATION REPORT', font: 'Montserrat', fontSize: 12, color: '#fff', bold: true, marginTop: 3 }
                    ]
                  },
                  {
                    border: [true, false, false, false],
                    fillColor: '#b40c1e',
                    ...(logoImage && logoImage !== "" && {image: logoImage}),
                    width: 100
                  },
                ]
              ],
            },
            layout: {
              vLineColor: function (i, node) { return 'white'; },
              vLineWidth: function (i) { return 4; },
              paddingLeft: function (i, node) { return 30; },
              paddingRight: function (i, node) { return 10; },
              paddingTop: function (i, node) { return 20; },
              paddingBottom: function (i, node) { return 10; },
            }
          };
        } else {
          headerObj = {
            table: {
              headerRows: 0,
              widths: ['25%', '75%'],
              body: [
                [
                  {
                    border: [false, false, true, false],
                    fillColor: '#b40c1e',
                    ...(logoImage && logoImage !== "" && {image: logoImage}),
                    width: 100
                  },
                  {
                    border: [true, false, false, false],
                    fillColor: '#002a4e',
                    stack: [
                      { text: vsDutyHolder, font: 'Montserrat', fontSize: 9, color: '#fff', margin: 0 },
                      { text: `Report No. ${reportNumber} ${currentRevision}`, font: 'Montserrat', fontSize: 9, color: '#fff' },
                      [{
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 30,
                            h: 0.5,
                            r: 0,
                            lineWidth: 1,
                            lineColor: 'red',
                          }
                        ]
                      }],
                      { text: 'PERIODIC VERIFICATION REPORT', font: 'Montserrat', fontSize: 12, color: '#fff', bold: true, marginTop: 3 }
                    ]
                  },
                ]
              ],
            },
            layout: {
              vLineColor: function (i, node) { return 'white'; },
              vLineWidth: function (i) { return 4; },
              paddingLeft: function (i, node) { return 30; },
              paddingRight: function (i, node) { return 10; },
              paddingTop: function (i, node) { return 20; },
              paddingBottom: function (i, node) { return 10; },
            }
          };
        }
      }
      return headerObj;
    },
    footer: function (currentPage, pageCount) {
      if (currentPage !== 1 && currentPage !== pageCount) {
        var footerObj;

        footerObj = [
          { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, font: 'Montserrat', fontSize: 8, alignment: 'center' },
          { text: `Copyright © by ABS. All Rights Reserved. CONFIDENTIAL`, font: 'Montserrat', fontSize: 8, alignment: 'center' }
        ]

        //return currentPage.toString() -1; 
        return footerObj;
      }
    },
    //metadata
    info: {
      title: 'Periodic Verification Report',

      author: pdfAuthorDetail.full_name ? pdfAuthorDetail.full_name : "N/A",

    },
    content: [
      //COVER PAGE
      {
        table: {
          widths: ['100%'],
          heights: [200],
          body: [
            [{
              fillColor: '#b40c1e', border: [false, false, false, true],
              stack: [
                ...(vmLogo && vmLogo !== "" && [{ image: vmLogo, width: 300, marginBottom: 25 }]),
                { text: 'PERIODIC', font: 'Montserrat', fontSize: 30, color: '#fff', bold: true, lineHeight: 1 },
                { text: 'VERIFICATION', font: 'Montserrat', fontSize: 30, color: '#fff', bold: true, lineHeight: 1 },
                { text: 'REPORT', font: 'Montserrat', fontSize: 30, color: '#fff', bold: true, lineHeight: 1, marginBottom: 20 },
                [{
                  canvas: [
                    {
                      type: 'rect',
                      x: 0,
                      y: 0,
                      w: 105,
                      h: 0.5,
                      r: 0,
                      lineWidth: 1,
                      lineColor: 'white',
                    }
                  ]
                }],
              ]
            }],
          ],
        },
        layout: {
          vLineColor: function (i, node) { return '#fff'; },
          vLineWidth: function (i) { return 0; },
          hLineColor: function (i, node) { return 'white'; },
          hLineWidth: function (i) { return 6; },
          paddingLeft: function (i, node) { return 40; },
          paddingRight: function (i, node) { return 40; },
          paddingTop: function (i, node) { return 40; },
          paddingBottom: function (i, node) { return 10; },
        },
      },
      {
        table: {
          widths: ['100%'],
          heights: [100],
          body: [
            [{
              stack: [

                { text: vsPdfReportTitle, font: 'Montserrat', fontSize: 14, lineHeight: 1 },
                { text: dateRange, font: 'Montserrat', fontSize: 14, lineHeight: 1, marginBottom: 20 },
                { text: `Report No. ${reportNumber} ${currentRevision}`, font: 'Montserrat', fontSize: 14, lineHeight: 1 },
                { text: vsPdfReportSubTitle, font: 'Montserrat', fontSize: 14, lineHeight: 1 },
                { text: `Date of Issue: ${issueDate}`, font: 'Montserrat', fontSize: 14, lineHeight: 1, marginBottom: 20 },
                { text: `Verification Scheme Ref(s): ${verSchemeRef}`, font: 'Montserrat', fontSize: 14, lineHeight: 1 },

              ]
            }]
          ],
        },
        layout: {
          vLineColor: function (i, node) { return '#fff'; },
          vLineWidth: function (i) { return 0; },
          hLineColor: function (i, node) { return 'white'; },
          hLineWidth: function (i) { return 6; },
          paddingLeft: function (i, node) { return 0; },
          paddingRight: function (i, node) { return 0; },
          paddingTop: function (i, node) { return 30; },
          paddingBottom: function (i, node) { return 30; },
        },
      },

      {
        table: {
          widths: ['100%'],
          heights: [120],
          body: [
            [{
              fillColor: '#002a4e', border: [false, true, false, false],
              stack: [

                ...(logoImage && logoImage !=="" && [{ image: logoImage, width: 225, alignment: 'center' }]),

              ]
            }]
          ],
        },
        layout: {
          vLineColor: function (i, node) { return '#fff'; },
          vLineWidth: function (i) { return 0; },
          hLineColor: function (i, node) { return 'white'; },
          hLineWidth: function (i) { return 6; },
          paddingLeft: function (i, node) { return 40; },
          paddingRight: function (i, node) { return 40; },
          paddingTop: function (i, node) { return 40; },
          paddingBottom: function (i, node) { return 10; },
        },
        pageBreak: 'after'
      },
      //COVER PAGE END

      //DOCUMENT CONTROL
      [{
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 30,
            h: 0.5,
            r: 0,
            lineWidth: 1,
            lineColor: '#b40c1e',
          }
        ]
      }], //red line
      { text: 'DOCUMENT CONTROL', style: 'title' },
      {
        columns: [
          //empty col for left spacing
          {
            width: 40,
            text: ''
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              //REVISIONS TABLE
              {
                table: {
                  widths: ['auto', 'auto', '*', 'auto', 'auto'], //col widths
                  headerRows: 2,
                  body: [
                    [{ text: 'REVISION CONTROL', style: 'StandardTableHeader', colSpan: 5, alignment: 'center', borderColor: ['#000', '#000', '#000', '#fff'] }, {}, {}, {}, {}],//first header
                    [{ text: 'Rev.', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#000', '#fff', '#fff', '#fff'] }, { text: 'Date', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, { text: 'Description', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, { text: 'Revised By', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, { text: 'Approved By', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#fff', '#fff', '#000', '#fff'] }],//second header
                    ...createRevisionTableRows(vsReportHistories)                   
                  ]
                },
                style: 'standardTable',
              },
              //DOCUMENTS TABLE
              /*
              {
                table: {
                  widths: ['33.3%', '33.3%', '33.3%'], //col widths
                  headerRows: 1,
                  body: [
                    [{ text: 'Issue Number', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#000', '#000', '#fff', '#fff'] }, { text: 'Document Holder', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#000', '#000', '#fff', '#fff'] }, { text: 'Location', style: 'StandardTableHeader', alignment: 'center', borderColor: ['#fff', '#000', '#000', '#fff'] }],//header
                    ...createDocumentTableRows(reportIssuanceList)
                  ]
                },
                style: 'standardTable',
              }, */
              //{ text: ['This is to certify that the undersigned Surveyor to this Bureau, did at the request of the Duty Holder’s Representative, attend the Maersk Drilling office in Lyngby, to complete onshore Verification activities for Maersk Resolve, ', { text: 'ABSID 09162307', color: '#b40c1e' }, { text: ', of ' }, { text: 'Republic of Singapore.', color: '#b40c1e' }] },
              { stack: vsReport.rptDescriptionAlt },
              // { text: 'In here will go a standard statement that will be created in the “General Template”.', marginTop: 10 },
              {
                table: {
                  widths: ['27%', '1%', '27%', '1%', '27%', '1%', '16%'], //col widths
                  headerRows: 2,
                  body: [
                    [
                      { text: pdfAuthorDetail.full_name ? pdfAuthorDetail.full_name : "N/A", alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: pdfAuthorDetail.location && pdfAuthorDetail.location !== "" ? pdfAuthorDetail.location : "N/A", alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      // { text: 'J. Bloggs', alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                     ...(pdfAuthorDetail.signature && pdfAuthorDetail.signature !== ""? [{ image: pdfAuthorDetail.signature, width: 90, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }]: [{ text: pdfAuthorDetail.full_name, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }]),
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: pdfAuthorDetail.date ? pdfAuthorDetail.date : "N/A", alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }
                    ],
                    [
                      { text: 'Author Name', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Location', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Signature', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Date', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] }
                    ],
                  ]
                },
                layout: CenteredLayout,
                style: 'signatureTable'
              },
              {
                table: {
                  widths: ['27%', '1%', '27%', '1%', '27%', '1%', '16%'], //col widths
                  headerRows: 2,
                  body: [
                    [
                      { text: pdfReviewerDetail.full_name ? pdfReviewerDetail.full_name : "N/A", alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: pdfReviewerDetail.location && pdfReviewerDetail.location !== "" ? pdfReviewerDetail.location : "N/A", alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      // { text: 'J. Doe', alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] },
                      ...(pdfReviewerDetail.signature && pdfReviewerDetail.signature !== "" ? [{ image: pdfReviewerDetail.signature, width: 90, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }] : [{ text: pdfReviewerDetail.full_name, alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }]),
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: pdfReviewerDetail.date ? pdfReviewerDetail.date : "N/A", alignment: 'center', borderColor: ['#fff', '#fff', '#fff', '#000'] }
                    ],
                    [
                      { text: 'Reviewer Name', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Location', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Signature', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      { text: ' ', borderColor: ['#fff', '#fff', '#fff', '#fff'] }, //spacer
                      { text: 'Date', marginTop: 2, alignment: 'center', borderColor: ['#fff', '#000', '#fff', '#fff'] }
                    ],
                  ]
                },
                layout: CenteredLayout,
                style: 'signatureTable',
                pageBreak: 'after'
              },
            ],
          },
        ],
      },
      //DOCUMENT CONTROL END

      //TABLE OF CONTENTS
      [{
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 30,
            h: 0.5,
            r: 0,
            lineWidth: 1,
            lineColor: '#b40c1e',
          }
        ]
      }], //red line
      { text: 'TABLE OF CONTENTS', style: 'title' },
      {
        columns: [
          //empty col for left spacing
          {
            width: 40,
            text: ''
          },
          //content goes in this col
          {
            width: '*',
            table: {
              widths: ['auto', '*', 'auto'], //col widths
              headerRows: 0,
              body: [
                ['1.', { textReference: 'executive' }, { pageReference: 'executive' }],
                ['2.', { textReference: 'extent-of-ver' }, { pageReference: 'extent-of-ver' }],
                [' ', { text: ['2.1 ', { textReference: 'additional-info' }] }, { pageReference: 'additional-info' }],
                ['3.', { textReference: 'ver-status' }, { pageReference: 'ver-status' }],
                [' ', { text: ['3.1 ', { textReference: 'seceStatus' }] }, { pageReference: 'seceStatus' }],
                [' ', { text: ['3.2 ', { textReference: 'verSchedule' }] }, { pageReference: 'verSchedule' }],
                [' ', { text: ['3.3 ', { textReference: 'completedActivities' }] }, { pageReference: 'completedActivities' }],
                [' ', { text: ['3.4 ', { textReference: 'commencedActivities' }] }, { pageReference: 'commencedActivities' }],
                [' ', { text: ['3.5 ', { textReference: 'notCommencedActivities' }] }, { pageReference: 'notCommencedActivities' }],             
                ['4.', { textReference: 'findings' }, { pageReference: 'findings' }],
                [' ', { text: ['4.1 ', { textReference: 'findingRegister' }] }, { pageReference: 'findingRegister' }],               
                [' ', { text: ['4.2 ', { textReference: 'findingStats' }] }, { pageReference: 'findingStats' }],
                [' ', { text: ['4.2.1 ', { textReference: 'findingsOverview' }] }, { pageReference: 'findingsOverview' }],
                [' ', { text: ['4.2.2 ', { textReference: 'findingsNature' }] }, { pageReference: 'findingsNature' }],
                [' ', { text: ['4.2.3 ', { textReference: 'findingsIssues' }] }, { pageReference: 'findingsIssues' }],
                ['5.', { textReference: 'improvement' }, { pageReference: 'improvement' }],
                ['6.', { textReference: 'concern' }, { pageReference: 'concern' }],
                ['7.', { textReference: 'attendance' }, { pageReference: 'attendance' }],
              ],
            },
            layout: 'noBorders',
            pageBreak: 'after'
          },
        ]
      },
      //TABLE OF CONTENTS END

      //OUR MISSION
      [{
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 30,
            h: 0.5,
            r: 0,
            lineWidth: 1,
            lineColor: '#b40c1e',
          }
        ]
      }], //red line
      { text: 'OUR MISSION', style: 'title' },
      {
        columns: [
          //empty col for left spacing
          {
            width: 40,
            text: ''
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'The mission of ABS is to serve the public interest as well as the needs of our members and clients by promoting the security of life and property and preserving the natural environment.', style: 'leadText' },
              { text: 'Health, Safety, Quality and Environmental Policy Statement', style: 'leadText', bold: true },
              { text: [{ text: 'We will respond', bold: true }, ' to the needs of our members, clients and the public by delivering quality products and services in support of our Mission that provides for the safety of life and property and the protection of the natural environment.',], marginBottom: 10, font: 'Montserrat', color: '#002a4e' },

              { text: [{ text: 'With the input and the participation of our workers', bold: true }, ' we are committed to continually improving the effectiveness of our HSQE performance and management system by identifying risks and opportunities that help to eliminate hazards and reduce risks and by providing safe and healthy working conditions for the prevention of work related injury, ill health and pollution.'], marginBottom: 10, font: 'Montserrat', color: '#002a4e' },
              { text: [{ text: 'We will comply', bold: true }, ' with all applicable legal requirements as well as any additional requirements ABS subscribes to which relate to ABS and our HSQE aspects, objectives and targets.'], font: 'Montserrat', color: '#002a4e', pageBreak: 'after' },

            ]
          },
        ]
      },
      //OUR MISSION END

      //EXECUTIVE SUMMARY
      {
        columns: [
          {
            width: 40,
            text: '1',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'EXECUTIVE SUMMARY', style: 'title', id: 'executive' },
              { stack: vsReport.rptSummaryAlt, marginBottom: 20 },
              // { text: "ABS are contracted by [Client] to act as Verifier for [asset(s)] in accordance with the requirements of the following UK Legislation:", marginLeft: 20 },
              // {
              // to treat a paragraph as a bulleted list, set an array of items under the ul key
              //  ul: [
              //     'The Offshore Installations (Offshore Safety Directive) (Safety Case etc.) Regulations; SI 2015/398',
              //     'The Offshore Installations & Wells (Design and Construction, etc.) Regulations; SI 1996/913',
              //     'The Offshore Installations (Prevention of Fire and Explosion, and Emergency Response) Regulations; SI 1995/743',
              //  ],
              //  marginLeft: 20
              // },
              //{ text: 'This report summarises the [MoC / Operational] Verification activities, as defined in the [Scheme(s), completed during [date range], as well as the key conclusions and recommendations resulting from the Verification process.' }
            ]
          }
        ]
      },
      //EXECUTIVE SUMMARY END

      //EXTENT OF VERIFICATION
      {
        columns: [
          {
            width: 40,
            text: '2',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'EXTENT OF VERIFICATION', style: 'title', id: 'extent-of-ver' },
              { stack: vsReport.rptScopeAlt },
            ]
          }
        ]
      },
      //EXTENT OF VERIFICATION END

      //ADDITIONAL INFORMATION
      {
        columns: [
          {
            width: 40,
            text: '2.1',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Additional Information', style: 'title', id: 'additional-info' },
              { stack: vsReport.rptStatementAlt, marginBottom: 20 },
            ],
            pageBreak: 'after'
          }
        ]
      },
      //ADDITIONAL INFORMATION END

      //VERIFICATION STATUS
      {
        columns: [
          {
            width: 40,
            text: '3',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'VERIFICATION STATUS', style: 'title', id: 'ver-status' },
              { text: "The following section details the status of SECEs and associated Verification Activities within this report. For full details, refer to ABS Verification Manager™." },
            ]
          }
        ]
      },
      //VERIFICATION STATUS END

      //SECE STATUS
      {
        columns: [
          {
            width: 40,
            text: '3.1',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'SECE Status', style: 'title', id: 'seceStatus' },
              { text: 'The following chart provides the status of SECEs in terms of Completion of Verification Activities and Findings assigned to them post the attendance(s) detailed within this report.' },
              {
                style: 'tableSM',
                table: {
                  widths: ['*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                  headerRows: 3,
                  body: [
                    //header
                    [

                      { text: ' ', colSpan: 2, style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#fff'] },
                      {},
                      { text: `End of Date Range - ${dateRangeEnd}`, colSpan: 6, style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#fff'] },
                      {},
                      {},
                      {},
                      {},
                      {},
                    ],
                    [
                      { text: ' ', colSpan: 2, style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#fff'] },
                      {},
                      { text: 'Completion', colSpan: 3, style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#fff'] },
                      {},
                      {},
                      { text: 'Findings', colSpan: 3, style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#fff'] },
                      {},
                      {},

                    ],
                    [
                      { text: 'SECE Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
                      { text: 'SECE Name', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Offshore', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Onshore', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Remote', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Level 1', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Level 2', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Level 3', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] }
                    ],

                    ...createSeceStatusTableRows(seceBreakdowns)

                  ]
                }
              },
            ]
          }
        ]
      },
      //SECE STATUS END

      //VERIFICATION SCHEDULE
      {
        columns: [
          {
            width: 40,
            text: '3.2',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Verification Schedule', style: 'title', id: 'verSchedule' },
              { text: 'The following chart details the overall Verification Schedule post attendance(s) detailed within this report.' },
             ...(base64VerificationSchedule && base64VerificationSchedule !=="" && [{ image: base64VerificationSchedule, width: 450 }]),
            ],
            pageBreak: 'after'
          }
        ]
      },
      //VERIFICATION SCHEDULE END

      //COMPLETED ACTIVITIES
      {
        columns: [
          {
            width: 40,
            text: '3.3',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Completed Activities', style: 'title', id: 'completedActivities' },
              { text: 'The following Verification Activities were completed during the attendance(s) detailed within this report.', marginBottom: 5 },
              { text: 'KEY:' },
              {
                table: {
                  widths: [10, '*'],
                  headerRows: 0,
                  body: [
                    //rows
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#4CA342',
                            color: '#4CA342',
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were satisfied with regard to this Activity.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#5E8AB4',
                            color: '#5E8AB4'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were satisfied with regard to this Activity, however Low Level Finding(s) were raised during completion of this Activity, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#FFAD0A',
                            color: '#FFAD0A'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were not met with regard to this Activity, resulting in Mid Level Finding(s) being raised during completion, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#b40c1e',
                            color: '#b40c1e'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were not met with regard to this Activity, resulting in High Level Finding(s) being raised during completion, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ]
                  ]
                },
              },
              {
                style: 'tableSM',
                table: {
                  widths: ['auto', 'auto', 'auto', '*', 40],
                  headerRows: 1,
                  body: [
                    //header
                    [
                      { text: 'SECE Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
                      { text: 'SECE Name', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Ref.', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Short Description', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'As Found Status', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] },
                    ],
                    ...createCompletedActivityTableRows(completedVaList)
                  ]
                }
              },
            ],
            pageBreak: 'after'
          }
        ]
      },
      //COMPLETED ACTIVITIES END

      //COMMENCED ACTIVITIES
      {
        columns: [
          {
            width: 40,
            text: '3.4',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Commenced Activities', style: 'title', id: 'commencedActivities' },
              { text: 'The following Verification Activities were Commenced during the attendance(s) detailed within this report.', marginBottom: 5 },
              { text: 'KEY:' },
              {
                table: {
                  widths: [10, '*'],
                  headerRows: 0,
                  body: [
                    //rows
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#4CA342',
                            color: '#4CA342',
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were satisfied with regard to this Activity.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#5E8AB4',
                            color: '#5E8AB4'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were satisfied with regard to this Activity, however Low Level Finding(s) were raised during completion of this Activity, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#FFAD0A',
                            color: '#FFAD0A'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were not met with regard to this Activity, resulting in Mid Level Finding(s) being raised during completion, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#b40c1e',
                            color: '#b40c1e'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Performance Standards were not met with regard to this Activity, resulting in High Level Finding(s) being raised during completion, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                  ]
                },
              },
              {
                style: 'tableSM',
                table: {
                  widths: ['auto', 'auto', 'auto', '*', 40],
                  headerRows: 1,
                  body: [
                    //header
                    [
                      { text: 'SECE Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
                      { text: 'SECE Name', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Ref.', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Short Description', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'As Found Status', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] },
                    ],
                    ...createCommencedActivityTableRows(commencedVaList)
                  ]
                }
              },
            ],
            pageBreak: 'after'
          }
        ]
      },
      //COMMENCED ACTIVITIES END

      //NOT COMMENCED ACTIVITIES
      {
        columns: [
          {
            width: 40,
            text: '3.5',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Not Commenced Activities', style: 'title', id: 'notCommencedActivities' },
              { text: 'The following Verification Activities were Not Commenced during the attendance(s) detailed within this report.', marginBottom: 5 },
              { text: 'KEY:' },
              {
                table: {
                  widths: [10, '*'],
                  headerRows: 0,
                  body: [
                    //rows
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#5E8AB4',
                            color: '#5E8AB4'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Activity Not Commenced, however Low Level Finding(s) were raised, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#FFAD0A',
                            color: '#FFAD0A'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Activity Not Commenced, however Mid Level Finding(s) were raised, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: '#b40c1e',
                            color: '#b40c1e'
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'Activity Not Commenced, however High Level Finding(s) were raised, or remain Open from previous Verification.', border: [false, false, false, false] }
                    ],
                    [
                      {
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 10,
                            h: 10,
                            r: 0,
                            lineWidth: 1,
                            lineColor: 'black',
                          },
                        ],
                        border: [false, false, false, false]
                      },
                      { text: 'No Verification has been conducted with regard to this Activity.', border: [false, false, false, false] }
                    ],
                  ]
                },
              },
              {
                style: 'tableSM',
                table: {
                  widths: ['auto', 'auto', 'auto', '*', 40],
                  headerRows: 1,
                  body: [
                    //header
                    [
                      { text: 'SECE Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
                      { text: 'SECE Name', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Ref.', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'VA Short Description', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'As Found Status', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] },
                    ],
                    ...createNotCommencedActivityTableRows(notCommencedVaList)
                  ]
                }
              },
            ],
            pageBreak: 'after'
          }
        ]
      },
      //NOT COMMENCED ACTIVITIES END

      //FINDINGS
      {
        columns: [
          {
            width: 40,
            text: '4',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'FINDINGS', style: 'title', id: 'findings', marginBottom: 5 },
            ]
          }
        ]
      },
      //FINDINGS END

      //FINDING REGISTER
      {
        columns: [
          {
            width: 40,
            text: '4.1',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Finding Register', style: 'title', id: 'findingRegister' },
              { text: 'The following Findings were raised (and closed where applicable) during the attendance(s) detailed within this report.', marginBottom: 5 },
              {
                style: 'tableSM',
                table: {
                  widths: ['auto', 'auto', '*', 'auto', 40],
                  headerRows: 1,
                  body: [
                    //header
                    [
                      { text: 'Finding Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'], alignment: 'center' },
                      { text: 'SECE Ref.', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'], alignment: 'center' },
                      { text: 'Synopsis', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                      { text: 'Finding Level', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'], alignment: 'center' },
                      { text: 'Status', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'], alignment: 'center' },
                    ],
                    ...createFindingTableRows(raisedFindings)
                  ]
                }
              },
            ]
          }
        ],
        pageBreak: 'after'
      },
      //FINDING REGISTER END

      //FINDING STATISTICS
      {
        columns: [
          {
            width: 40,
            text: '4.2',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'Finding Statistics', style: 'title', id: 'findingStats' },
              { text: 'The following section provides statistical information relating to all Findings Open at the end of the reporting period that this report covers. For full details, refer to ABS Verification Manager™.' },
            ]
          }
        ]
      },
      //FINDING STATISTICS END

      //FINDINGS OVERVIEW
      {
        columns: [
          {
            width: 40,
            text: '4.2.1',
            style: 'title',
          },
          //content goes in this col
          [
            { text: 'Findings by Overview', style: 'title', id: 'findingsOverview' },
            { text: 'The following charts provide a summary of the status and level of Findings Open at the end of the reporting period.', marginBottom: 5 },
            {
              columns: [
                [
                  { text: 'ALL OPEN FINDINGS', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  ...(base64OpenFindingsStatus && base64OpenFindingsStatus !=="" && [{ image: base64OpenFindingsStatus, width: 225 }])
                ],
                [
                  { text: 'ALL OPEN FINDINGS', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  ...(base64OpenFindingsLevel && base64OpenFindingsLevel !=="" && [{ image: base64OpenFindingsLevel, width: 225 }])
                ],
              ]
            }
          ]
        ]
      },
      //FINDING OVERVIEW END

      //FINDINGS NATURE
      {
        columns: [
          {
            width: 40,
            text: '4.2.2',
            style: 'title',
          },
          //content goes in this col
          [
            { text: 'Findings by Nature', style: 'title', id: 'findingsNature' },
            { text: 'The following charts detail the Nature by which Findings Open at the end of the reporting period were raised.', marginBottom: 5 },
            {
              columns: [
                [
                  { text: 'FINDINGS BY NATURE', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  ...(base64FindingsByNatureStatus && base64FindingsByNatureStatus !== "" && [{ image: base64FindingsByNatureStatus, width: 225, marginRight: 10 }])
                ],
                [
                  { text: 'FINDINGS BY NATURE', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  ...(base64FindingsByNatureLevel && base64FindingsByNatureLevel !== "" && [{ image: base64FindingsByNatureLevel, width: 225 }])
                ],
              ]
            }
          ]
        ]
      },
      //FINDING NATURE END

      //FINDINGS ISSUE
      {
        columns: [
          {
            width: 40,
            text: '4.2.3',
            style: 'title',
          },
          //content goes in this col
          [
            { text: 'Findings by Issue', style: 'title', id: 'findingsIssues' },
            { text: 'The following charts detail the issues associated with Findings Open at the end of the reporting period.', marginBottom: 5 },

            {
              columns: [
                [
                  { text: 'FINDINGS BY ISSUE', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  {
                    table: {
                      widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                      headerRows: 0,
                      body: [
                        //header
                        [
                          ...createIssueFindingsHeaderRows(issuesFindingsStats),
                        ],

                      ]
                    }
                  },
                  { svg: issuesFindingsStatsSvg, width: 225, marginRight: 10 }
                ],
                [
                  { text: 'FINDINGS BY ISSUE', font: 'Montserrat', fontSize: 8, bold: true, color: '#002a4e' },
                  ...(base64FindingsIssuesBarChart && base64FindingsIssuesBarChart !== "" && [{ image: base64FindingsIssuesBarChart, width: 225 }])
                ],
              ]
            }
          ]
        ],
        pageBreak: 'after'
      },
      //FINDINGS ISSUE END

      //OPPORTUNITIES FOR IMPROVEMENT
      {
        columns: [
          {
            width: 40,
            text: '5',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'OPPORTUNITIES FOR IMPROVEMENT', style: 'title', id: 'improvement' },
              { stack: vsReport.rptImprovementsAlt },
            ]
          }
        ]
      },
      //OPPORTUNITIES FOR IMPROVEMENT END


      //AREAS OF CONCERN
      {
        columns: [
          {
            width: 40,
            text: '6',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'AREAS OF CONCERN', style: 'title', id: 'concern' },
              { stack: vsReport.rptConcernsAlt },
            ]
          }
        ]
      },
      //AREAS OF CONCERN END

      //POSITIVIES FROM THE ATTENDANCE
      {
        columns: [
          {
            width: 40,
            text: '7',
            style: 'title',
          },
          //content goes in this col
          {
            width: '*',
            stack: [
              { text: 'POSITIVES FROM THE ATTENDANCE', style: 'title', id: 'attendance' },
              { stack: vsReport.rptPositivesAlt },
            ]
          }
        ],
        pageBreak: 'after'
      },
      //POSITIVIES FROM THE ATTENDANCE END

      //BACK COVER
      {
        table: {
          widths: ['100%'],
          heights: [275, 275],
          body: [
            [{
              fillColor: '#b40c1e', border: [false, false, false, true],
              stack: [
                ...(vmLogo && vmLogo !== "" && [{ image: vmLogo, width: 350, alignment: 'center' }]),
              ]
            }],
            [{
              fillColor: '#002a4e', border: [false, true, false, false],
              stack: [

                ...(logoImage && logoImage !== "" && [{ image: logoImage, width: 250, marginTop: 70, alignment: 'center' }]),

              ]
            }]
          ],
        },
        layout: {
          vLineColor: function (i, node) { return '#fff'; },
          vLineWidth: function (i) { return 0; },
          hLineColor: function (i, node) { return 'white'; },
          hLineWidth: function (i) { return 6; },
          paddingLeft: function (i, node) { return 40; },
          paddingRight: function (i, node) { return 40; },
          paddingTop: function (i, node) { return 40; },
          paddingBottom: function (i, node) { return 10; },
        }
      },
      //BACK COVER END

      //CONTENT END
    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      title: {
        marginTop: 10,
        font: 'Montserrat',
        fontSize: 14,
        bold: true,
        color: '#002a4e'
      },
      leadText: {
        marginBottom: 20,
        font: 'Montserrat',
        fontSize: 11,
        color: '#002a4e',
        //bold: true
      },
      redTitle: {
        marginBottom: 10,
        font: 'Montserrat',
        color: '#b40c1e'
      },
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1
      },
      tableSM: {
        fontSize: 8
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      detailsTable: {
        marginBottom: 35,
        lineHeight: 1
      },
      signatureTable: {
        margin: [0, 20, 0, 0],
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  //open in new tab
  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  //download
  //const pdfDownload = () => {
  //pdfMake.createPdf(docDefinition).download();
  //}

  //PDF REPORT END

  //Sece Detialed Breakdown Column Start
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'SECEref',
        columns: [{
          Header: 'SECE Ref.',
          accessor: 'sece_ref',
        }]
      },
      {
        Header: '',
        id: 'SECEtitle',
        columns: [{
          Header: 'SECE Title',
          accessor: 'sece_title',
        }]
      },
      {
        Header: `End of Date Range - ${dateRangeEnd}`,
        id: 'EndOfDateRange',
        columns: [
          {
            Header: 'Completion',
            id: 'completion',
            columns: [
              {
                Header: 'Offshore',
                accessor: 'offshore',
                Cell: off => (
                  <span className={`flex py-1 px-2 justify-center ${[off.value === 0 ? "bg-red-900 text-white" : off.value <= 50 ? "bg-blue-300 text-white" : off.value <= 75 ? "bg-yellow-900 text-black" : off.value <= 100 ? "bg-green-900 text-black" : null]}`}>
                    {off.value}%
                  </span>
                ),
              }, {
                Header: 'Onshore',
                accessor: 'onshore',
                Cell: on => (
                  <span className={`flex py-1 px-2 justify-center ${[on.value === 0 ? "bg-red-800 text-white" : on.value <= 50 ? "bg-blue-300 text-white" : on.value <= 75 ? "bg-yellow-900 text-black" : on.value <= 100 ? "bg-green-900 text-black" : null]}`}>
                    {on.value}%
                  </span>
                ),
              }, {
                Header: 'Remote',
                accessor: 'remote',
                Cell: rem => (

                  <span className={`flex py-1 px-2 justify-center ${[rem.value === 0 ? "bg-red-800 text-white" : rem.value <= 50 ? "bg-blue-300 text-white" : rem.value <= 75 ? "bg-yellow-900 text-black" : rem.value <= 100 ? "bg-green-900 text-black" : null]}`}>
                    {rem.value}%
                  </span>
                ),
              }
            ]
          },
          {
            Header: 'Findings',
            id: 'findings',
            columns: [
              {
                Header: 'Level 1',
                accessor: 'level1',
                Cell: lev1 => (
                  <span className={"flex py-1 px-2 justify-center bg-blue-300 text-black"}>
                    {lev1.value}
                  </span>
                ),
              },
              {
                Header: 'Level 2',
                accessor: 'level2',
                Cell: lev2 => (
                  <span className={"flex py-1 px-2 justify-center bg-yellow-900"}>
                    {lev2.value}
                  </span>
                ),
              },
              {
                Header: 'Level 3',
                accessor: 'level3',
                Cell: lev3 => (
                  <span className={"flex py-1 px-2 justify-center bg-red-900 text-white"}>
                    {lev3.value}
                  </span>
                ),
              }
            ]
          }
        ]
      }
    ],
    [dateRangeStart, dateRangeEnd]
  )
  //Sece Detailed Breakdown Column End

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{vsDutyHolder && vsDutyHolder !=="" && `${vsDutyHolder} - `}View Periodic Verification</h1>
      </div>

      <div className="my-4">
        {/* <Link to="/reporting/report-search" className="button-red mr-2"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link> */}

        {isLoaded === true && resultStatus === true && chartImageStatus === true ?
          <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF Report</span> :
          <span className="button-red cursor-not-allowed">
            <div className="flex items-center">
              <span>
                Loading PDF
              </span>
              <span className="ml-2 inline-block">
                <Loader
                  type="Oval"
                  color="#ffffff"
                  height={17}
                  width={17}
                  timeout={120000} //120 secs
                />
              </span>
            </div>
          </span>
        }

        {
          (vsReport.rptStatus && vsReport.rptStatus !== NOTIFICATION_STATUS_REVIEW &&
            vsReport.rptStatus !== NOTIFICATION_STATUS_PENDING &&
            vsReport.rptStatus !== NOTIFICATION_STATUS_APPROVED) ?
            <Link to={{
              pathname: `/reporting/vs-report/report-index/edit/${vsRptId}`,
              state: { formMode: 'edit' }
            }} className="button-red ml-2">Edit <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-white" /></Link>
            : <></>
        }
        
      </div>

      {/* Initially lg:w-2/3 */}
      <div className="w-full lg:w-3/3">

        {/* DOCUMENT CONTROL */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">
          <h2 className="mt-12 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{""}</h2>
          <table className="w-full my-5 table-auto">
            <tbody>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border w-1/2">Duty Holder</th>
                <td className="p-3 border">{vsDutyHolder}</td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border w-1/2">Dates</th>
                <td className="p-3 border">{dateRange}</td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border w-1/2">Report Title</th>
                <td className="p-3 border">{`${vsReport.rptTitle ?? ""}`}</td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border w-1/2">Report No.</th>
                <td className="p-3 border">{`${reportNumber} ${currentRevision}`}</td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border w-1/2">Verification Scheme Ref(s)</th>
                <td className="p-3 border">{`${verSchemeRef}`}</td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border" colSpan="2">Installations</th>
              </tr>
              <tr>
                <td className="p-3 border" colSpan="2">
                  {vsInstallations.length > 0 ? vsInstallations.map((inst, index) =>
                    <p key={index}>{inst.installation_title}</p>
                  ) :
                    <p>{"No Installation"}</p>
                  }
                </td>
              </tr>
              <tr>
                <th className="p-3 text-left text-white font-sans font-semibold bg-blue-900 border" colSpan="2">Verifiers</th>
              </tr>
              <tr>
                <td className="p-3 border" colSpan="2">
                  {vsVerifiers.length > 0 ? vsVerifiers.map((verifier, index) =>
                    <p key={index}>{verifier}</p>
                  ) :
                    <p>{"No Verifier"}</p>
                  }
                </td>
              </tr>
            </tbody>
          </table>

          <h2 className="mt-12 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Document Control</h2>
          <table className="w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th colSpan="5">Revision Control</th>
              </tr>
              <tr>
                <th>Revision</th>
                <th>Revised Date</th>
                <th>Description</th>
                <th>Revised By</th>
                <th>Approved By</th>
              </tr>
            </thead>
            <tbody>
              {vsReportHistories.length ? vsReportHistories.map((hist, index) =>
                <tr key={index}>
                  <td>{hist.revision}</td>
                  <td>{hist.revisedDate}</td>
                  <td>{<Sanitiser html={hist.description} />}</td>
                  <td>{hist.revisedBy}</td>
                  <td>{hist.approvedBy}</td>
                </tr>
              ) :
                <tr key={"1"}>
                  <td colSpan="5">{"No Data to Display"}</td>
                </tr>
              }
            </tbody>
          </table>

          {/*
          <table className="mt-6 w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th>Issue Number</th>
                <th>Document Holder</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {reportIssuanceList.map((issuance, index) =>
                <tr key={index}>
                  <td>{issuance.issue_number}</td>
                  <td>{issuance.document_holder}</td>
                  <td>{issuance.location}</td>
                </tr>
              )}
            </tbody>
          </table> */}

           {/* <p>This is to certify that the undersigned Surveyor to this Bureau, did at the request of the Duty Holder’s Representative, attend the Maersk Drilling office in Lyngby, to complete onshore Verification activities for Maersk Resolve, <span className="text-red-900">ABSID 09162307</span>, of <span className="text-red-900">Republic of Singapore</span>.</p> */}
          <div>{<Sanitiser html={vsReport.rptDescription}/>}</div>

        </div>
        {/* DOCUMENT CONTROL END */}

        {/* TABLE OF CONTENTS */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">

          <h2 className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Table of Contents</h2>

          <p><a href="#executive-summary" className="hover:underline">1. EXECUTIVE SUMMARY</a></p>

          <p><a href="#extent-of-verification" className="hover:underline">2. EXTENT OF VERIFICATION</a></p>
          <p className='ml-5'><a href="#additional-information" className="hover:underline">2.1 Additional Information</a></p>

          <p><a href="#verification-status" className="hover:underline">3. VERIFICATION STATUS</a></p>
          <p className='ml-5'><a href="#sece-status" className="hover:underline">3.1 SECE Status</a></p>
          <p className='ml-5'><a href="#verification-schedule" className="hover:underline">3.2 Verification Schedule</a></p>
          <p className='ml-5'><a href="#completed-activities" className="hover:underline">3.3 Completed Activities</a></p>
          <p className='ml-5'><a href="#commenced-activities" className="hover:underline">3.4 Commenced Activities</a></p>
          <p className='ml-5'><a href="#not-commenced-activities" className="hover:underline">3.5 Not Commenced Activities</a></p>

          <p><a href="#findings" className="hover:underline">4. FINDINGS</a></p>
          <p className='ml-5'><a href="#finding-register" className="hover:underline">4.1 Finding Register</a></p>
          <p className='ml-5'><a href="#findings-statistics" className="hover:underline">4.2 Findings Statistics</a></p>
          <p className='ml-5'><a href="#findings-by-overview" className="hover:underline">4.2.1 Findings by Overview</a></p>
          <p className='ml-5'><a href="#findings-by-nature" className="hover:underline">4.2.2 Findings by Nature</a></p>
          <p className='ml-5'><a href="#findings-by-issue" className="hover:underline">4.2.3 Findings by Issue</a></p>

          <p><a href="#opportunities-for-improvement" className="hover:underline">5. OPPORTUNITIES FOR IMPROVEMENT</a></p>
          <p><a href="#areas-of-concern" className="hover:underline">6. AREAS OF CONCERN</a></p>
          <p><a href="#positives-from-the-attendance" className="hover:underline">7. POSITIVES FROM THE ATTENDANCE</a></p>

        </div>
        {/* TABLE OF CONTENTS END */}

        {/* EXECUTIVE SUMMARY */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">
          <h2 id="executive-summary" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">1. Executive Summary</h2>
          <Sanitiser html={vsReport.rptSummary} />
        </div>
        {/* EXECUTIVE SUMMARY END */}

        {/* EXTENT OF VERIFICATION */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">
          <h2 id="extent-of-verification" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">2. Extent of Verification</h2>
          <Sanitiser html={vsReport.rptScope} />

          <h3 id="additional-information" className="mt-3 mb-3 text-blue-900 font-sans text-xl font-bold">2.1 Additional Information</h3>
          <div>{<Sanitiser html={vsReport.rptStatement} />}</div>
        </div>
        {/* EXTENT OF VERIFICATION */}

        {/* VERIFICATION STATUS */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">
          <h2 id="verification-status" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">3. VERIFICATION STATUS</h2>
          <p>The following section details the status of SECEs and associated Verification Activities within this report. For full details, refer to ABS Verification Manager&trade;.</p>

          <h3 id="sece-status" className="mt-3 mb-3 text-blue-900 font-sans text-xl font-bold">3.1 SECE Status</h3>
          <p>The following chart provides the status of SECEs in terms of Completion of Verification Activities and Findings assigned to them post the attendance(s) detailed within this report.</p>

          {seceBreakdownError ? <span>Something went wrong retrieving the table data</span> : ""}
          {seceBreakdownLoading ? <PageSpinner /> : ""}
          {seceBreakdowns.length ? <Table columns={columns} data={seceBreakdowns} /> : (!seceBreakdownLoading && "No Data To Display")}

          <h3 id="verification-schedule" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">3.2 Verification Schedule</h3>
          <p>The following chart details the overall Verification Schedule post attendance(s) detailed within this report.</p>
          {vsStatsError ? <span>Something went wrong retrieving the table data</span> : ""}
          {vsStatsLoading ? <PageSpinner /> : ""}
          <ErrorBoundary>
            <Line
              data={vsStatsData}
              options={{
                title: { display: true, text: 'VERIFICATION SCHEDULE', fontColor: '#002a4e', fontSize: 20 },
                legend: { align: 'middle' },
                animation: {
                  onComplete: doneVerificationSchedule
                },
              }}
              ref={VerificationScheduleRef}
            />
          </ErrorBoundary>        

          <h3 id="completed-activities" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">3.3 Completed Activities</h3>
          <p className="mb-3">The following Verification Activities were completed during the attendance(s) detailed within this report.</p>

          <p>KEY:</p>
          <p><span className="text-green-900">◼</span> Performance Standards were satisfied with regard to this Activity.</p>
          <p><span className="text-blue-300">◼</span> Performance Standards were satisfied with regard to this Activity, however Low Level Finding(s) were raised during completion of this Activity, or remain Open from previous Verification.</p>
          <p><span className="text-yellow-900">◼</span> Performance Standards were not met with regard to this Activity, resulting in Mid Level Finding(s) being raised during completion, or remain Open from previous Verification.</p>
          <p><span className="text-red-900">◼</span> Performance Standards were not met with regard to this Activity, resulting in High Level Finding(s) being raised during completion, or remain Open from previous Verification.</p>

          <table className="mt-6 w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th className="text-left">SECE Ref.</th>
                <th className="text-left">SECE Name</th>
                <th className="text-left">VA Ref.</th>
                <th className="text-left">VA Short Description</th>
                <th className="text-left">As Found Status</th>
              </tr>
            </thead>
            <tbody>
              {svaLoading ? <tr><td colSpan={5}>Loading...</td></tr> : <></>}
              {svaError ? <tr><td colSpan={5}>Something went wrong retrieving the table data</td></tr> : <></>}
              {completedVaList.length ? completedVaList.map((sva, index) =>
                <React.Fragment key={index}>
                  <tr>
                    <td>{sva.sece_ref}</td>
                    <td>{sva.sece_name}</td>
                    <td>{sva.va_ref}</td>
                    <td>{sva.va_short_desc}</td>
                    <td className={sva.as_found_status}>&nbsp;</td>
                  </tr>
                </React.Fragment>
              ) :
                (!svaLoading &&
                  <tr><td colSpan="6">{"No Data to Display"}</td></tr>
                )
              }
            </tbody>
          </table>

          <h3 id="commenced-activities" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">3.4 Commenced Activities</h3>
          <p className="mb-3">The following Verification Activities were Commenced during the attendance(s) detailed within this report.</p>

          <p>KEY:</p>
          <p><span className="text-green-900">◼</span> Performance Standards were satisfied with regard to this Activity.</p>
          <p><span className="text-blue-300">◼</span> Performance Standards were satisfied with regard to this Activity, however Low Level Finding(s) were raised during completion of this Activity, or remain Open from previous Verification.</p>
          <p><span className="text-yellow-900">◼</span> Performance Standards were not met with regard to this Activity, resulting in Mid Level Finding(s) being raised during completion, or remain Open from previous Verification.</p>
          <p><span className="text-red-900">◼</span> Performance Standards were not met with regard to this Activity, resulting in High Level Finding(s) being raised during completion, or remain Open from previous Verification.</p>

          <table className="mt-6 w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th className="text-left">SECE Ref.</th>
                <th className="text-left">SECE Name</th>
                <th className="text-left">VA Ref.</th>
                <th className="text-left">VA Short Description</th>
                <th className="text-left">As Found Status</th>
              </tr>
            </thead>
            <tbody>
              {svaLoading ? <tr><td colSpan={5}>Loading...</td></tr> : <></>}
              {svaError ? <tr><td colSpan={5}>Something went wrong retrieving the table data</td></tr> : <></>}
              {commencedVaList.length ? commencedVaList.map((sva, index) =>
                <React.Fragment key={index}>
                  <tr>
                    <td>{sva.sece_ref}</td>
                    <td>{sva.sece_name}</td>
                    <td>{sva.va_ref}</td>
                    <td>{sva.va_short_desc}</td>
                    <td className={sva.as_found_status}>&nbsp;</td>
                  </tr>
                </React.Fragment>
              ) :
                (!svaLoading &&
                  <tr><td colSpan="6">{"No Data to Display"}</td></tr>
                )
              }
            </tbody>
          </table>

          <h3 id="not-commenced-activities" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">3.5 Not Commenced Activities</h3>
          <p className="mb-3">The following Verification Activities were Not Commenced during the attendance(s) detailed within this report.</p>

          <p>KEY:</p>
          <p><span className="text-blue-300">◼</span> Activity Not Commenced, however Low Level Finding(s) were raised, or remain Open from previous Verification.</p>
          <p><span className="text-yellow-900">◼</span> Activity Not Commenced, however Mid Level Finding(s) were raised, or remain Open from previous Verification.</p>
          <p><span className="text-red-900">◼</span> Activity Not Commenced, however High Level Finding(s) were raised, or remain Open from previous Verification.</p>
          <p>◻ No Verification has been conducted with regard to this Activity.</p>

          <table className="mt-6 w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th className="text-left">SECE Ref.</th>
                <th className="text-left">SECE Name</th>
                <th className="text-left">VA Ref.</th>
                <th className="text-left">VA Short Description</th>
                <th className="text-left">As Found Status</th>
              </tr>
            </thead>
            <tbody>
              {svaLoading ? <tr><td colSpan={5}>Loading...</td></tr> : <></>}
              {svaError ? <tr><td colSpan={5}>Something went wrong retrieving the table data</td></tr> : <></>}
              {notCommencedVaList.length ? notCommencedVaList.map((sva, index) =>
                <React.Fragment key={index}>
                  <tr>
                    <td>{sva.sece_ref}</td>
                    <td>{sva.sece_name}</td>
                    <td>{sva.va_ref}</td>
                    <td>{sva.va_short_desc}</td>
                    <td className={sva.as_found_status}>&nbsp;</td>
                  </tr>
                </React.Fragment>
              ) :
                (!svaLoading &&
                  <tr><td colSpan="6">{"No Data to Display"}</td></tr>
                )
              }
            </tbody>
          </table>

        </div>
        {/* VERIFICATION STATUS */}

        {/* FINDINGS */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">
          <h2 id="findings" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">4. FINDINGS</h2>

          <h3 id="finding-register" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.1 Finding Register</h3>
          <p className="mb-3">The following Findings were raised (and closed where applicable) during the attendance(s) detailed within this report.</p>

          <table className="mt-6 w-full my-3 table-auto report-table">
            <thead>
              <tr>
                <th className="text-left">Finding Ref.</th>
                <th className="text-left">SECE Ref.</th>
                <th className="text-left">Synopsis</th>
                <th className="text-left">Finding Level</th>
                <th className="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              {fndRegLoading ? <tr><td colSpan={4}>Loading...</td></tr> : <></>}
              {fndRegError ? <tr><td colSpan={4}>Something went wrong retrieving the table data</td></tr> : <></>}
              {raisedFindings.length ? raisedFindings.map((fnd, index) =>
                <tr key={index}>
                  <td className="text-center">{fnd.finding_ref}</td>
                  <td className="text-center">{fnd.sece_ref}</td>
                  <td>{fnd.synopsis}</td>
                  <td className= {`${classColorCodeMapper(fnd.finding_level_summary)} text-center`}>{fnd.finding_infomatrix_level}</td>
                  <td className="text-center">{fnd.status}</td>
                </tr>
              ) :
                (!fndRegLoading &&
                  <tr><td colSpan="6">{"No Data to Display"}</td></tr>
                )
              }
            </tbody>
          </table>

          <h3 id="findings-statistics" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.2 Finding Statistics</h3>
          <p className="mb-3">The following section provides statistical information relating to all Findings Open at the end of the reporting period that this report covers. For full details, refer to ABS Verification Manager&trade;.</p>

          <h3 id="findings-by-overview" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.2.1 Findings by Overview</h3>
          <p className="mb-3">The following charts provide a summary of the status and level of Findings Open at the end of the reporting period.</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">All Open Findings</h3>
                  {openFindingsLoading && (
                    <PageSpinner />
                  )}
                  {openFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}
                  {!openFindingsLoading && !openFindingsError && openFindingsStats.date.datasets &&
                   (
                    <ErrorBoundary>
                      <Pie
                        data={openFindingsStats.date}
                        options={{
                          title: { display: false, text: 'All Open Findings', fontColor: '#002a4e' },
                          legend: { align: 'start' },
                          animation: {
                            onComplete: doneOpenFindingsStatus
                          },
                        }}
                        ref={OpenFindingsStatus}
                      />
                    </ErrorBoundary> 
                  )}
                </div>
              </div>
            </div>

            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">All Open Findings</h3>

                  {openFindingsLoading && (
                    <PageSpinner />
                  )}
                  {openFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}

                  {!openFindingsLoading && !openFindingsError && openFindingsStats.level.datasets &&
                    (
                      <ErrorBoundary>
                        <Pie
                          data={openFindingsStats.level}
                          options={{
                            title: { display: false, text: 'All Open Findings', fontColor: '#002a4e' },
                            legend: { align: 'start' },
                            animation: {
                              onComplete: doneOpenFindingsLevel
                            },
                          }}
                          ref={OpenFindingsLevel}
                        />
                      </ErrorBoundary>
                    )
                  }
                </div>
              </div>
            </div>
          </div>

          <h3 id="findings-by-nature" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.2.2 Findings by Nature</h3>
          <p className="mb-3">The following charts detail the Nature by which Findings Open at the end of the reporting period were raised.</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">Findings by Nature</h3>

                  {natureFindingsLoading && (
                    <PageSpinner />
                  )}
                  {natureFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}

                  {!natureFindingsLoading && !natureFindingsError && natureFindingsStats.date.datasets &&
                   (
                    <ErrorBoundary>
                      <Bar
                          data={{...defaultState.date, ...natureFindingsStats.date}}
                          options={{
                            scales: {
                              xAxes: [{
                                stacked: true
                              }],
                              yAxes: [{
                                stacked: true
                              }]
                            },
                            legend: { align: 'start' },
                            animation: {
                              onComplete: doneFindingsByNatureStatus
                            }
                          }
                          }
                          ref={FindingsByNatureStatus}
                        />
                    </ErrorBoundary>                
                   )}

                </div>
              </div>
            </div>

            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">Findings by Nature</h3>
                  {natureFindingsLoading && (
                    <PageSpinner />
                  )}
                  {natureFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}           
                  {!natureFindingsLoading && !natureFindingsError && natureFindingsStats.level.datasets.length > 0 &&
                    (
                    <ErrorBoundary>
                      <Bar
                          data={{...defaultState.level, ...natureFindingsStats.level}}
                          options={{
                            scales: {
                              xAxes: [{
                                stacked: true
                              }],
                              yAxes: [{
                                stacked: true
                              }]
                            },
                            legend: { align: 'start' },
                            animation: {
                              onComplete: doneFindingsByNatureLevel
                            }
                          }}
                          ref={FindingsByNatureLevel}
                        />
                    </ErrorBoundary>
                    )}
                </div>
              </div>
            </div>
          </div>

          <h3 id="findings-by-issue" className="mt-10 mb-3 text-blue-900 font-sans text-xl font-bold">4.2.3 Findings by Issue</h3>
          <p className="mb-3">The following charts detail the issues associated with Findings Open at the end of the reporting period.</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">Findings by Issue</h3>
                  {issuesFindingsLoading && (
                    <PageSpinner />
                  )}
                  {issuesFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}
                  {!issuesFindingsLoading && !issuesFindingsError && issuesFindingsStats.length && (
                    <>
                      <div className="flex">
                        <div className="mt-2 w-full flex flex-row justify-between">
                          {issuesFindingsStats.map((issue, index) =>
                            <div className="p-2 text-center" style={{ backgroundColor: `${issue.backgroundColor}` }} key={index}>
                              <span className="block text-sm text-white">{issue.count}</span>
                              <span style={{ fontSize: '0.6rem' }} className="text-white">{issue.label}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <ErrorBoundary>
                        <div className="w-full xl:w-4/5 mx-auto" ref={divRef}>
                          <FindingIssues data={issuesFindingsStats} />
                        </div>
                      </ErrorBoundary>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-gray-100">
              <div className="flex flex-col h-full">
                <div className="p-4">
                  <h3 className="mb-3 font-semibold text-blue-900 uppercase">Findings by Issue</h3>
                  {issuesFindingsLoading && (
                    <PageSpinner />
                  )}
                  {issuesFindingsError && (
                    <span>Something went wrong retrieving the data</span>
                  )}
                  {!issuesFindingsLoading && !issuesFindingsError && issuesFindingsStatsBarChart.datasets && (
                    <ErrorBoundary>
                      <Bar
                        data={issuesFindingsStatsBarChart}
                        options={{
                          scales: {
                            xAxes: [{
                              stacked: true,
                              ticks: {
                                fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                              },
                            }],
                            yAxes: [{
                              stacked: true,
                              ticks: {
                                fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                              },
                            }]
                          },
                          legend: {
                            display: false,
                            align: 'start',
                            labels: {
                              fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                            }
                          },
                          animation: {
                            onComplete: doneFindingsIssuesBar
                          },
                        }}
                        ref={FindingsIssuesBarChart}
                      />
                    </ErrorBoundary>
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* FINDINGS END */}

        {/* OPPORTUNITIES FOR IMPROVEMENT */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">
          <h2 id="opportunities-for-improvement" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">5. Opportunities for Improvement</h2>
          <Sanitiser html={vsReport.rptImprovements} />
        </div>
        {/* OPPORTUNITIES FOR IMPROVEMENT END */}

        {/* AREAS OF CONCERN */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">
          <h2 id="areas-of-concern" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">6. Areas of Concern</h2>
          <Sanitiser html={vsReport.rptConcerns} />
        </div>
        {/* AREAS OF CONCERN END */}

        {/* POSITIVES FROM THE ATTENDANCE */}
        <div className="mb-6 pb-8 border-b-2 border-gray-200">
          <h2 id="positives-from-the-attendance" className="mt-3 mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">7. Positives from the attendance</h2>
          <Sanitiser html={vsReport.rptPositives} />
        </div>
        {/* POSITIVES FROM THE ATTENDANCE END */}

      </div>

      <div className="my-4">
        {/* <Link to="/reporting/report-search" className="button-red mr-2"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link> */}

        {isLoaded === true && resultStatus === true && chartImageStatus === true ?
          <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF Report</span> :
          <span className="button-red cursor-not-allowed">
            <div className="flex items-center">
              <span>
                Loading PDF
              </span>
              <span className="ml-2 inline-block">
                <Loader
                  type="Oval"
                  color="#ffffff"
                  height={17}
                  width={17}
                  timeout={120000} //120 secs
                />
              </span>
            </div>
          </span>
        }

        {
          (vsReport.rptStatus && vsReport.rptStatus !== NOTIFICATION_STATUS_REVIEW &&
            vsReport.rptStatus !== NOTIFICATION_STATUS_PENDING &&
            vsReport.rptStatus !== NOTIFICATION_STATUS_APPROVED) ?
            <Link to={{
              pathname: `/reporting/vs-report/report-index/edit/${vsRptId}`,
              state: { formMode: 'edit' }
            }} className="button-red ml-2">Edit <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-white" /></Link>
            : <></>
        }
      </div>
    </div>
  )

}

export const  createRevisionTableRows = (revisions) => {
  let rowList = [];

  if (!revisions || revisions.length === 0) {
    let emptyRow = ["No Data to Display", "", "", "", ""];
    rowList.push(emptyRow);
    return rowList;
  }

  revisions.forEach(rev => {
    const {revision, revisedDate, description, revisedBy, approvedBy} = rev ? rev : {};
    let formatedDescription = htmlToPdfmake(description);
    let row = [revision, revisedDate, formatedDescription, revisedBy, approvedBy]
    rowList.push(row);
  })

  return rowList;
}

export const  createDocumentTableRows = (issuances) => {
  let rowList = [];

  if (!issuances || issuances.length === 0) {
    let emptyRow = ["No Data to Display", "", ""];
    rowList.push(emptyRow);
    return rowList;
  }

  issuances.forEach(issuance => {
    const { issue_number, document_holder, location } = issuance ? issuance : {};
    let row = [issue_number, document_holder, location]
    rowList.push(row);
  })

  return rowList;
}

export const createSeceStatusTableRows = (seceStatusList) => {

  let rowList = [];

  if (!seceStatusList || seceStatusList.length === 0) {
    let emptyRow = ["No Data to Display", "", "", "", "", "", "", ""];
    rowList.push(emptyRow);
    return rowList;
  }

  seceStatusList.forEach((status, index) => {
    const { sece_ref, sece_title, level1, level2, level3, offshore, onshore, remote } = status ? status : {};

    const isOddNumber = ((index) % 2) === 0;

    let row = [
      { text: sece_ref, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
      { text: sece_title, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
      { text: `${offshore}%`, fontSize: 11, color: '#fff', fillColor: offshore === 0 ? "#b40c1e" : offshore <= 50 ? "#5E8AB4" : offshore <= 75 ? "#FFAD0A" : offshore <= 100 ? "#4CA342" : null, alignment: 'center' },
      { text: `${onshore}%`, fontSize: 11, color: '#fff', fillColor: onshore === 0 ? "#b40c1e" : onshore <= 50 ? "#5E8AB4" : onshore <= 75 ? "#FFAD0A" : onshore <= 100 ? "#4CA342" : null, alignment: 'center' },
      { text: `${remote}%`, fontSize: 11, color: '#fff', fillColor: remote === 0 ? "#b40c1e" : remote <= 50 ? "#5E8AB4" : remote <= 75 ? "#FFAD0A" : remote <= 100 ? "#4CA342" : null, alignment: 'center' },
      { text: level1, fontSize: 11, color: '#fff', fillColor: '#5E8AB4', alignment: 'center' },
      { text: level2, fontSize: 11, fillColor: '#FFAD0A', alignment: 'center' },
      { text: level3, fontSize: 11, color: '#fff', fillColor: '#b40c1e', alignment: 'center' },
    ]
    rowList.push(row);
  })

  return rowList;
}

export const  createCompletedActivityTableRows = (completedVaList) => {
  let rowList = [];

  if (!completedVaList || completedVaList.length === 0) {
    let emptyRow = ["No Data to Display", "", "", "", ""];
    rowList.push(emptyRow);
    return rowList;
  }

  completedVaList.forEach(va => {
    const { sece_ref, sece_name, va_ref, va_short_desc, as_found_status } = va ? va : {};
    let row = [
      sece_ref,
      sece_name,
      va_ref,
      va_short_desc,
      { text: '', fillColor: mapColorCode(as_found_status) }
    ];
    rowList.push(row);
  })

  return rowList;
}

export const  createCommencedActivityTableRows = (commencedVaList) => {
  let rowList = [];

  if (!commencedVaList || commencedVaList.length === 0) {
    let emptyRow = ["No Data to Display", "", "", "", ""];
    rowList.push(emptyRow);
    return rowList;
  }

  commencedVaList.forEach(va => {
    const { sece_ref, sece_name, va_ref, va_short_desc, as_found_status } = va ? va : {};
    let row = [
      sece_ref,
      sece_name,
      va_ref,
      va_short_desc,
      { text: '', fillColor: mapColorCode(as_found_status) }
    ];
    rowList.push(row);
  })

  return rowList;
}

export const  createNotCommencedActivityTableRows = (notCommencedVaList) => {
  let rowList = [];

  if (!notCommencedVaList || notCommencedVaList.length === 0) {
    let emptyRow = ["No Data to Display", "", "", "", ""];
    rowList.push(emptyRow);
    return rowList;
  }

  notCommencedVaList.forEach(va => {
    const { sece_ref, sece_name, va_ref, va_short_desc, as_found_status } = va ? va : {};
    let row = [
      sece_ref,
      sece_name,
      va_ref,
      va_short_desc,
      { text: '', fillColor: mapColorCode(as_found_status) }
    ];
    rowList.push(row);
  })

  return rowList;
}

export const  createFindingTableRows = (raisedFindings) => {
  let rowList = [];

  if (!raisedFindings || raisedFindings.length === 0) {
    let emptyRow = ["No Data to Display", "", "", "", ""];
    rowList.push(emptyRow);
    return rowList;
  }

  raisedFindings.forEach(fnd => {
    const { finding_ref, finding_infomatrix_level, finding_level_summary, sece_ref, synopsis, status } = fnd ? fnd : {};
    let row = [ 
      {text: finding_ref, alignment: 'center' }, 
      {text: sece_ref, alignment: 'center'}, 
      synopsis, 
      {text: finding_infomatrix_level, fillColor: classColorCodeMapperAlt(finding_level_summary), alignment: 'center'}, 
      {text: status, alignment: 'center'}]
    rowList.push(row);
  })

  return rowList;
}

export const createIssueFindingsHeaderRows = (issuesFindingsStats) => {
  let rowList = [];

  if (!issuesFindingsStats || issuesFindingsStats.length === 0) {
    return rowList;
  }

  issuesFindingsStats.forEach(issue => {
    const { label, count, backgroundColor } = issue ? issue : {};
    let row = {
      text: `${count}\n ${label}`,
      color: '#fff',
      borderColor: ['#fff', '#fff', '#fff', '#fff'],
      fillColor: `${backgroundColor}`,
      fontSize: 6,
      alignment: 'center'
    };
    rowList.push(row);
  })

  return rowList;
}

function classColorCodeMapperAlt(findingLevel) {
  let colorCode = "#da1f33";
  switch (findingLevel) {
    case "HIGH":
    case "High":
      colorCode = "#da1f33";
      break;
    case "MEDIUM":
    case "Medium":
      colorCode = "#ffa700";
      break;
    case "LOW":
    case "Low":
      colorCode = "#008744";
      break;
    default:
      colorCode = "#da1f33";
      break;
  }
  return colorCode;
}

export const mapColorCode = (code) => {
  let color = "#b40c1e";
  if (code && code !== "") {
    switch (code) {
      case "bg-green-900":
        color = "#4CA342";
        break;
      case "bg-blue-100":
      case "bg-blue-300":
        color = "#5E8AB4";
        break;
      case "bg-yellow-900":
        color = "#FFAD0A";
        break;
      case "bg-red-900":
        color = "#b40c1e";
        break;
      case "bg-white-900":
        color = "#ffffff";
        break;
      default:
        break;
    }
  }
  return color;
}

export default ViewPeriodicVerification;